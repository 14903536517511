import axios from 'axios'

const storeProcess = async function(data){
    const options = {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        data: JSON.stringify(data),
        url: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/process/store',
    }
    return await axios(options)
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
    })
}

const updateProcess = async function(data){
    const options = {
        method: 'PUT',
        headers: {'content-type': 'application/json'},
        data: JSON.stringify(data),
        url: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/process/update',
    }
    return await axios(options)
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
    })
}

const sendInformationProcess = async function(data){
    const options = {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        data: JSON.stringify(data),
        url: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/master/info/send',
    }
    return await axios(options)
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
    })
}

export {storeProcess, updateProcess, sendInformationProcess}