import React from 'react'
import Preloader from '../../Loader/Preloader'
import Pagination from './Pagination'
import List from './List'
import NewModule from './NewModule'
import EditModule from './EditModule'
import M from 'materialize-css/dist/js/materialize.min.js'
import {request} from '../../../services/request'
import R from '../../../services/routes'
import Toast from '../../IziToast/Toast'

export default class ShowModules extends React.Component {

    constructor(){
        super()
        this.state = {
            showPreloader: true,
            showList: true,
            sites: null,
            siteId: '',
            modules: null,
            newAccess: false,
            editAccess: false,
            idToEdit: null,
            moduleSiteId: '',
            moduleName: '',
            moduleDescription: '',
            moduleSiteIdEdit: '',
            moduleNameEdit: '',
            moduleDescriptionEdit: '',
        }
    }

    componentDidMount(){
        this.getSites()
    }

    getSites = async () => {
        await this.setState({showPreloader: true})
        const res = await request('GET', R.getSitesNames, true, true, false)
        if(res.success){
            await this.setState({sites: res.sites})
            const select = document.querySelectorAll('select')
            M.FormSelect.init(select, {})

        }
        await this.setState({showPreloader: false})
    }

    getModules = async site => {
        await this.setState({showPreloader: true})
        const res = await request('GET', `${R.getModulesBySite}/${site}`, true, true, false)
        if(res.success){
            await this.setState({modules: res.modules})
        }
        await this.setState({showPreloader: false})
    }

    getModule = async id => {
        await this.setState({showPreloader: true})
        const res = await request('GET', `${R.getModule}/${id}`, true, true, false)
        if(res.success){
            await this.setState({
                moduleSiteIdEdit: res.module.site_id,
                moduleNameEdit: res.module.name,
                moduleDescriptionEdit: res.module.description,
            })
        }
        await this.setState({showPreloader: false})
    }

    handleSearchModules = async () => {
        await this.getModules(this.state.siteId)
    }

    handleNewModule = async () => {
        await this.setState({showList: false, newModule: true})
        const select = document.querySelectorAll('select')
        M.FormSelect.init(select, {})
        M.updateTextFields()
    }

    handleStoreModule = async e => {
        e.preventDefault()
        e.stopPropagation()
        await this.setState({showPreloader: true})
        const data = {
            site_id: this.state.moduleSiteId,
            name: this.state.moduleName,
            description: this.state.moduleDescription,
        }
        const res = await request('POST', R.storeModule, true, true, JSON.stringify(data))
        if(res.success){
            Toast.showSuccess({
                title: 'Módulo creado',
                message: 'El módulo fue creado correctamente',
                position: 'topCenter',
            })
        } else {
            Toast.showError({
                title: 'Error',
                message: 'Ocurrió un error inesperado',
                position: 'topCenter',
            })
        }
        await this.setState({
            showPreloader: false,
            showList: true,
            newModule: false,
            moduleSiteId: '',
            moduleName: '',
            moduleDescription: '',
        })
        if(this.state.siteId !== null)
            this.getModules(this.state.siteId)
        const select = document.querySelectorAll('select')
        M.FormSelect.init(select, {})
    }

    handleEdit = async id => {
        await this.getModule(id)
        await this.setState({showList: false, editModule: true, idToEdit: id})
        const select = document.querySelectorAll('select')
        M.FormSelect.init(select, {})
        M.updateTextFields()
    }

    handleUpdateModule = async e => {
        e.preventDefault()
        e.stopPropagation()
        await this.setState({showPreloader: true})
        const data = {
            id: this.state.idToEdit,
            site_id: this.state.moduleSiteIdEdit,
            name: this.state.moduleNameEdit,
            description: this.state.moduleDescriptionEdit,
        }
        const res = await request('PUT', R.updateModule, true, true, JSON.stringify(data))
        if(res.success){
            Toast.showSuccess({
                title: 'Módulo Actualizado',
                message: 'El módulo fue actualizado correctamente',
                position: 'topCenter',
            })
        } else {
            Toast.showError({
                title: 'Error',
                message: 'Ocurrió un error inesperado',
                position: 'topCenter',
            })
        }
        await this.setState({
            showPreloader: false,
            showList: true,
            editModule: false,
            idToEdit: null,
            moduleSiteIdEdit: '',
            moduleNameEdit: '',
            moduleDescriptionEdit: '',
        })
        this.getModules(this.state.siteId)
        const select = document.querySelectorAll('select')
        M.FormSelect.init(select, {})
    }

    handleDelete = id => {
        Toast.showCallBack({
            title: 'Eliminar módulo',
            message: '¿Seguro que desea eliminar este elemento?',
            position: 'center',
            callBack: () => {
                this.handleCallBackDelete(id)
            }
        })
    }

    handleCallBackDelete = async id => {
        const res = await request('DELETE', `${R.deleteModule}/${id}`, true, true, false)
        if(res.success){
            Toast.showSuccess({
                title: 'Eliminado',
                message: 'Módulo eliminado correctamente',
                position: 'topCenter',
            })
        } else {
            Toast.showError({
                title: 'Error',
                message: 'Ocurrió un error inesperado',
                position: 'topCenter',
            })
        }
        this.getModules(this.state.siteId)
    }

    async handlePaginationClick(url){
        await this.setState({showPreloader: true})
        const res = await request('GET', url, false, true, false)
        if(res.success){
            await this.setState({modules: res.modules})
        }
        await this.setState({showPreloader: false})
    }

    handleChange = e => {
        const id = e.target.id
        const value = e.target.value
        this.setState({
            ...this.state,
            [id]: value,
        })
    }

    render(){
        return(
            <div style={{height: '100%'}}>
                {this.state.showPreloader &&
                    <Preloader color='yellownee' />
                }
                {this.state.showList &&
                    <React.Fragment>
                    {this.state.sites &&
                        <div className="col s12">
                            <div className="input-field deep-blue col s12 m8">
                                <select id="siteId" className="validate" onChange={this.handleChange} value={this.state.siteId} >
                                    <option value="">Selecciona una opción</option>
                                    {this.state.sites.map(site => {
                                        return(
                                            <option key={`site-${site.id}`} value={site.id}>{site.name}</option>
                                        )
                                    })}
                                </select>
                                <label>Sitio</label>
                            </div>
                            <div className="col s12 m4" style={{marginTop: '1rem'}}>
                                <button className="btn waves-effect waves-light deep-blue-bg" onClick={this.handleSearchModules}>
                                    <i className="material-icons right">search</i>
                                    Buscar
                                </button>
                            </div>
                        </div>
                    }
                    {this.state.modules &&
                        <div className="col s12" style={{overflow :'hidden'}}>
                            {this.state.modules.data.length !== 0 ?
                                <React.Fragment>
                                    <div className="col s12">
                                        <Pagination
                                            state={this.state}
                                            handlePaginationClick={this.handlePaginationClick.bind(this)}
                                        />
                                    </div>
                                    <div className="col s12 masonry">
                                        <List
                                            modules={this.state.modules}
                                            handleEdit={this.handleEdit.bind(this)}
                                            handleDelete={this.handleDelete.bind(this)}
                                        />
                                    </div>
                                </React.Fragment>
                                :
                                <span style={{fontWeight: 'bold', fontSize: '15px', marginLeft: 'calc(50% - 88px)'}}>No hay registros todavía</span>
                            }
                        </div>
                    }
                    </React.Fragment>
                }
                {this.state.newModule ?
                    <NewModule
                        state={this.state}
                        handleStoreModule={this.handleStoreModule.bind(this)}
                        handleChange={this.handleChange.bind(this)}
                    />
                    :
                    <button className="waves-effect waves-light btn btn-bottom yellownee-gradient" onClick={this.handleNewModule} style={{position: 'fixed', top: 'calc(100vh - 110px)', left: 0}}>
                        <i className="material-icons left">add</i>
                        Nuevo modulo
                    </button>
                }
                {this.state.editModule &&
                    <EditModule
                        state={this.state}
                        handleUpdateModule={this.handleUpdateModule.bind(this)}
                        handleChange={this.handleChange.bind(this)}
                    />
                }
            </div>
        )
    }

}