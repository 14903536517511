import React from 'react'
import Checkbox from '../../Checkbox/Checkbox'

const ModulesCard = props => {
    return(
        <div className="card-panel deep-blue-bg">
            <span className="white-text">
                <span className="panelTitle">{props.name}</span>
                <br/>
                <span className={`panelTitle ${!props.showDescription ? 'hide':''}`}>{props.description}</span>
                <Checkbox
                    id={props.id}
                    name="Seleccionar todo"
                    color="white-text"
                    checked={props.checked}
                    toggleCheckbox={props.toggleSelectAll}
                />
                {props.content}
            </span>
        </div>
    )
}

export default ModulesCard