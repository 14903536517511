import React from 'react'

const ActionButton = props => {
    return(
        <button
            className={`btn-flat waves-effect waves-light ${props.color}`}
            name="action"
            type="button"
            onClick={()=>{props.action()}}
            style={props.fullWidth ? {width: '100%'}:props.width ? {width: props.width}:{}}
        >
            <i className="material-icons right">{props.icon}</i>{props.text}
        </button>
    )
}

export default ActionButton