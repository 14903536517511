const iziToast = require('izitoast')

export default function(title, message, position, closingFn = function(){console.log('error')}){

    iziToast.settings({
        timeout: 4000,
        theme: 'light',
        icon: 'fas fa-times-circle',
        resetOnHover: true,
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        iconColor: '#ff5555',
        titleColor: '#282c36',
        messageColor: '#7e8086',
        progressBarColor: '#ff5555',
        backgroundColor: '#ffffff'
    })

    iziToast.show({
        title: title,
        message: message,
        position: position, // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
        // buttons: [
        //     ['<button>Ok</button>', function (instance, toast) {
        //         alert("Hello world!");
        //     }, true], // true to focus
        //     ['<button>Close</button>', function (instance, toast) {
        //         instance.hide({
        //             transitionOut: 'fadeOutUp',
        //             onClosing: function(instance, toast, closedBy){
        //                 console.info('closedBy: ' + closedBy); // The return will be: 'closedBy: buttonName'
        //             }
        //         }, toast, 'buttonName');
        //     }]
        // ],
        // onOpening: function(instance, toast){
        //     console.info('callback abriu!');
        // },
        onClosing: closingFn
    })
}