import React from 'react'
import person from '../../img/person.png'
import Preloader from '../Loader/Preloader'
import {user} from '../../Functions/users'

export default class Profile extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            profile: null
        }
    }

    componentDidMount(){
        this.getUser()
    }

    getUser = async () => {
        await user(localStorage.getItem('id'))
        .then(data => {
            if(data.success){
                this.setState({profile: data.user})
            } else {
                this.getUser()
            }
        })
        .catch(e => {
            this.getUser()
        })
    }

    render(){
        return(
            <React.Fragment>
                {!this.state.profile ?
                        <Preloader color="yellownee" />
                    :
                    <div className="card-panel blue-gradient profile-card">
                        <div className="center-align">
                            <img className="userImageProfile" src={person} alt="user_image"/>
                            <br/>
                            <span className="white-text user-name">
                                {this.state.profile.name}
                            </span>
                            <br/>
                            <span className="white-text">
                                {this.state.profile.email}
                            </span>
                        </div>
                        <div className="row center-align" style={{marginBottom: 'unset'}}>
                            <div className="col s6">
                                <span className="white-text list-description">Tareas completadas</span>
                                <br/>
                                <span className="white-text">{this.props.yet}</span>
                            </div>
                            <div className="col s6">
                                <span className="white-text list-description">Tareas pendientes</span>
                                <br/>
                                <span className="white-text">{this.props.pending}</span>
                            </div>
                        </div>
                        <span className="white-text list-description">Nivel de usuario</span>
                        <br/>
                        <span className="white-text">
                            {this.state.profile.level === 0 ? 'Usuario' : 'Administrador'}
                        </span>
                        <br/>
                        <span className="white-text list-description">Departamento</span>
                        <br/>
                        <span className="white-text">
                            {this.state.profile.department}
                        </span>
                    </div>
                }
            </React.Fragment>
        )
    }
}