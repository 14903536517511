import React from 'react'
import { Link, Redirect } from 'react-router-dom';
import Sidenav from './Sidenav'
import Preloader from '../Loader/Preloader'
import logo from '../../img/logo.png'
import logout from '../../Functions/logout'
import M from 'materialize-css/dist/js/materialize.min.js'

export default class Navbar extends React.Component{

    constructor(){
        super()
        this.state = {
            showPreloader: false,
        }
    }

    componentDidMount(){
        const sidenav = document.querySelectorAll('.sidenav')
        M.Sidenav.init(sidenav)
    }

    handleClick = async () => {
        await this.setState({showPreloader: true})
        await logout()
        this.setState({ redirect: true })
    }

    render(){

        const routes = [
            
        ]

        return (

            <div>
                {this.state.showPreloader ? 
                    <Preloader color="yellownee" />
                    :
                    ''
                }
                <div className="navbar-fixed">
                    <nav>
                    <div className="nav-wrapper blue-sky-bg">
                        <Link to={process.env.REACT_APP_REPORTES_GERENCIALES == "1" ? '/reports' : '/home'}>
                            <span className="brand-logo">
                                <img style={{width: '140px'}} src={logo} alt=""/>
                            </span>
                        </Link>
                        <a href="#" data-target="slide-out" className="sidenav-trigger hide-on-med-and-up"><i className="material-icons">menu</i></a>
                        <ul className="right hide-on-small-only">
                            {
                                routes.map(route => {
                                    return(
                                        <Link to={route.href} ><li key={route.name}>{route.name}</li></Link>
                                    )
                                })
                            }
                            <li><a onClick={this.handleClick}>Cerrar sesión</a></li>
                        </ul>
                    </div>
                    </nav>
                </div>
                <Sidenav />
                
            </div>
        )
    }
}