import React from 'react'
import {useParams} from 'react-router-dom'
import Walmart from './Walmart'
import Information from './Information'
import BlueDiamond from './BlueDiamond'
import BlueDiamondAdmin from './BlueDiamond/BlueDiamondAdmin'
export default function Externals(){
    let {type} = useParams()
    switch (type) {
        case 'walmart':
            return <Walmart/>
            break
        case 'information':
            return <Information />
            break
        // case 'blue_diamond':
        //     return <BlueDiamond />
        //     break
        case 'blue_diamond_admin':
            return <BlueDiamondAdmin />
    }
}