import axios from 'axios'

const config = async function(id){
    let access = [
        'Authenticate',
        'Bulkload',
        'Foliator',
        'Suppliers',
        'Inventory',
        'Querys',
        'Rooms',
        'SuppliersApp',
        'Projects',
        'Tasks',
        'Reports'
    ]
    const response = await axios.get((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/user/singleUser/' + id,
    {
        headers: {'Authorization': localStorage.getItem('token_type')+ ' ' +localStorage.getItem('token')}
    })
    .then(res => {
        const user = res.data.user
        const options = JSON.parse(user.options)
        let acs = []
        for(let i in access){
            if(options.access.indexOf(access[i]) !== -1){
                acs.push({name: access[i], value: true})
            } else {
                acs.push({name: access[i], value: false})
            }
        }
        options.access = acs
        return {id: user.id, options: options}
    })
    .catch(e => {
        console.log(e)
    })
    return response
}

const configUpdate = async function(data){
    let acs = []
    for(let i in data.access){
        if(data.access[i].value){
            acs.push(data.access[i].name)
        }
    }
    console.log(data)
    const opt = {
        'id_company': data.company, 
        'level': data.level,
        'origin': data.origin,
        'access': acs,
        'department': data.department
    }
    console.log(opt)
    const pData = {
        'id': data.id,
        'options': opt
    }
    const options = {
        method: 'PUT',
        headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
        data: JSON.stringify(pData),
        url: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/user/update/options',
    }
    const response = await axios(options)
    .then(res => {
        const data = res.data
        return data
    })
    .catch(err => {
        console.log(err)
        return {success: false}
    })
    return response
}

const user = async function(id){
    const response = await axios.get((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/user/singleUser/' + id,{
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const data = res.data
        return data
    })
    .catch(err => {
        console.log(err)
        return {
            success: false,
            error: err
        }
    })
    return response
}

const userUpdate = async function(data){
    const options = {
        method: 'PUT',
        headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
        data: JSON.stringify(data),
        url: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/user/update'
    }
    const response = await axios(options)
    .then(res => {
        const data = res.data
        return data
    })
    .catch(err => {
        console.log(err)
        return {success: false}
    })
    return response
}

const users = async function(url){
    const response = await axios.get(url,{
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const data = res.data
        if(data.success){
            return {users: data.users, url: url}
        }
    })
    .catch(err => {
        if(err.response.data.message === 'Server Error'){
            return this.users(url)
        }
    })
    return response
}

const userNames = async function(){
    return await axios.get((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/user/names',{
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const data = res.data
        return data
    })
    .catch(err => {
        console.log(err)
        return {
            success: false,
            error: err
        }
    })
}

export {config, configUpdate, users, userUpdate, user, userNames}