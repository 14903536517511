import React from 'react'

const NewModule = (props) => {
    return(
        <React.Fragment>
            <br/>
            <div className="col s12 m4 push-m4 flex-center" style={{textAlign: 'center'}}>
                <span className="panelTitle">Agregar nuevo modulo</span>
            </div>
            <br/>
            <form onSubmit={props.handleStoreModule}>
                <div className="input-field deep-blue col s12 m8 push-m2">
                    <select id="moduleSiteId" className="validate" onChange={props.handleChange} value={props.state.moduleSiteId} >
                        <option value="">Selecciona una opción</option>
                        {props.state.sites.map(site => {
                            return(
                                <option key={`newModule-site-${site.id}`} value={site.id}>{site.name}</option>
                            )
                        })}
                    </select>
                    <label>Sitio</label>
                </div>
                <div className="input-field col s12 m8 push-m2">
                    <input placeholder="Nombre" className="deep-blue" value={props.state.moduleName}  onChange={props.handleChange} id="moduleName" type="text" required />
                    <label className="blue-sky" htmlFor="moduleName">Nombre</label>
                </div>
                <div className="input-field col s12 m8 push-m2">
                    <textarea placeholder="Descripción" className="deep-blue materialize-textarea" value={props.state.moduleDescription} onChange={props.handleChange} id="moduleDescription" type="text" required ></textarea>
                    <label className="blue-sky" htmlFor="moduleDescription">Descripción</label>
                </div>
                <div className="col s12 m8 push-m2">
                    <button className="btn deep-blue-bg waves-effect waves-light" type="submit" style={{width: '100%'}}>
                        <i className="material-icons right">save</i>
                        Guardar
                    </button>
                </div>
            </form>
        </React.Fragment>
    )
}

export default NewModule