import React from 'react'
import Loader from '../Loader/Loader'
import Preloader from '../Loader/Preloader'
import Success from '../IziToast/Success'
import Error from '../IziToast/Error'
import Warning from '../IziToast/Warning'
import {folio} from '../../Functions/folios'
import {storeProcess, updateProcess, sendInformationProcess} from '../../Functions/process'
import {assignInfoMaster} from '../../Functions/folios'
import walmartLogo from '../../img/walmart.png'
import Camera from 'react-html5-camera-photo'
import ImagePreview from '../Camera/ImagePreview'
import SignaturePad from 'react-signature-canvas'
import AssignModal from '../Foliator/AssignModal'
import 'react-html5-camera-photo/build/css/index.css'
import styles from './styles.module.css'
import M from 'materialize-css/dist/js/materialize.min.js'
const qS = require('query-string')
let parsed = null

export default class Walmart extends React.Component {

    sigPad = {}

    constructor(){
        super()
        this.state = {
            showPreloader: true,
            showCamera: false,
            showCameraLoading: true,
            folio: null,
            folioReference: null,
            photo: null,
            email: '',
            isFullscreen: true,
            equals: false,
            trimmedDataURL: null,
            finalStep: false,
            final: false
        }
    }

    componentDidMount(){
        parsed = qS.parse(window.location.search)
        folio(parsed.folio)
        .then(res => {
            console.log(res)
            if(res.success){
                if(res.folio !== null){
                    if(res.folio.options !== null){
                        const options = JSON.parse(res.folio.options)
                        if(options.key == parsed.key){
                            if(options.status === 2){
                                this.assignState(res.folio)
                                .then(() => {
                                    const element = document.getElementById('modalAssignInfo')
                                    const modal = M.Modal.init(element, {preventScrolling: false, dismissible: false, opacity: 0})
                                    modal.open()
                                })
                            }else if(options.status === 3){
                                this.setState({folio: res.folio, showPreloader: false, equals: true, final: true})
                            }
                             else {
                                this.setState({folio: res.folio, showPreloader: false, equals: true})
                            }
                        } else {
                            this.setState({folio: res.folio, showPreloader: false})
                        }
                    } else {
                        this.setState({folio: res.folio, showPreloader: false})
                    }
                } else {
                    this.setState({showPreloader: false})
                    Error('Error', 'El folio es inexistente o ya está dado de alta', 'topCenter')
                }
            } else {
                Error('Error', 'Ocurrió un error inesperado', 'topCenter')
            }
        })
        .catch(e => {
            console.log(e)
        })
    }

    assignState = async (folio) => {
        await this.setState({showPreloader: false, finalStep: true, folioReference: folio, folio: folio, equals: true})
    }

    handleChange = e => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            [e.target.id]: value
        })
    }

    handleSubmit = e => {
        e.preventDefault()
        e.stopPropagation()
        if(this.state.email !== '' && this.state.photo){
            const data = {
                folio_id: this.state.folio.id,
                options: {
                    email: this.state.email,
                    folio: this.state.folio.folio,
                    image: this.state.photo,
                    status: 1
                }
            }
            storeProcess(data)
            .then(data => {
                if(data.success){
                    Success('Guardado', 'Recibirá un email confirmando el proceso. Siga las instrucciones en él para continuar.', 'bottomCenter')
                }
            })
        } else if(this.state.photo === null){
            Warning('Atención', 'No se pude guardar el registro sin tomar una fotografía de su ticket de compra', 'topCenter')
        }
    }

    handleTakePhoto = () => {
        this.setState({showCamera: true})
    }

    handleTakeAnotherPhoto = () => {
        this.setState({photo: null, showCamera: true, showCameraLoading: true})
    }

    handleTakePhotoAnimationDone = (dataUri) => {
        this.setState({photo: dataUri})
    }

    handleStartingCamera = () => {
        this.setState({showCameraLoading: false})
    }

    handleClear = () => {
        this.sigPad.clear()
    }

    handleTrim = () => {
        const trimmed = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
        const options = JSON.parse(this.state.folio.options)
        const dataUpdate = {
            id: this.state.folio.processId,
            folio_id: this.state.folio.id,
            options: {
                email: options.email,
                folio: options.folio,
                image: options.image,
                key: options.key,
                signature: trimmed,
                status: 2
            }
        }
        updateProcess(dataUpdate)
        .then(data => {
            if(data.success){
                Success('Guardado', 'Se guardó su firma digital.', 'bottomCenter', function(){window.location = window.location.href})
                this.assignState(dataUpdate)
                .then(() => {
                    console.log(this.state)
                    const element = document.getElementById('modalAssignInfo')
                    const modal = M.Modal.init(element, {preventScrolling: false, dismissible: false, opacity: 0})
                    modal.open()
                })
            }
        })
    }

    handleCloseModal = id => {
        Warning('Atención', 'Si cierra el modal no podrá terminar el proceso.', 'topCenter')
    }

    handleSubmitModal = data => {
        const id = 'modalAssignInfo'
        this.setState({ showPreloader: true })
        assignInfoMaster(data)
        .then(response => {
            if(response.success){
                Success('Guardado', 'Se guardó la información del folio', 'bottomCenter')
                const options = JSON.parse(this.state.folioReference.options)
                const data = {
                    id: this.state.folioReference.processId,
                    folio_id: this.state.folioReference.id,
                    options: {
                        email: options.email,
                        folio: options.folio,
                        image: options.image,
                        key: options.key,
                        signature: options.signature,
                        status: 3
                    }
                }
                updateProcess(data)
                .then(data => {
                    if(data.success){
                        Success('Guardado', 'Se guardó la información de su producto.', 'bottomCenter', function(){window.location = window.location.href})
                        this.setState({final: true, folioReference: this.state.folio})
                    }
                })
            } else {
                Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
            }
            const element = document.getElementById(id)
            const modal =  M.Modal.getInstance(element)
            modal.close()
            this.setState({showPreloader: false})
        })
        .catch(e => {
            console.log(e)
            Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
            const element = document.getElementById(id)
            const modal =  M.Modal.getInstance(element)
            modal.close()
            this.setState({showPreloader: false})
        })
    }

    handleInformation = () => {
        sendInformationProcess({folio_id: this.state.folio.id})
        .then(data => {
            if(data.success){
                Success('Enviado', 'Se envió un correo al email con el que iniciaste el proceso.', 'bottomCenter')
            } else {
                Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
            }
        })
        .catch(e => {
            console.log(e)
            Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
        })
    }

    render(){
        return(
            <React.Fragment>
                {this.state.showPreloader &&
                    <Loader />
                }
                <img src={walmartLogo} alt="logo"/>
                {(this.state.folio !== null && this.state.finalStep === false && this.state.final === false) &&
                    this.state.folio.options === null ?
                    <form onSubmit={this.handleSubmit} className="col s12">
                        <span>Datos para activar su servicio</span>
                        <hr className="form-divider"/>
                        <div className="row" style={{marginBottom: '0'}}>
                            <div className="input-field col s12">
                                <input placeholder="Correo electrónico" className="deep-blue" value={this.state.email}  onChange={this.handleChange} id="email" type="text" required />
                                <label className="blue-sky" htmlFor="email">Correo electrónico</label>
                            </div>
                        </div>
                        {!this.state.showCamera &&
                            <div style={{marginBottom: '15px', textAlign: 'center'}}>
                                <button className="btn waves-effect waves-light blue-gradient" type="button" style={{width: '50%'}} onClick={this.handleTakePhoto}>Tomar fotografía</button>
                            </div>
                        }
                        {(this.state.photo)
                            ? <ImagePreview dataUri={this.state.photo}
                                isFullscreen={this.state.isFullscreen}
                            />
                            : this.state.showCamera &&
                            <React.Fragment>
                                {this.state.showCameraLoading &&
                                    <Preloader color="yellownee" />
                                }
                                <Camera 
                                    onCameraStart={this.handleStartingCamera}
                                    onTakePhoto={(dataUri) => {this.handleTakePhotoAnimationDone(dataUri)}}
                                    isFullscreen={this.state.isFullscreen}
                                />
                            </React.Fragment>
                        }
                        {this.state.photo !== null &&
                            <div style={{marginBottom: '15px', textAlign: 'center'}}>
                                <button className="btn waves-effect waves-light red-gradient" type="button" style={{width: '50%'}} onClick={this.handleTakeAnotherPhoto}>Tomar de nuevo</button>
                            </div>
                        }
                        <div>
                            <button className="btn waves-effect waves-light blue-gradient" type="submit" style={{width: '100%'}}>Guardar</button>
                        </div>
                    </form>
                    :
                    parsed !== null &&
                        parsed.key === undefined ?
                            <p className="deep-blue" style={{width: '90%', fontSize: '15px', margin: 'auto'}}>El producto con el folio {parsed.folio} ya cuenta con un proceso activo, para poder continuar con él verifique el correo de confirmación que enviamos a la dirección proporcionada. De otro modo comuníquese a la línea...</p>
                            :
                            this.state.equals === true ?
                                (this.state.finalStep === false && this.state.final === false) &&
                                <React.Fragment>
                                    <p className="deep-blue" style={{width: '90%', fontSize: '15px', margin: 'auto', textAlign: 'center'}}>Para continuar con el proceso, por favor firma en el recuadro de abajo lo más similar posible a tu firma manuscrita</p>
                                    <div  className={styles.sigContainer}>
                                        <SignaturePad 
                                            canvasProps={{className: styles.sigPad}}
                                            ref={(ref) => { this.sigPad = ref }} 
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col s6">
                                            <button className="btn waves-effect waves-light blue-gradient" type="button" style={{width: '100%'}} onClick={this.handleTrim}>Guardar</button>
                                        </div>
                                        <div className="col s6">
                                            <button className="btn waves-effect waves-light red-gradient" type="button" style={{width: '100%'}} onClick={this.handleClear}>Limpiar</button>
                                        </div>
                                    </div>
                                </React.Fragment>
                                :
                                this.state.finalStep === false &&
                                <p className="deep-blue" style={{width: '90%', fontSize: '15px', margin: 'auto'}}>Lo sentimos, pero la clave de acceso para continuar con su activación no es la correcta. Si tiene problemas con la activación de su producto comuníquese a la línea...</p>
                }
                {this.state.finalStep &&
                    <AssignModal
                        id={this.state.folioReference.id}
                        callbackClose={this.handleCloseModal.bind(this)}
                        callbackSubmit={this.handleSubmitModal.bind(this)}
                    />
                }
                {this.state.final &&
                    <React.Fragment>
                        <p className="deep-blue" style={{width: '90%', fontSize: '15px', margin: 'auto'}}>Este folio está correctamente activado. Si desea ver su información, de click en el botón de abajo para recibir un correo con todos los datos recopilados a través de este portal.</p>
                        <button className="btn waves-effect waves-light blue-gradient" type="button" style={{width: '100%'}} onClick={this.handleInformation}>Enviar</button>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}
