import axios from 'axios'

const lotsByCompany = async function(url){
    const response = await axios.get(url, 
    {
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const data = res.data
        
        return data
    })
    .catch(e => {
        console.log(e)
    })
    return response
}

export {lotsByCompany}