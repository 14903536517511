import axios from 'axios'

const departmentsNames = async function(){
    const response = await axios.get((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/department/names', {
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const departments = res.data.departments
        let departmentNames = []
        for(let i in departments){
            let department = departments[i]
            departmentNames[department.id] = department.name
        }
        departmentNames[0] = 'Ninguno'
        return departmentNames
    })
    .catch(e => {
        console.log(e)
    })
    return response
}

export {departmentsNames}