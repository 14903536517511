import React from 'react'

const EditSite = (props) => {
    return(
        <React.Fragment>
            <br/>
            <div className="col s12 m4 push-m4 flex-center" style={{textAlign: 'center'}}>
                <span className="panelTitle">Editar sitio</span>
            </div>
            <br/>
            <form onSubmit={props.handleUpdateSite}>
                <div className="input-field col s12 m8 push-m2">
                    <input placeholder="Nombre" className="deep-blue" value={props.state.siteNameEdit}  onChange={props.handleChange} id="siteNameEdit" type="text" required />
                    <label className="blue-sky" htmlFor="siteName">Nombre</label>
                </div>
                <div className="input-field col s12 m8 push-m2">
                    <input placeholder="IP privada" className="deep-blue" value={props.state.sitePrivateIpEdit}  onChange={props.handleChange} id="sitePrivateIpEdit" type="text" required />
                    <label className="blue-sky" htmlFor="sitePrivateIp">IP privada</label>
                </div>
                <div className="input-field col s12 m8 push-m2">
                    <input placeholder="IP pública" className="deep-blue" value={props.state.sitePublicIpEdit}  onChange={props.handleChange} id="sitePublicIpEdit" type="text" required />
                    <label className="blue-sky" htmlFor="sitePublicIp">IP pública</label>
                </div>
                <div className="input-field col s12 m8 push-m2">
                    <textarea placeholder="Descripción" className="deep-blue materialize-textarea" value={props.state.siteDescriptionEdit} onChange={props.handleChange} id="siteDescriptionEdit" type="text" required ></textarea>
                    <label className="blue-sky" htmlFor="siteDescription">Descripción</label>
                </div>
                <div className="col s12 m8 push-m2">
                    <button className="btn deep-blue-bg waves-effect waves-light" type="submit" style={{width: '100%'}}>
                        <i className="material-icons right">save</i>
                        Guardar
                    </button>
                </div>
            </form>
        </React.Fragment>
    )
}

export default EditSite