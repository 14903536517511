import React from 'react';

const Pagination = (props) => {
  const { state: { data }, handlePaginationClick } = props
  return (
    <div className="col s12">
        {data &&
            <div className="row valign-wrapper">
                <div className="col s12 m6">
                    <ul className="pagination" style={{float: 'right', margin: '3px'}}>
                        <li className="waves-effect"><button className="deep-blue" typw="button" role="button" onClick={()=>{handlePaginationClick(data.first_page_url)}}><i className="material-icons">first_page</i></button></li>
                        {data.prev_page_url &&
                            <li className="waves-effect"><button className="deep-blue" typw="button" role="button" onClick={()=>{handlePaginationClick(data.prev_page_url)}}>{data.current_page - 1}</button></li>
                        }
                        <li className="waves-effect active"><button className="white-text" typw="button" role="button">{data.current_page}</button></li>
                        {data.next_page_url &&
                            <li className="waves-effect"><button className="deep-blue" typw="button" role="button" onClick={()=>{handlePaginationClick(data.next_page_url)}}>{data.current_page + 1}</button></li>
                        }
                        <li className="waves-effect"><button className="deep-blue" typw="button" role="button" onClick={()=>{handlePaginationClick(data.last_page_url)}}><i className="material-icons">last_page</i></button></li>
                    </ul>
                </div>
            </div>
        }
    </div>
  )
}

export default Pagination;