import React from 'react'
import './Preloader.css'

export default function Preloader(props){

    return(
        <div className={"progress " + props.color} style={{position:(props.position ? props.position : 'absolute'), margin: 0, zIndex: 999}}>
            <div className="indeterminate"></div>
        </div>
    )
}