import React from 'react'
import Banks from './Banks'

export default class ShowBanks extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            data: null,
        }
    }

    async componentDidMount(){
        this.props.togglePreloader(true)
        await this.getBanks()
        this.props.togglePreloader(false)
    }

    getBanks = async () => {
        
    }

    render(){
        return(
            <React.Fragment>
                {this.state.data &&
                    <Banks
                        state={this.state}
                        togglePreloader={this.props.togglePreloader}
                        handlePaginationClick={this.handlePaginationClick.bind(this)}
                        handleEdit={this.handlePaginationClick.bind(this)}
                        handleDelete={this.handlePaginationClick.bind(this)}
                    />
                }
            </React.Fragment>
        )
    }
}