import React from 'react'
import { Link } from 'react-router-dom'
import Preloader from '../Loader/Preloader'
import logout from '../../Functions/logout'
import background from '../../img/sunrise_1280.jpg'
import person from '../../img/person.png'

export default class Sidenav extends React.Component{

    constructor(){
        super()
        this.state = {
            name: localStorage.getItem('name'),
            email: localStorage.getItem('email'),
            showPreloader: false,
            routes: localStorage.getItem('access') ? JSON.parse(localStorage.getItem('access')) : [],
            access: {
                Authenticate: {
                    name: 'Autenticación'
                },
                Bulkload: {
                    name: 'Emisión'
                },
                Foliator: {
                    name: 'Foliador'
                },
                Projects: {
                    name: 'Proyectos'
                },
                Tasks: {
                    name: 'Tareas'
                },
                Reports: {
                    name: 'Reportes'
                },
            }
        }
    }

    handleClick = () => {
        this.setState({showPreloader: true})
        logout()
    }

    render(){
        const { routes, access } = this.state;
        return(
            <ul id="slide-out" className="sidenav">
                {this.state.showPreloader &&
                    <Preloader color="yellownee" />
                }
                <li>
                    <div className="user-view">
                        <div className="background">
                            <img src={background} alt="background"/>
                        </div>
                        <img className="circle" src={person} alt="user"/>
                        <span className="white-text name">{this.state.name}</span>
                        <span className="white-text email">{this.state.email}</span>
                    </div>
                </li>
                <Link to={process.env.REACT_APP_REPORTES_GERENCIALES != "1" ? '/home':'reports'}><li><span className={(this.props.pathname === (process.env.REACT_APP_REPORTES_GERENCIALES != "1" ? '/home' : '/reports') ? 'active' : '')}>{process.env.REACT_APP_REPORTES_GERENCIALES == "1" ? 'Reportes' : 'Dashboard'}</span></li></Link>
                { (routes.length && process.env.REACT_APP_REPORTES_GERENCIALES != "1") &&
                    routes.map(route => {
                        let li
                        if(access.hasOwnProperty(route)){
                                li = <Link to={`/${route}`}><li key={route}><span className="waves-effect">{access[route].name}</span></li></Link>
                        } else {
                            li = null
                        }

                        return li
                    })
                }
                <li><div className="divider"></div></li>
                {/* <li><a className="subheader">Subheader</a></li> */}
                <li onClick={this.handleClick} style={{cursor: 'pointer'}}><i className="material-icons left" style={{marginTop: '12px'}}>power_settings_new</i>Cerrar sesión</li>
            </ul>
        )
}
}