import React from 'react'
import Card from './Card'

const List = props => {
    return props.modules.data.map(module => {
        return(
            <div className="col s12 masonry-element">
                <Card
                    key={props.id}
                    id={module.id}
                    name={module.name}
                    description={module.description}
                    handleEdit={props.handleEdit}
                    handleDelete={props.handleDelete}
                />
            </div>
        )
    })
}

export default List