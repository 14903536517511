import React from 'react'

const Info = props => {
    return(
        <div className="row">
            <div className="col s6 task-description-name">
                {props.name}
            </div>
            <div className="col s6 task-description-content truncate">
                {props.value}
            </div>
        </div>
    )
}

export default Info