import React from 'react'
import logo from '../../img/logo.png'
import Preloader from '../Loader/Preloader'
import Success from '../IziToast/Success'
import Error from '../IziToast/Error'
import Warning from '../IziToast/Warning'
import {verify} from '../../Functions/verify'
import { Redirect } from 'react-router-dom';
import './Login.css'
const axios = require('axios')

export default class Login extends React.Component{

    authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV

    constructor(){
        super()
        this.state = {
            email: '',
            password: '',
            showPreloader: false,
            showPassword: false,
            passwordType: 'password',
            redirect: false,
        }
    }

    async componentDidMount(){

        document.title = 'Mas Servicios'
        const auth = await verify()
        if(auth){
            Success(
                '¡Bienvenido!',
                'Iniciaste sesión',
                'topCenter',
                function(){
                    window.location.href = process.env.REACT_APP_REPORTES_GERENCIALES == "1" ? '/reports' : '/home'
                }
            )
        }
        this.setState({showLoader: false})
    }

    handleChange = e => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            [e.target.id]: value
        })
    }

    handlePassword = () => {
        const passwordType = this.state.passwordType === 'password' ? 'text' : 'password' 
        this.setState({showPassword: !this.state.showPassword, passwordType: passwordType})
    }

    handleSubmit = async e => {
        this.setState({showPreloader: true})
        e.preventDefault()
        e.stopPropagation()
        const token = await this.login(this.state.email, this.state.password)
        if(token){
            const user = await this.profile(token)
            if(user.success){
                const options = JSON.parse(user.user.options)
                if(options.access.length > 0){ 
                    Success(
                        '¡Bienvenido!',
                        'Iniciaste sesión',
                        'topCenter',
                    )
                    await this.setState({ redirect: true })
                } else {
                    Warning(
                        'No autorizado',
                        'Aún no tienes acceso a esta aplicación',
                        'topCenter'
                    )
                }
            }
        } else {
            Error(
                'Error',
                'Credenciales erróneas',
                'topCenter'
            )
        }
        this.setState({showPreloader: false})
    }

    async login(email, password) {
    
        const response = await axios.post(this.authenticate + '/api/login', {
            email,
            password
        })
        .then(res => {
            const data = res.data
            if(data.token){
                localStorage.setItem('token', data.token)
                localStorage.setItem('token_type', data.token_type)
                return data
            }
        })
        .catch(e => {
            console.log(e)
        })
        return response
        
    }

    async profile(tokenRes) {
        try {
            const res = await axios.get(this.authenticate + '/api/user/profile',{
                headers: {'Authorization':tokenRes.token_type+' '+tokenRes.token}
            })
            const data = res.data
            if(data.success){
                const user = data.user
                const options = JSON.parse(user.options)
                const access = JSON.stringify(options.access)
                localStorage.setItem('access', access)
                localStorage.setItem('id', user.id)
                localStorage.setItem('email', user.email)
                localStorage.setItem('name', user.name)
                localStorage.setItem('id_company', options.id_company)
                const response = await axios.get(`${this.authenticate}/api/user/get/information/${user.id}`,{
                    headers: {'Authorization':tokenRes.token_type+' '+tokenRes.token}
                })
                const profileData = response.data;
                const modules = profileData.access.filter(access => access.site === 'Bulkload')
                localStorage.setItem('modules', JSON.stringify(modules))
                return data
            }
        } catch(err) {
            console.log(err)
            return err
        }
    }

    render(){
        return (
            <>
            {this.state.redirect ?
                <Redirect to={process.env.REACT_APP_REPORTES_GERENCIALES == "1" ? '/reports' : '/home'} />
                :
                <div className="row loginCard">
                    <div className="col s12 m10 push-m1 login-margins">
                        <div className="card">
                        {this.state.showPreloader ?
                            <Preloader color="yellownee" />
                            :
                            ''
                        }
                            <div className="card-content white-text">
                                <div className="row" style={{marginBottom: '0'}}>
                                    <div className="col s12 m6">
                                        <img src={logo} style={{width: '50%', marginLeft: '25%'}} alt="logo" /> 
                                        <p style={{textAlign: 'center'}}>
                                            <span className="card-title">Bienvenido</span>
                                            <a href="/reset_password?origin=AuthenticateURL">¿Olvidaste tu contraseña?</a>
                                            <br/>
                                            <a href="/">¿Necestas acceso a alguna aplicación?</a>
                                        </p>
                                    </div>
                                    <span className="developedBy">Desarrollado por Mas Servicios</span>
                                    <div className="col s12 m6" style={{borderLeft: '1px solid #ccc'}}>
                                        <span className="card-title">Inicia sesión</span>
                                        <form className="col s12" onSubmit={this.handleSubmit}>
                                            <div className="row" style={{marginBottom: '0'}}>
                                                <div className="input-field col s12">
                                                    <input placeholder="Correo electrónico" value={this.state.email}  onChange={this.handleChange} id="email" type="text" className="validate" required />
                                                    <label className="blue-sky" htmlFor="email">Correo electrónico</label>
                                                </div>
                                                <div className="input-field col s12">
                                                    {!this.state.showPassword ?
                                                        <i className="material-icons showPassword" onClick={this.handlePassword}>visibility</i>
                                                        :
                                                        <i className="material-icons showPassword" onClick={this.handlePassword}>visibility_off</i>
                                                    }
                                                    <input placeholder="Contraseña" value={this.state.password} onChange={this.handleChange} id="password" type={this.state.passwordType} className="validate" required />
                                                    <label className="blue-sky" htmlFor="password">Contraseña</label>
                                                </div>
                                            </div>
                                            <div>
                                                <button className="btn waves-effect waves-light blue-gradient" type="submit" style={{width: '100%'}}>Entrar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            </>
        )
    }
}