const axios = require('axios')

const fullHeaders = {
    'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token'),
    'content-type': 'application/json'
}

/**
 * @param {String} method GET, POST, PUT, DELETE, OPTIONS
 * @param {String} route Route of the API method
 * @param {Boolean} fullRoute If require  the authenticate const route
 * @param {Boolean} headers If the request needs to generate the authentication headers
 * @param {JSON} data The data of the request
 * @description Show the question message
*/
const request = async function(method, route, fullRoute, headers, data){
    const authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV
    const ops = {
        method,
        headers: headers ? fullHeaders : {'content-type': 'application/json'},
        data: data ? data : '',
        url: fullRoute ? `${authenticate}${route}` : route,
    }
    return await axios(ops)
    .then(response => {
        const data = response.data
        return data
    })
    .catch(e => {
        return {
            success: false,
            error: e
        }
    })
}

export {request}