import React from 'react'
import Axios from 'axios'
import Preloader from '../Loader/Preloader'

export default class Comments extends React.Component {

    authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV
    interval = null

    constructor(props){
        super(props)
        this.state = {
            task: this.props.task,
            comments: false,
            showPreloader: true,
            intervalId: null
        }
    }

    componentDidMount(){
        this.comments()
        if(this.props.taskStatus < 2){
            this.interval = setInterval(() => {
                this.comments()
            }, 5000)
        }
    }

    componentWillUnmount(){
        clearInterval(this.interval)
    }

    comments(){
        Axios.get(this.authenticate + '/api/comment/all/' + this.state.task, {
            headers: {
                Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')
            }
        })
        .then(response => {
            const data = response.data
            this.setState({comments: data.comments, showPreloader: false})
        })
    }

    render(){
        return(
            <React.Fragment>
                {this.state.showPreloader ?
                    <Preloader color="yellownee"/>
                    :
                    <React.Fragment>
                        {this.state.comments.length > 0 ?
                            <React.Fragment>
                                <div className="row">
                                    <div className="col s12 center-align">
                                        <span style={{color: '#ababab'}}>Comentarios</span>
                                    </div>
                                </div>
                                {this.state.comments.map(comment => {
                                    return (
                                        <div key={comment.id} className="card-panel deep-blue-bg white-text" style={{padding: '5px 15px'}}>
                                            <span style={{fontSize: '10px'}}>{comment.created_by_name}</span> • <span style={{fontSize: '10px'}}>{comment.created_at}</span>
                                            <br/>
                                            <span>{comment.comment}</span>
                                        </div>
                                    )
                                })}
                            </React.Fragment>
                            :
                            <div style={{textAlign: 'center', width: '100%', color: '#ababab'}}>
                                <span>No hay comentarios aún</span>
                            </div>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}