import axios from 'axios'

const plansByCompany = async function(company){
    const response = await axios.get((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/plan/names/' + company, 
    {
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const plans = res.data.plans
        let names = []
        for(let i in plans){
            let plan = plans[i]
            names[plan.id] = plan.title
        }
        return names
    })
    .catch(e => {
        console.log(e)
    })
    return response
}

const planStore = async function(data){
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
        data: JSON.stringify(data),
        url: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/plan/store',
    }
    const response = await axios(options)
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
    })

    return response
}

export {plansByCompany, planStore}