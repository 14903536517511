import React from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'

export default class UserCard extends React.Component{

    constructor(props){
        super(props)
    }

    componentDidMount(){
        const tooltips = document.querySelectorAll('.tooltipped')
        M.Tooltip.init(tooltips)
    }

    handleEdit = () => {
        this.props.callbackEdit(this.props.user.id)
    }

    handleDelete = () => {
        this.props.callbackDelete(this.props.user.id)
    }

    handleConfig = () => {
        this.props.callbackConfig(this.props.user.id)
    }

    render(){
        return(
            <div className="col s12 m6">
                <div className="card box">
                    <div className="card-content row">
                        <div className="col s12 m8">
                            {/* <span className="list-description">Datos</span>
                            <br/> */}
                            <span className="item-name deep-blue">{this.props.user.name}</span>
                            <br/>
                            <span>{this.props.user.email}</span>
                        </div>
                        <div className="col s12 m6 buttons-card-box">
                            <button className="btn-floating waves-effect waves-light tooltipped" data-position="top" data-tooltip="Editar" onClick={this.handleEdit}>
                                <i className="material-icons amber-text text-darken-4">edit</i>
                            </button>
                            <button className="btn-floating waves-effect waves-light tooltipped" data-position="top" data-tooltip="Eliminar" onClick={this.handleDelete}>
                                <i className="material-icons red-text">delete</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}