import React from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
import Preloader from '../Loader/Preloader'
import Success from '../IziToast/Success'
import Error from '../IziToast/Error'
import {plansByCompany} from '../../Functions/plans'
import {folioStore} from '../../Functions/folios'

export default class AddFolios extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            companies: this.props.companies,
            company: '',
            plans: null,
            plan: '',
            typeOfFolio: '',
            lengthNumber: '',
            number: '',
            showPreloader: false
        }
    }

    componentDidMount(){
        M.updateTextFields()
        const selects = document.querySelectorAll('select')
        M.FormSelect.init(selects)
    }

    handleChange = e => {
        const value = e.target.value
        if(e.target.id === 'company'){
            this.handleCompany(value)
        }
        if(e.target.id === 'lengthNumber'){
            if(this.state.typeOfFolio === 'alphanumeric'){
                if(value < 12 && value >= 10){
                    this.setState({lengthNumber: 12})
                } else if(value > 30) {
                    this.setState({lengthNumber: 30})
                } else {
                    this.setState({lengthNumber: value})
                }
            } else {
                if(value < 11 && value >= 10){
                    this.setState({lengthNumber: 10})
                } else if(value > 15) {
                    this.setState({lengthNumber: 15})
                } else {
                    this.setState({lengthNumber: value})
                }
            }
        } else {
            this.setState({
                ...this.state,
                [e.target.id]: value
            })
        }
    }

    handleFocusOut = e => {
        const value = e.target.value
        if(this.state.typeOfFolio === 'alphanumeric'){
            if(value < 12){
                this.setState({lengthNumber: 12})
            }
        } else {
            if(value < 11){
                this.setState({lengthNumber: 10})
            }
        }
    }

    handleSubmit = e =>{
        e.stopPropagation()
        e.preventDefault()
        this.setState({showPreloader: true})
        const data = {
            id_company: this.state.company,
            id_plan: this.state.plan,
            number: this.state.number,
            type: this.state.typeOfFolio,
            length: this.state.lengthNumber
        }
        folioStore(data)
        .then(response => {
            if(response.success){
                Success('Correcto', 'Se crearon los folios', 'bottomCenter')
            } else {
                Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
            }
            this.setState({showPreloader: false})
        })
        .catch(e => {
            console.log(e)
            Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
            this.setState({showPreloader: false})
        })
    }

    handleCompany(company){
        plansByCompany(company)
        .then(plans => {
            this.setState({plans: plans})
            const selects = document.querySelectorAll('select')
            M.FormSelect.init(selects)
        })
    }

    render(){
        return(
            <div className="row">
                {this.state.showPreloader &&
                    <Preloader color="yellownee" />
                }
                <form onSubmit={this.handleSubmit} className="col s12">
                    <div className="row" style={{marginBottom: '0'}}>
                        <div className="input-field deep-blue col s12 m6">
                            <select id="company" onChange={this.handleChange} defaultValue={this.state.company} onBlur={this.handleChange}>
                                <option value="" disabled>Selecciona una opción</option>
                                {
                                    this.state.companies.map((company, key) => {
                                        return(
                                            <option key={key} value={key}>{company}</option>
                                        )
                                    })
                                }
                            </select>
                            <label>Compañía</label>
                        </div>
                        <div className="input-field deep-blue col m6 s12">
                            <select id="plan" onChange={this.handleChange} defaultValue={this.state.plan} className="deep-blue" required>
                                <option value="" disabled>Selecciona una opción</option>
                                {this.state.plans !== null &&
                                    this.state.plans.map((company, key) => {
                                        return(
                                            <option key={key} value={key}>{company}</option>
                                        )
                                    })
                                }
                            </select>
                            <label>Plan</label>
                        </div>
                        <div className="input-field deep-blue col m12 s12">
                            <select id="typeOfFolio" onChange={this.handleChange} defaultValue={this.state.typeOfFolio} required>
                                <option value="" disabled>Seleccione una opción</option>
                                <option value="alphanumeric">Alfanumérico</option>
                                <option value="number">Numérico</option>
                            </select>
                            <label>Tipo de caracteres</label>
                        </div>
                        <div className="input-field col m6 s12">
                            <input placeholder="Número de caracteres" id="lengthNumber" className="deep-blue" value={this.state.lengthNumber} onChange={this.handleChange} onBlur={this.handleFocusOut} type="number" min="10" max="30" required />
                            <label htmlFor="lengthNumber" className="greenBlue">Número de caracteres</label>
                        </div>
                        <div className="input-field deep-blue col m6 s12">
                            <input placeholder="Número de folios" id="number" className="deep-blue" value={this.state.number} onChange={this.handleChange} type="number" required />
                            <label htmlFor="number" className="greenBlue">Número de folios</label>
                        </div>
                    </div>
                    <div>
                        <button className="btn waves-effect waves-light blue-gradient" type="submit" style={{width: '100%'}}>Crear</button>
                    </div>
                </form>
            </div>
        )
    }
}