import React from 'react'

/**
 * @param {Object} state State
 * @param {String} column Number of columns
 * @param {String} placeholder Placeholder
 * @param {String} id Id
 * @param {Function} handleChange Function for change handler 
 * @param {String} type Input type
 * @param {String} label Label name
 * @description Input
*/
const Input = props => {
    let isRequired = props.requiredMessage ? true : false;
    return(
        <div className={`input-field deep-blue col ${props.column}`}>
            <input
            {...props}
                placeholder={props.placeholder}
                id={props.id}
                className="deep-blue"
                value={props.state[props.id]}
                onChange={props.handleChange}
                type={props.type}
                required={isRequired}
            />
            <label
                htmlFor={props.id}
                className="greenBlue"
            >
                {props.label}
                {props.requiredMessage ? 
                <small style={{color:"#ff5722"}}>&nbsp;{props.requiredMessage}</small>
                : ''}
            </label>

        </div>
    )
}

export default Input