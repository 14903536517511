import React from 'react'
import Preloader from '../Loader/Preloader'
import M from 'materialize-css/dist/js/materialize.min.js'
import MaterialDateTimePicker from 'material-datetime-picker'
import 'material-datetime-picker/dist/material-datetime-picker.css'
let picker = ''

export default class DepartmentModal extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            created_by: localStorage.getItem('id'),
            belongs_to: '',
            belongs_toId: '',
            name: '',
            observations: '',
            status: 0,
            priority: '',
            watchers: '',
            attached: '',
            estimated_finish_date: '',
            finish_date: '',
            users: this.props.users
        }
    }

    componentDidMount(){
        M.updateTextFields()
        const selects = document.querySelectorAll('select')
        M.FormSelect.init(selects)
        const textArea = document.getElementById('observations')
        M.textareaAutoResize(textArea)
        const autocomplete = document.getElementById('belongs_to')
        M.Autocomplete.init(autocomplete,
            {
                data: this.state.users.names,
                onAutocomplete: complete => {
                    const filtered = this.state.users.data.filter(user => user.name === complete)
                    this.setState({belongs_toId: filtered[0].id, belongs_to: filtered[0].name})
                }
            }
        )
        picker = new MaterialDateTimePicker({
            el: document.getElementById('belongs_to'),
            container: document.getElementById('modalAddTask')
        })
        .on('submit', (val) => {
            const date = new Date(val)
            console.log(date.getFullYear() + '-' + (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()) + ' ' + (date.getHours() <= 9 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes()) + ':' + (date.getSeconds() <= 9 ? '0' + date.getSeconds() : date.getSeconds()))
            console.log(`data: ${val}`)
            const efd = date.getFullYear() + '-' + (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()) + ' ' + (date.getHours() <= 9 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes()) + ':' + (date.getSeconds() <= 9 ? '0' + date.getSeconds() : date.getSeconds())
            this.setState({estimated_finish_date: efd})
        })
    }

    handleChange = e => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            [e.target.id]: value
        })
    }

    handleSubmit = e => {
        e.preventDefault()
        e.stopPropagation()
        this.props.callbackSubmit({
            created_by: this.state.created_by,
            belongs_to: this.state.belongs_toId,
            name: this.state.name,
            observations: this.state.observations,
            status: this.state.status,
            priority: this.state.priority,
            watchers: 'none',
            attached: 'none',
            estimated_finish_date: this.state.estimated_finish_date,
            finish_date: this.state.estimated_finish_date,
        })
    }

    handleOpenPicker = () => {
        picker.open()
    }

    handleClose = () => {
        this.props.callbackClose('modalAddTask')
    }

    render(){
        return(
            <div id="modalAddTask" className="modal modal-large deep-blue-bg">
                {this.state.showPreloader ?
                    <Preloader color="yellownee" />
                    :
                    ''
                }
                <i className="material-icons close-modal-btn" onClick={this.handleClose}>close</i>
                <div className="modal-content">
                    <div className="row">
                        <form onSubmit={this.handleSubmit} className="col s12">
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <input placeholder="Nombre" className="validate" value={this.state.name} onChange={this.handleChange} id="name" type="text" required />
                                    <label className="blue-sky" htmlFor="name">Nombre</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12">
                                    <input placeholder="Asignar a" className="validate autocomplete" id="belongs_to" type="text" value={this.state.belongs_to} onChange={this.handleChange} />
                                    <label className="blue-sky" htmlFor="belongs_to">Asignar a</label>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <textarea placeholder="Observaciones" className="materialize-textarea white-text" value={this.state.observations}  onChange={this.handleChange} id="observations" type="text" required></textarea>
                                    <label className="blue-sky" htmlFor="observations">Observaciones</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12">
                                    <select id="priority" onChange={this.handleChange} value={this.state.priority}>
                                        <option value="" disabled>Selecciona una opción</option>
                                        <option value="0">Baja</option>
                                        <option value="1">Media</option>
                                        <option value="2">Alta</option>
                                    </select>
                                    <label>Prioridad</label>
                                </div>
                            </div>
                            <div className="input-field col s12">
                                <input placeholder="Fecha estimada" id="estimated_finish_date" className="validate white-text" value={this.state.estimated_finish_date}  onClick={this.handleOpenPicker} type="text" />
                                <label className="blue-sky" htmlFor="estimated_finish_date">Fecha estimada</label>
                            </div>
                            <div>
                                <button className="btn waves-effect waves-light blue-gradient" type="submit" style={{width: '100%'}}>Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}