import React from 'react'
import './Cards.css'

export default class UrlCard extends React.Component{

    handleClick = () => {
        this.props.callback(this.props.section)
    }

    render(){
        return(
            <div className="col s12 m6">
                <div className="card url-card">
                    <div className="card-content">
                        <span className="card-title gray">{this.props.title}</span>
                        <p>{this.props.text}</p>
                    </div>
                    <div className="card-under waves-effect waves-light flexbox" onClick={this.handleClick}>
                        <span>{this.props.button}</span>
                    </div>
                </div>
            </div>
        )
    }
}