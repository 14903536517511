import React from 'react';
import ReactDOM from 'react-dom';
const modal = document.querySelector('#modal-root');

const RecordModal = ({ title, children, isOpened, onClose }) => (
  isOpened ? 
  ReactDOM.createPortal(
    <div className="card card-modal deep-blue-bg">
        <div className="card-content white-text">
          <span className="card-title">{title}</span>
          {children}
        </div>
        <div className="card-action">
          <button
            className="btn-flat waves-effect waves-light white-text"
            name="action"
            onClick={onClose}>
              Cerrar
          </button>
        </div>
      </div>,
    modal
  )
  : null
);

export default RecordModal;