import React from 'react'
import './Checkbox.scss'

/**
 * @param {String} off Off label
 * @param {String} on On Label
 * @param {Function} toggleSwitch Toggle function
 * @description Switch
*/
const Switch = props => {
    return(
        <div className="switch">
            <label>
            {props.off}
            {props.checked === true ?
                <input type="checkbox" onChange={props.toggleSwitch} id={props.id} checked/>
                :
                <input type="checkbox" onChange={props.toggleSwitch} id={props.id}/>
            }
            <span className="lever"></span>
            {props.on}
            </label>
        </div>
    )
}

export default Switch