import React from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'

export default class DepartmentCard extends React.Component{

    componentDidMount(){
        const tooltips = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(tooltips);
    }

    handleEdit = () => {
        this.props.callbackEdit(this.props.department.id)
    }

    handleDisable = () => {
        this.props.callbackDelete(this.props.department.id)
    }

    render(){
        return(
            <div className="col s12 m6">
                <div className="card box border-red">
                    <div className="card-content row">
                        <div className="col s12 m8">
                            <span className="item-name deep-blue">{this.props.department.name}</span>
                            <br/>
                            <span className="list-description">Pertenece a</span>
                            <br/>
                            <span>{this.props.belongsTo}</span>
                        </div>
                        <div className="col s12 m6 buttons-card-box">
                            <button className="btn-floating waves-effect waves-light tooltipped" data-position="top" data-tooltip="Editar" onClick={this.handleEdit}>
                                <i className="material-icons amber-text text-darken-4">edit</i>
                            </button>
                            <button className="btn-floating waves-effect waves-light tooltipped" data-position="top" data-tooltip="Deshabilitar" onClick={this.handleDisable}>
                                <i className="material-icons red-text">delete</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}