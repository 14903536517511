import React from 'react'
import ActionButton from '../../Buttons/ActionButton'
import Card from '../../Cards/Card'
import Input from '../../Inputs/Input'
import Switch from '../../Checkbox/Switch'
import moment from 'moment';

const AddGuest = props => {
    const getBirthDateDateLimits = () => {
        var startDate = moment();
        startDate = startDate.subtract(100, "years");
        startDate = startDate.format("YYYY-MM-DD");

        var endDate = moment().format('YYYY-MM-DD');

        return [startDate, endDate];
    }

    return(
        <Card
            columns="s12 m8 l6 push-m2 push-l3"
            color="white"
            white={false}
            style={{marginTop: 'calc(50vh - 288px)', borderRadius: '0px 0px 10px 10px', borderTop: '5px solid rgb(60 152 204)', boxShadow: '0px 0px 32px rgba(0,0,0,.8)'}}
        >
            {props.state.language ?
                <React.Fragment>
                    <p className="subTitle" style={{marginBottom: '15px'}}>Information</p>
                    <Input
                        state={props.state}
                        column="s12"
                        placeholder="Nombre"
                        id="guestFirstName"
                        handleChange={props.handleChange}
                        type="text"
                        label="Nombre"
                    />
                    <Input
                        state={props.state}
                        column="s12"
                        placeholder="Apellidos"
                        id="guestLastName"
                        handleChange={props.handleChange}
                        type="text"
                        label="Apellidos"
                    />
                    <div className={`input-field col s12`}>
                        <input type="date" id={"guestBirth"} name={"guestBirth"} className="deep-blue" min={getBirthDateDateLimits()[0]} max={getBirthDateDateLimits()[1]} onChange={props.handleChange}/>
                        <label htmlFor={"guestBirth"}>Fecha de nacimiento</label>
                    </div>

                    <div className="col s12" align="center" style={{margin: '30px 0'}}>
                        <Switch
                            off="Masculino"
                            on="Femenino"
                            id="guestGenre"
                            toggleSwitch={props.handleToggleSwitch}
                            checked={props.state.guestGenre}
                        />
                    </div>
                    <ActionButton color="deep-blue-bg white-text" icon="save" text="Guardar huésped" fullWidth={false} action={props.action}/>
                    <ActionButton color="yellow darken-4 white-text" icon="cancel" text="Cancelar" fullWidth={false} action={props.handleCancelAddEditGuest}/>
                </React.Fragment>
                :
                <React.Fragment>
                    <p className="subTitle" style={{marginBottom: '15px'}}>Information</p>
                    <Input
                        state={props.state}
                        column="s12"
                        placeholder="First name"
                        id="guestFirstName"
                        handleChange={props.handleChange}
                        type="text"
                        label="First name"
                    />
                    <Input
                        state={props.state}
                        column="s12"
                        placeholder="Last name"
                        id="guestLastName"
                        handleChange={props.handleChange}
                        type="text"
                        label="Last name"
                    />
                    <div className={`input-field col s12`}>
                        <input type="date" id={"guestBirth"} name={"guestBirth"} className="deep-blue" min={getBirthDateDateLimits()[0]} max={getBirthDateDateLimits()[1]} onChange={props.handleChange}/>
                        <label htmlFor={"guestBirth"}>Birth date</label>
                    </div>

                    <div className="col s12" align="center" style={{margin: '30px 0'}}>
                        <Switch
                            off="Male"
                            on="Female"
                            id="guestGenre"
                            toggleSwitch={props.handleToggleSwitch}
                            checked={props.state.guestGenre}
                        />
                    </div>
                    <ActionButton color="deep-blue-bg white-text" icon="save" text="Save guest" fullWidth={false} action={props.action}/>
                    <ActionButton color="yellow darken-4 white-text" icon="cancel" text="Cancel" fullWidth={false} action={props.handleCancelAddEditGuest}/>
                </React.Fragment>
            }
        </Card>
    )
}

export default AddGuest