import React from 'react'
import Comments from './Comments'
import M from 'materialize-css/dist/js/materialize.min.js'
import Axios from 'axios'
import Preloader from '../Loader/Preloader'
import Success from '../IziToast/Success'
import Error from '../IziToast/Error'
import {updateWatchers} from '../../Functions/tasks'
const priority = ['Baja', 'Media', 'Alta']
const status = ['Pendiente', 'En proceso', 'Terminada', 'Cancelada']

export default class TaskView extends React.Component {

    authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV

    constructor(props){
        super(props)
        this.state = {
            showPreloaderStatus: false,
            showPreloaderComment: false,
            showComments: false,
            showCommentInput: false,
            showAddWatchers: false,
            comment: '',
            commentId: this.props.task.id,
            status: this.props.task.status,
            watchers: this.props.task.watchers !== 'none' ? this.props.task.watchers.split(',') : 'none',
            newWatchers: null,
            tags: null
        }
    }

    componentDidMount(){
        M.AutoInit()
        M.updateTextFields()
        const fab = document.querySelectorAll('.fixed-action-btn')
        M.FloatingActionButton.init(fab, {toolbarEnabled: true})
        const tooltips = document.querySelectorAll('.tooltipped')
        M.Tooltip.init(tooltips)
        const tags = []
        if(typeof this.state.watchers !== 'string'){
            this.state.watchers.forEach(watcher => {
                const filtered = this.props.users.data.filter(user => user.id == watcher)
                tags.push({tag: filtered[0].name})
            })
            this.addState({name:'tags', state: tags})
            .then(() => {
                const autocomplete = document.getElementById('addWatchers')
                M.Chips.init(autocomplete,
                    {
                        data: this.state.tags,
                        autocompleteOptions: {
                            data: this.props.users.names
                        }
                    }
                )
            })
        } else {
            const autocomplete = document.getElementById('addWatchers')
            M.Chips.init(autocomplete,
                {
                    autocompleteOptions: {
                        data: this.props.users.names
                    }
                }
            )
        }
        
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.task !== this.props.task){
            this.setState({
                ...this.state,
                showComments: false,
                showCommentInput: false,
                comment: '',
                commentId: this.props.task.id,
                watchers: this.props.task.watchers !== 'none' ? this.props.task.watchers.split(',') : 'none',
                status: this.props.task.status
            })
            if(this.props.task.watchers !== 'none'){
                const tags = []
                const watch = this.props.task.watchers.split(',')
                watch.forEach(watcher => {
                    const filtered = this.props.users.data.filter(user => user.id == watcher)
                    tags.push({tag: filtered[0].name})
                })
                this.addState({name:'tags', state: tags})
                .then(() => {
                    const autocomplete = document.getElementById('addWatchers')
                    M.Chips.init(autocomplete,
                        {
                            data: this.state.tags,
                            autocompleteOptions: {
                                data: this.props.users.names
                            }
                        }
                    )
                })
            } else {
                const autocomplete = document.getElementById('addWatchers')
                M.Chips.init(autocomplete,
                    {
                        autocompleteOptions: {
                            data: this.props.users.names
                        }
                    }
                )
            }
        }

    }

    handleNewComment = () => {
        this.setState({showCommentInput: true})
    }

    handleCloseComment = () => {
        this.setState({comment: '',showCommentInput: false})
    }

    handleWatchComments = () => {
        this.setState({showComments: !this.state.showComments})
    }

    handleChange = e => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            [e.target.id]: value
        })
    }

    handleAddComment = () => {
        this.setState({showPreloaderComment: true, commentId: 0})
        const res = {
            task_id: this.props.task.id,
            created_by: localStorage.getItem('id'),
            comment: this.state.comment,
            attached: 'none',
            tags: {users: [localStorage.getItem('id')]}
        }
        const options = {
            method: 'POST',
            headers: { 
                'content-type': 'application/json',
                Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token'),
            },
            data: JSON.stringify(res),
            url: this.authenticate + '/api/comment/store'
        }
        Axios(options)
        .then(response => {
            const data = response.data
            if(data.success){
                this.setState({comment: '', showCommentInput: false, commentId: this.props.task.id, showPreloaderComment: false})
                Success('Guardado', 'Comentario enviado', 'topRight')
            } else {
                this.setState({showPreloaderComment: false})
                Error('Error', 'Ocurrió algún error desconocido', 'topRight')
            }
        })
        .catch(e => {
            this.setState({showPreloaderComment: false})
            console.log(e)
            Error('Error', 'Ocurrió algún error desconocido', 'topRight')
        })
    }

    handleUpdateStatus(status){
        this.setState({showPreloaderStatus: true})
        const options = {
            method: 'PUT',
            headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
            url: this.authenticate + '/api/task/update/status/' + this.props.task.id + '/' + status
        }
        Axios(options)
        .then(response => {
            const data = response.data
            if(data.success){
                this.setState({status: status, showPreloaderStatus: false})
                Success('Actualizado', 'Se actualizó el estatus de la tarea', 'topRight')
            } else {
                this.setState({showPreloaderStatus: false})
                Error('Error', 'Ocurrió algún error desconocido', 'topRight')
            }
        })
        .catch(e => {
            this.setState({showPreloaderStatus: false})
            console.log(e)
            Error('Error', 'Ocurrió algún error desconocido', 'topRight')
        })
    }

    handleAddWatchers = () => {
        this.setState({showAddWatchers: true})
    }

    handleUpdateWatchers = () => {
        const autocomplete = document.getElementById('addWatchers')
        const chips = M.Chips.getInstance(autocomplete)
        let watchers = []
        if(chips.chipsData.length !== 0){
            chips.chipsData.forEach(chip => {
                const filtered = this.props.users.data.filter(user => user.name === chip.tag)
                watchers.push(filtered[0].id)
            })
            watchers = watchers.join(',')
        } else {
            watchers = 'none'
        }

        this.addState({name:'newWatchers', state: (watchers === 'none' ? watchers : watchers.split(',')) })
        .then(() => {
            updateWatchers(this.props.task.id, this.state.newWatchers)
            .then(response => {
                if(response.success){
                    Success('Guardado', 'Los observadores fueron guardados', 'topRight')
                    this.setState({newWatchers: null, showAddWatchers: false})
                } else {
                    Error('Error', 'Ocurrio un error inesperado', 'topRight')
                }
            })
            .catch(e => {
                console.log(e)
                Error('Error', 'Ocurrio un error inesperado', 'topRight')
            })
        })
        
    }

    addState = async data => {
        const name = data.name
        const state = data.state
        await this.setState({[name]: state})
    }

    handleCloseWatchers = () => {
        this.setState({showAddWatchers: false})
    }

    render(){
        return(
            <React.Fragment>
                {this.state.showPreloaderStatus &&
                    <Preloader color="yellownee" />
                }
                <div className="card-panel" style={{marginLeft: '10px', borderRadius: '3px'}}>
                    <div className="ribbon">
                        <span className="ribbon-title">{this.props.task.name}</span>
                    </div>
                    {this.state.status < 2 &&
                        <div className="fixed-action-btn toolbar direction-top tooltipped" data-position="top" data-tooltip="Cambiar status" style={{position: 'absolute'}}>
                            <a className="btn-floating btn-small blue-sky-bg">
                                <i className="large material-icons">more_vert</i>
                            </a>
                            <ul className="blue-gradient">
                                {this.state.status === 0 ?
                                    <React.Fragment>
                                        <li className="waves-effect waves-light tooltipped" data-position="bottom" data-tooltip="En proceso" onClick={() => this.handleUpdateStatus(1)}><a href="#!"><i className="material-icons">hourglass_empty</i></a></li>
                                        <li className="waves-effect waves-light tooltipped" data-position="bottom" data-tooltip="Terminada" onClick={() => this.handleUpdateStatus(2)}><a href="#!"><i className="material-icons">check</i></a></li>
                                        {this.props.task.created_by == localStorage.getItem('id') &&
                                            <li className="waves-effect waves-light tooltipped" data-position="bottom" data-tooltip="Cancelada" onClick={() => this.handleUpdateStatus(3)}><a href="#!"><i className="material-icons">close</i></a></li>
                                        }
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <li className="waves-effect waves-light tooltipped" data-position="bottom" data-tooltip="Terminada" onClick={() => this.handleUpdateStatus(2)}><a href="#!"><i className="material-icons">check</i></a></li>
                                        {this.props.task.created_by == localStorage.getItem('id') &&
                                            <li className="waves-effect waves-light tooltipped" data-position="bottom" data-tooltip="Cancelada" onClick={() => this.handleUpdateStatus(3)}><a href="#!"><i className="material-icons">close</i></a></li>
                                        }
                                    </React.Fragment>
                                }
                            </ul>
                        </div>
                    }
                    <div className="row" style={{marginTop: '15px'}}>
                        <div className="col s12">
                            <div className="row">
                                <div className="col s6 task-description-name">
                                    Creado por
                                </div>
                                <div className="col s6 task-description-content">
                                    {this.props.task.created_by_name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s6 task-description-name">
                                    Pertenece a
                                </div>
                                <div className="col s6 task-description-content">
                                {this.props.task.belongs_to_name}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s6 task-description-name">
                                    Estatus
                                </div>
                                <div className="col s6 task-description-content">
                                    {status[this.state.status]}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s6 task-description-name">
                                    Prioridad
                                </div>
                                <div className="col s6 task-description-content">
                                    {priority[this.props.task.priority]}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s6 task-description-name">
                                    Observadores
                                </div>
                                <div className="col s6 task-description-content" style={{position: 'relative'}}>
                                    {this.state.watchers === 'none' ? 
                                        'none'
                                        :
                                        this.state.watchers.map(watcher => {
                                            const filtered = this.props.users.data.filter(user => user.id == watcher)
                                            return filtered[0].name + ', '
                                        })
                                    }
                                    <button className="btn-floating btn-small waves-effect waves-light tooltipped red" style={{position: 'absolute', right: 0}} data-position="left" data-tooltip="Agregar" onClick={this.handleAddWatchers}>
                                        <i className="material-icons">add</i>
                                    </button>
                                </div>
                                <div className="col s12" style={{display: this.state.showAddWatchers ? 'block' : 'none'}}>
                                    <div id="addWatchers" className="chips"></div>
                                    <div className="row">
                                        <div className="col s6">
                                            <button className="waves-effect waves-light btn-small blue-gradient" style={{width: '100%'}} onClick={this.handleUpdateWatchers}><i className="material-icons left">save</i>Guardar</button>
                                        </div>
                                        <div className="col s6">
                                            <button className="waves-effect waves-light btn-small red-gradient" style={{width: '100%'}} onClick={this.handleCloseWatchers}><i className="material-icons left">close</i>Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s6 task-description-name">
                                    Adjuntos
                                </div>
                                <div className="col s6 task-description-content">
                                    {this.props.task.attached}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s6 task-description-name">
                                    Fecha estimada de finalización
                                </div>
                                <div className="col s6 task-description-content">
                                    {this.props.task.estimated_finish_date}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s6 task-description-name">
                                    Fecha de finalización
                                </div>
                                <div className="col s6 task-description-content">
                                    {this.state.status < 2 ? '' : this.props.task.finish_date}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 task-description-name">
                                    Observaciones
                                </div>
                                <div className="col s12 task-description-content">
                                    {this.props.task.observations}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {!this.state.showComments ?
                                <div className="col s12">
                                    <button className="waves-effect waves-light btn-flat" style={{width: '100%', color: '#ababab'}} onClick={this.handleWatchComments}>Ver comentarios</button>
                                </div>
                                :
                                <React.Fragment>
                                    <div className="col s12">
                                    <Comments task={this.state.commentId} taskStatus={this.props.task.status}/>
                                    </div>
                                    <div className="col s12">
                                        <button className="waves-effect waves-light btn-flat" style={{width: '100%', color: '#ababab'}} onClick={this.handleWatchComments}>Ocultar comentarios</button>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                        {this.state.showCommentInput ?
                            <div className="row">
                                {this.state.showPreloaderComment &&
                                    <Preloader color="yellownee" />
                                }
                                <div className="input-field col s12">
                                    <textarea className="deep-blue materialize-textarea" value={this.state.comment} onChange={this.handleChange} id="comment" type="text" required ></textarea>
                                    <label className="blue-sky" htmlFor="comment">Comentario</label>
                                </div>
                                <div className="col s12">
                                    <div className="row">
                                        <div className="col s6">
                                            <button className="waves-effect waves-light btn-small blue-gradient" style={{width: '100%'}} onClick={this.handleAddComment}><i className="material-icons left">send</i>Enviar</button>
                                        </div>
                                        <div className="col s6">
                                            <button className="waves-effect waves-light btn-small red-gradient" style={{width: '100%'}} onClick={this.handleCloseComment}><i className="material-icons left">close</i>Cancelar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            (this.state.status < 2 &&
                                <button className="waves-effect waves-light btn red-gradient" style={{width: '100%'}} onClick={this.handleNewComment}><i className="material-icons left">chat_bubble_outline</i>Agregar un comentario</button>
                            )
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}