import React from 'react'
import Pagination from '../../Authenticate/Sites/Pagination'
import InfoCard from '../../Cards/InfoCard'

const Banks = props => {
    return(
        <React.Fragment>
            <Pagination
                state={props.state}
                handlePaginationClick={props.handlePaginationClick}
            />
            <div className="col s12">
                <React.Fragment>
                {props.state.data.data &&
                    <div className="row card-list">
                        {props.state.data.data.map(user => {
                                return (
                                    <InfoCard
                                        state={props.state}
                                        handleEdit={props.handleEdit}
                                        handleDelete={props.handleDelete}
                                    >
                                        <span className="item-name deep-blue">{user.name}</span>
                                        <br/>
                                        <span>{user.business_name}</span>
                                    </InfoCard>
                                )
                            })
                        }
                    </div>
                }
                </React.Fragment>    
            </div>
        </React.Fragment>
    )
}

export default Banks