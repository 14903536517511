import React from 'react';
import Card from '../../Cards/Card';
import Form from '../../Forms/Form';
import Input from '../../Inputs/Input';
import Switch from '../../Checkbox/Switch';
import Datepicker from '../../Inputs/Datepicker';
import Scrollbars from 'react-custom-scrollbars';
import Select from '../../Inputs/Select';
import moment from 'moment';
import './Complementation-styles.css';

const Complementation = props => {

    const langCountry = {
        es: [
            {name: 'Afganistán'},
            {name: 'Albania'},
            {name: 'Alemania'},
            {name: 'Andorra'},
            {name: 'Angola'},
            {name: 'Anguila'},
            {name: 'Antártida'},
            {name: 'Antigua y Barbuda'},
            {name: 'Arabia Saudita'},
            {name: 'Argelia'},
            {name: 'Argentina'},
            {name: 'Armenia'},
            {name: 'Aruba'},
            {name: 'Australia'},
            {name: 'Austria'},
            {name: 'Azerbaiyán'},
            {name: 'Bélgica'},
            {name: 'Bahamas'},
            {name: 'Bahrein'},
            {name: 'Bangladesh'},
            {name: 'Barbados'},
            {name: 'Belice'},
            {name: 'Benín'},
            {name: 'Bhután'},
            {name: 'Bielorrusia'},
            {name: 'Birmania'},
            {name: 'Bolivia'},
            {name: 'Bosnia y Herzegovina'},
            {name: 'Botsuana'},
            {name: 'Brasil'},
            {name: 'Brunéi'},
            {name: 'Bulgaria'},
            {name: 'Burkina Faso'},
            {name: 'Burundi'},
            {name: 'Cabo Verde'},
            {name: 'Camboya'},
            {name: 'Camerún'},
            {name: 'Canadá'},
            {name: 'Chad'},
            {name: 'Chile'},
            {name: 'China'},
            {name: 'Chipre'},
            {name: 'Ciudad del Vaticano'},
            {name: 'Colombia'},
            {name: 'Comoras'},
            {name: 'República del Congo'},
            {name: 'República Democrática del Congo'},
            {name: 'Corea del Norte'},
            {name: 'Corea del Sur'},
            {name: 'Costa de Marfil'},
            {name: 'Costa Rica'},
            {name: 'Croacia'},
            {name: 'Cuba'},
            {name: 'Curazao'},
            {name: 'Dinamarca'},
            {name: 'Dominica'},
            {name: 'Ecuador'},
            {name: 'Egipto'},
            {name: 'El Salvador'},
            {name: 'Emiratos Árabes Unidos'},
            {name: 'Eritrea'},
            {name: 'Eslovaquia'},
            {name: 'Eslovenia'},
            {name: 'España'},
            {name: 'Estados Unidos de América'},
            {name: 'Estonia'},
            {name: 'Etiopía'},
            {name: 'Filipinas'},
            {name: 'Finlandia'},
            {name: 'Fiyi'},
            {name: 'Francia'},
            {name: 'Gabón'},
            {name: 'Gambia'},
            {name: 'Georgia'},
            {name: 'Ghana'},
            {name: 'Gibraltar'},
            {name: 'Granada'},
            {name: 'Grecia'},
            {name: 'Groenlandia'},
            {name: 'Guadalupe'},
            {name: 'Guam'},
            {name: 'Guatemala'},
            {name: 'Guayana Francesa'},
            {name: 'Guernsey'},
            {name: 'Guinea'},
            {name: 'Guinea Ecuatorial'},
            {name: 'Guinea-Bissau'},
            {name: 'Guyana'},
            {name: 'Haití'},
            {name: 'Honduras'},
            {name: 'Hong kong'},
            {name: 'Hungría'},
            {name: 'India'},
            {name: 'Indonesia'},
            {name: 'Irán'},
            {name: 'Irak'},
            {name: 'Irlanda'},
            {name: 'Isla Bouvet'},
            {name: 'Isla de Man'},
            {name: 'Isla de Navidad'},
            {name: 'Isla Norfolk'},
            {name: 'Islandia'},
            {name: 'Islas Bermudas'},
            {name: 'Islas Caimán'},
            {name: 'Islas Cocos (Keeling)'},
            {name: 'Islas Cook'},
            {name: 'Islas de Åland'},
            {name: 'Islas Feroe'},
            {name: 'Islas Georgias del Sur y Sandwich del Sur'},
            {name: 'Islas Heard y McDonald'},
            {name: 'Islas Maldivas'},
            {name: 'Islas Malvinas'},
            {name: 'Islas Marianas del Norte'},
            {name: 'Islas Marshall'},
            {name: 'Islas Pitcairn'},
            {name: 'Islas Salomón'},
            {name: 'Islas Turcas y Caicos'},
            {name: 'Islas Ultramarinas Menores de Estados Unidos'},
            {name: 'Islas Vírgenes Británicas'},
            {name: 'Islas Vírgenes de los Estados Unidos'},
            {name: 'Israel'},
            {name: 'Italia'},
            {name: 'Jamaica'},
            {name: 'Japón'},
            {name: 'Jersey'},
            {name: 'Jordania'},
            {name: 'Kazajistán'},
            {name: 'Kenia'},
            {name: 'Kirguistán'},
            {name: 'Kiribati'},
            {name: 'Kuwait'},
            {name: 'Líbano'},
            {name: 'Laos'},
            {name: 'Lesoto'},
            {name: 'Letonia'},
            {name: 'Liberia'},
            {name: 'Libia'},
            {name: 'Liechtenstein'},
            {name: 'Lituania'},
            {name: 'Luxemburgo'},
            {name: 'México'},
            {name: 'Mónaco'},
            {name: 'Macao'},
            {name: 'Macedônia'},
            {name: 'Madagascar'},
            {name: 'Malasia'},
            {name: 'Malawi'},
            {name: 'Mali'},
            {name: 'Malta'},
            {name: 'Marruecos'},
            {name: 'Martinica'},
            {name: 'Mauricio'},
            {name: 'Mauritania'},
            {name: 'Mayotte'},
            {name: 'Micronesia'},
            {name: 'Moldavia'},
            {name: 'Mongolia'},
            {name: 'Montenegro'},
            {name: 'Montserrat'},
            {name: 'Mozambique'},
            {name: 'Namibia'},
            {name: 'Nauru'},
            {name: 'Nepal'},
            {name: 'Nicaragua'},
            {name: 'Niger'},
            {name: 'Nigeria'},
            {name: 'Niue'},
            {name: 'Noruega'},
            {name: 'Nueva Caledonia'},
            {name: 'Nueva Zelanda'},
            {name: 'Omán'},
            {name: 'Países Bajos'},
            {name: 'Pakistán'},
            {name: 'Palau'},
            {name: 'Palestina'},
            {name: 'Panamá'},
            {name: 'Papúa Nueva Guinea'},
            {name: 'Paraguay'},
            {name: 'Perú'},
            {name: 'Polinesia Francesa'},
            {name: 'Polonia'},
            {name: 'Portugal'},
            {name: 'Puerto Rico'},
            {name: 'Qatar'},
            {name: 'Reino Unido'},
            {name: 'República Centroafricana'},
            {name: 'República Checa'},
            {name: 'República Dominicana'},
            {name: 'República de Sudán del Sur'},
            {name: 'Reunión'},
            {name: 'Ruanda'},
            {name: 'Rumanía'},
            {name: 'Rusia'},
            {name: 'Sahara Occidental'},
            {name: 'Samoa'},
            {name: 'Samoa Americana'},
            {name: 'San Bartolomé'},
            {name: 'San Cristóbal y Nieves'},
            {name: 'San Marino'},
            {name: 'San Martín (Francia)'},
            {name: 'San Pedro y Miquelón'},
            {name: 'San Vicente y las Granadinas'},
            {name: 'Santa Elena'},
            {name: 'Santa Lucía'},
            {name: 'Santo Tomé y Príncipe'},
            {name: 'Senegal'},
            {name: 'Serbia'},
            {name: 'Seychelles'},
            {name: 'Sierra Leona'},
            {name: 'Singapur'},
            {name: 'Sint Maarten'},
            {name: 'Siria'},
            {name: 'Somalia'},
            {name: 'Sri lanka'},
            {name: 'Sudáfrica'},
            {name: 'Sudán'},
            {name: 'Suecia'},
            {name: 'Suiza'},
            {name: 'Surinám'},
            {name: 'Svalbard y Jan Mayen'},
            {name: 'Swazilandia'},
            {name: 'Tayikistán'},
            {name: 'Tailandia'},
            {name: 'Taiwán'},
            {name: 'Tanzania'},
            {name: 'Territorio Británico del Océano Índico'},
            {name: 'Territorios Australes y Antárticas Franceses'},
            {name: 'Timor Oriental'},
            {name: 'Togo'},
            {name: 'Tokelau'},
            {name: 'Tonga'},
            {name: 'Trinidad y Tobago'},
            {name: 'Tunez'},
            {name: 'Turkmenistán'},
            {name: 'Turquía'},
            {name: 'Tuvalu'},
            {name: 'Ucrania'},
            {name: 'Uganda'},
            {name: 'Uruguay'},
            {name: 'Uzbekistán'},
            {name: 'Vanuatu'},
            {name: 'Venezuela'},
            {name: 'Vietnam'},
            {name: 'Wallis y Futuna'},
            {name: 'Yemen'},
            {name: 'Yibuti'},
            {name: 'Zambia'},
            {name: 'Zimbabue'}
        ],
        en: [
            {name: 'Afghanistan'},
            {name: 'Albania'},
            {name: 'Germany'},
            {name: 'Andorra'},
            {name: 'Angola'},
            {name: 'Anguilla'},
            {name: 'Antarctica'},
            {name: 'Antigua and Barbuda'},
            {name: 'Saudi Arabia'},
            {name: 'Algeria'},
            {name: 'Argentina'},
            {name: 'Armenia'},
            {name: 'Aruba'},
            {name: 'Australia'},
            {name: 'Austria'},
            {name: 'Azerbaijan'},
            {name: 'Belgium'},
            {name: 'Bahamas'},
            {name: 'Bahrain'},
            {name: 'Bangladesh'},
            {name: 'Barbados'},
            {name: 'Belize'},
            {name: 'Benin'},
            {name: 'Bhutan'},
            {name: 'Belarus'},
            {name: 'Myanmar'},
            {name: 'Bolivia'},
            {name: 'Bosnia and Herzegovina'},
            {name: 'Botswana'},
            {name: 'Brazil'},
            {name: 'Brunei'},
            {name: 'Bulgaria'},
            {name: 'Burkina Faso'},
            {name: 'Burundi'},
            {name: 'Cape Verde'},
            {name: 'Cambodia'},
            {name: 'Cameroon'},
            {name: 'Canada'},
            {name: 'Chad'},
            {name: 'Chile'},
            {name: 'China'},
            {name: 'Cyprus'},
            {name: 'Vatican City State'},
            {name: 'Colombia'},
            {name: 'Comoros'},
            {name: 'Republic of the Congo'},
            {name: 'Democratic Republic of the Congo'},
            {name: 'North Korea'},
            {name: 'South Korea'},
            {name: 'Ivory Coast'},
            {name: 'Costa Rica'},
            {name: 'Croatia'},
            {name: 'Cuba'},
            {name: 'Curaçao'},
            {name: 'Denmark'},
            {name: 'Dominica'},
            {name: 'Ecuador'},
            {name: 'Egypt'},
            {name: 'El Salvador'},
            {name: 'United Arab Emirates'},
            {name: 'Eritrea'},
            {name: 'Slovakia'},
            {name: 'Slovenia'},
            {name: 'Spain'},
            {name: 'United States of America'},
            {name: 'Estonia'},
            {name: 'Ethiopia'},
            {name: 'Philippines'},
            {name: 'Finland'},
            {name: 'Fiji'},
            {name: 'France'},
            {name: 'Gabon'},
            {name: 'Gambia'},
            {name: 'Georgia'},
            {name: 'Ghana'},
            {name: 'Gibraltar'},
            {name: 'Grenada'},
            {name: 'Greece'},
            {name: 'Greenland'},
            {name: 'Guadeloupe'},
            {name: 'Guam'},
            {name: 'Guatemala'},
            {name: 'French Guiana'},
            {name: 'Guernsey'},
            {name: 'Guinea'},
            {name: 'Equatorial Guinea'},
            {name: 'Guinea-Bissau'},
            {name: 'Guyana'},
            {name: 'Haiti'},
            {name: 'Honduras'},
            {name: 'Hong Kong'},
            {name: 'Hungary'},
            {name: 'India'},
            {name: 'Indonesia'},
            {name: 'Iran'},
            {name: 'Iraq'},
            {name: 'Ireland'},
            {name: 'Bouvet Island'},
            {name: 'Isle of Man'},
            {name: 'Christmas Island'},
            {name: 'Norfolk Island'},
            {name: 'Iceland'},
            {name: 'Bermuda Islands'},
            {name: 'Cayman Islands'},
            {name: 'Cocos (Keeling) Islands'},
            {name: 'Cook Islands'},
            {name: 'Åland Islands'},
            {name: 'Faroe Islands'},
            {name: 'South Georgia and the South Sandwich Islands'},
            {name: 'Heard Island and McDonald Islands'},
            {name: 'Maldives'},
            {name: 'Falkland Islands (Malvinas)'},
            {name: 'Northern Mariana Islands'},
            {name: 'Marshall Islands'},
            {name: 'Pitcairn Islands'},
            {name: 'Solomon Islands'},
            {name: 'Turks and Caicos Islands'},
            {name: 'United States Minor Outlying Islands'},
            {name: 'Virgin Islands'},
            {name: 'United States Virgin Islands'},
            {name: 'Israel'},
            {name: 'Italy'},
            {name: 'Jamaica'},
            {name: 'Japan'},
            {name: 'Jersey'},
            {name: 'Jordan'},
            {name: 'Kazakhstan'},
            {name: 'Kenya'},
            {name: 'Kyrgyzstan'},
            {name: 'Kiribati'},
            {name: 'Kuwait'},
            {name: 'Lebanon'},
            {name: 'Laos'},
            {name: 'Lesotho'},
            {name: 'Latvia'},
            {name: 'Liberia'},
            {name: 'Libya'},
            {name: 'Liechtenstein'},
            {name: 'Lithuania'},
            {name: 'Luxembourg'},
            {name: 'Mexico'},
            {name: 'Monaco'},
            {name: 'Macao'},
            {name: 'Macedonia'},
            {name: 'Madagascar'},
            {name: 'Malaysia'},
            {name: 'Malawi'},
            {name: 'Mali'},
            {name: 'Malta'},
            {name: 'Morocco'},
            {name: 'Martinique'},
            {name: 'Mauritius'},
            {name: 'Mauritania'},
            {name: 'Mayotte'},
            {name: 'Estados Federados de'},
            {name: 'Moldova'},
            {name: 'Mongolia'},
            {name: 'Montenegro'},
            {name: 'Montserrat'},
            {name: 'Mozambique'},
            {name: 'Namibia'},
            {name: 'Nauru'},
            {name: 'Nepal'},
            {name: 'Nicaragua'},
            {name: 'Niger'},
            {name: 'Nigeria'},
            {name: 'Niue'},
            {name: 'Norway'},
            {name: 'New Caledonia'},
            {name: 'New Zealand'},
            {name: 'Oman'},
            {name: 'Netherlands'},
            {name: 'Pakistan'},
            {name: 'Palau'},
            {name: 'Palestine'},
            {name: 'Panama'},
            {name: 'Papua New Guinea'},
            {name: 'Paraguay'},
            {name: 'Peru'},
            {name: 'French Polynesia'},
            {name: 'Poland'},
            {name: 'Portugal'},
            {name: 'Puerto Rico'},
            {name: 'Qatar'},
            {name: 'United Kingdom'},
            {name: 'Central African Republic'},
            {name: 'Czech Republic'},
            {name: 'Dominican Republic'},
            {name: 'South Sudan'},
            {name: 'Réunion'},
            {name: 'Rwanda'},
            {name: 'Romania'},
            {name: 'Russia'},
            {name: 'Western Sahara'},
            {name: 'Samoa'},
            {name: 'American Samoa'},
            {name: 'Saint Barthélemy'},
            {name: 'Saint Kitts and Nevis'},
            {name: 'San Marino'},
            {name: 'Saint Martin (French part)'},
            {name: 'Saint Pierre and Miquelon'},
            {name: 'Saint Vincent and the Grenadines'},
            {name: 'Ascensión y Tristán de Acuña'},
            {name: 'Saint Lucia'},
            {name: 'Sao Tome and Principe'},
            {name: 'Senegal'},
            {name: 'Serbia'},
            {name: 'Seychelles'},
            {name: 'Sierra Leone'},
            {name: 'Singapore'},
            {name: 'Sint Maarten'},
            {name: 'Syria'},
            {name: 'Somalia'},
            {name: 'Sri Lanka'},
            {name: 'South Africa'},
            {name: 'Sudan'},
            {name: 'Sweden'},
            {name: 'Switzerland'},
            {name: 'Suriname'},
            {name: 'Svalbard and Jan Mayen'},
            {name: 'Swaziland'},
            {name: 'Tajikistan'},
            {name: 'Thailand'},
            {name: 'Taiwan'},
            {name: 'Tanzania'},
            {name: 'British Indian Ocean Territory'},
            {name: 'French Southern Territories'},
            {name: 'East Timor'},
            {name: 'Togo'},
            {name: 'Tokelau'},
            {name: 'Tonga'},
            {name: 'Trinidad and Tobago'},
            {name: 'Tunisia'},
            {name: 'Turkmenistan'},
            {name: 'Turkey'},
            {name: 'Tuvalu'},
            {name: 'Ukraine'},
            {name: 'Uganda'},
            {name: 'Uruguay'},
            {name: 'Uzbekistan'},
            {name: 'Vanuatu'},
            {name: 'Venezuela'},
            {name: 'Vietnam'},
            {name: 'Wallis and Futuna'},
            {name: 'Yemen'},
            {name: 'Djibouti'},
            {name: 'Zambia'},
            {name: 'Zimbabwe'},
        ]
    }
    
    const langLabel = {
        es: {
            defaultOption: 'Seleccione una opción', required: '(*) obligatorio', guest: 'Huésped', subTitle: 'Llenado de información', commonData: 'Información en común', hotel: 'Hotel de estadía', registryEntity: 'Entidad de Registro', otherRegistryEntity: 'Otra entidad de registro', address: 'Domicilio de residencia', country: 'País de Residencia', countryBirth: 'País de Nacimiento', phone: 'Teléfono', check_in: 'Fecha de Check In', check_out: 'Fecha de Check Out', firstName: 'Nombre', lastName: 'Apellidos', birthDate: 'Fecha de Nacimiento', male: 'Masculino',  female: 'Femenino', addGuest: 'AGREGAR HUÉSPED', saveInfo: 'GUARDAR INFORMACIÓN', removeGuest: 'Eliminar huésped'
        },
        en: {
            defaultOption: 'Select an option', required: '(*) required', guest: 'Guest', subTitle: 'Data filling', commonData: 'Common data', hotel: 'Staying hotel', registryEntity: 'Registry Entity', otherRegistryEntity: 'Other registry entity', address: 'Residence address', country: 'Residence Country', countryBirth: 'Birth Country', phone: 'Phone', check_in: 'Check In Date', check_out: 'Check Out Date', firstName: 'Name', lastName: 'Lastname', birthDate: 'Birth Date', male: 'Male',  female: 'Female', addGuest: 'ADD GUEST', saveInfo: 'SAVE DATA', removeGuest: 'Remove guest'
        }
    }

    const setLangLabel = (id) => {
        let choosenLang = props.selectedLang ? 'es' : 'en';
        return langLabel[choosenLang][id];
    }
    
    const getLangCountry = () => {
        let choosenLang = props.selectedLang ? 'es' : 'en';
        return langCountry[choosenLang];
    } 

    const getCheckInOutDateLimits = () => {
        var startDate = moment();
        startDate = startDate.subtract(1, "days");
        startDate = startDate.format("YYYY-MM-DD");
        return startDate;
    }

    const getBirthDateDateLimits = () => {
        var startDate = moment();
        startDate = startDate.subtract(100, "years");
        startDate = startDate.format("YYYY-MM-DD");

        var endDate = moment().format('YYYY-MM-DD');

        return [startDate, endDate];
    }

    const getMaxDateCheckOut = () => {
        let max_date = moment().endOf('year').add(15, 'days');
        return max_date;
    }

    return(
        <Card
            columns="s12 m10 l8 push-m1 push-l2"
            color="white"
            white={false}
            style={{marginTop: 'calc(50vh - 302px)', borderRadius: '0px 0px 10px 10px', borderTop: '5px solid rgb(60 152 204)', boxShadow: '0px 0px 32px rgba(0,0,0,.8)'}}
        >
            <div className="row" style={{height: '100%'}}>
                <div className="col s12" style={{height: '100%'}}>
                    <p className="subTitle">{setLangLabel('subTitle')}</p>
                    <Form
                        handleSubmit={props.handleSubmit}
                        column="s12"
                    >
                        <div className="col s12 m6">
                            <p className="subTitle" style={{marginBottom: '15px'}}>{setLangLabel('commonData')}</p>

                            <React.Fragment>
                                <Select
                                    state={props.state}
                                    columns="s12 deep-blue"
                                    id="hotel"
                                    handleChange={props.handleChange}
                                    label={setLangLabel('hotel')}
                                    requiredMessage={setLangLabel('required')}
                                >
                                    <option value="" disabled>- - - -</option>
                                    <option value="Royalton White Sands">Royalton White Sands</option>
                                    <option value="Royalton Blue Waters">Royalton Blue Waters</option>
                                    <option value="Royalton Negril">Royalton Negril</option>
                                    <option value="Hideway Royalton Negril">Hideway Royalton Negril</option>
                                    <option value="Grand Lido Negril">Grand Lido Negril</option>
                                    <option value="Hideway Royalton Riviera Cancun">Hideway Royalton Riviera Cancun</option>
                                    <option value="Royalton Riviera Cancun">Royalton Riviera Cancun</option>
                                    <option value="Royalton Chic Suits Cancun">Royalton Chic Suits Cancun</option>
                                    <option value="Mistique Holbox">Mistique Holbox</option>
                                    <option value="PH Beach Resort Cancun">PH Beach Resort Cancun</option>
                                    <option value="PH Adult Scene Cancun">PH Adult Scene Cancun</option>
                                    <option value="Royalton Punta Cana">Royalton Punta Cana</option>
                                    <option value="Hideway Royalton Punta Cana">Hideway Royalton Punta Cana</option>
                                    <option value="Royalton Bavaro">Royalton Bavaro</option>
                                    <option value="Royalton Chic Punta Cana">Royalton Chic Punta Cana</option>
                                    <option value="Royalton Splash Punta Cana">Royalton Splash Punta Cana</option>
                                    <option value="Hideway Royalton Saint Lucia">Hideway Royalton Saint Lucia</option>
                                    <option value="Royalton Saint Lucia">Royalton Saint Lucia</option>
                                    <option value="Mistique St. Lucia">Mistique St. Lucia</option>
                                    <option value="Starfish St. Lucia">Starfish St. Lucia</option>
                                    <option value="Royalton Antigua">Royalton Antigua</option>
                                    <option value="Starfish Jolly Beach Resort">Starfish Jolly Beach Resort</option>
                                    <option value="Starfish Halcyon Cove">Starfish Halcyon Cove</option>
                                    <option value="PH Beach Resort Costa Rica">PH Beach Resort Costa Rica</option>
                                    <option value="Royalton Grenada">Royalton Grenada</option>
                                </Select>
                                
                                <Select
                                    state={props.state}
                                    columns="s12 deep-blue"
                                    id="registryEntity"
                                    handleChange={props.handleChange}
                                    label={setLangLabel('registryEntity')}
                                    requiredMessage={setLangLabel('required')}
                                    value={props.state.registryEntity}
                                >
                                    {props.state.registryEntities.map((item, key) => {
                                        if (key === 0) {
                                            return <option key={key} value="" disabled>- - - -</option>
                                        } else {
                                            return <option key={key} value={item}>{item}</option>
                                        }
                                    })}
                                </Select>
                                {props.state.showOtherRegistryEntity === true ? 
                                
                                    <Input
                                        state={props.state}
                                        column="s12"
                                        id="otherRegistryEntity"
                                        handleChange={props.handleChange}
                                        type="text"
                                        label={setLangLabel('otherRegistryEntity')}
                                        requiredMessage={setLangLabel('required')}
                                    />

                                :
                                    ''
                                }
                                <Input
                                    state={props.state}
                                    column="s12"
                                    id="address"
                                    handleChange={props.handleChange}
                                    type="text"
                                    label={setLangLabel('address')}
                                    requiredMessage={setLangLabel('required')}
                                />

                                <Input
                                    state={props.state}
                                    column="s12"
                                    id="country"
                                    onInput={props.handleChange}
                                    type="text"
                                    label={setLangLabel('country')}
                                    requiredMessage={setLangLabel('required')}
                                    list="residenceCountryList"
                                /> 
                                <datalist id="residenceCountryList">
                                    {getLangCountry().map((item, key) => {
                                        return <option key={key} value={item.name}>{item.name}</option>
                                    })}
                                </datalist>
                                <Input
                                    state={props.state}
                                    column="s12"
                                    id="countryBirth"
                                    handleChange={props.handleChange}
                                    type="text"
                                    label={setLangLabel('countryBirth')}
                                    requiredMessage={setLangLabel('required')}
                                    list="birthCountryList"
                                />
                                <datalist id="birthCountryList">
                                    {getLangCountry().map((item, key) => {
                                        return <option key={key} value={item.name}>{item.name}</option>
                                    })}
                                </datalist>
                                <Input
                                    state={props.state}
                                    column="s12"
                                    id="phone"
                                    handleChange={props.handleChange}
                                    type="tel"
                                    label={setLangLabel('phone')}
                                />

                                <div className={`input-field col s12`}>
                                    <label htmlFor="check_in">
                                        {setLangLabel('check_in')}
                                        <small style={{color:"#ff5722"}}>&nbsp;{setLangLabel('required')}</small>
                                    </label>
                                    <input type="date" id="check_in" name="check_in" min={getCheckInOutDateLimits()} placeholder={moment().format('DD/MM/YYYY')} max={moment().endOf('year').format('YYYY-MM-DD')} className="deep-blue" onChange={props.handleChange}/>
                                </div>

                                <div className={`input-field col s12`}>
                                    <label htmlFor="check_out">
                                        {setLangLabel('check_out')}
                                        <small style={{color:"#ff5722"}}>&nbsp;{setLangLabel('required')}</small>
                                    </label>
                                    <input type="date" id="check_out" name="check_out" min={getCheckInOutDateLimits()} placeholder={moment().format('DD/MM/YYYY')} max={getMaxDateCheckOut().format('YYYY-MM-DD')} className="deep-blue" onChange={props.handleChange}/>
                                </div>

                                {/* 
                                <Datepicker
                                    column="s12"
                                    id="check_in"
                                    label={setLangLabel('check_in')}
                                    onFocus={props.onFocus}
                                    requiredMessage={setLangLabel('required')}
                                />

                                <Datepicker
                                    column="s12"
                                    id="check_out"
                                    label={setLangLabel('check_out')}
                                    onFocus={props.onFocus}
                                    requiredMessage={setLangLabel('required')}
                                /> */}
                                
                            </React.Fragment>

                        </div>
                        <div className="col s12 m6" style={{height: '100%'}}>
                            <p className="subTitle" style={{marginBottom: '15px'}}>{setLangLabel('guest')}</p>
                            <Scrollbars style={{height: '335px', width: '100%'}}
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
                            >
                            {props.state.guestsData.map((guest, key) => {
                                return(
                                    <React.Fragment key={key}>
                                        <React.Fragment>
                                            <Input
                                                state={props.state}
                                                column="s12"
                                                id={guest.first_name.id}
                                                handleChange={props.handleChange}
                                                type="text"
                                                label={setLangLabel('firstName')}
                                                requiredMessage={setLangLabel('required')}
                                            />
                                            <Input
                                                state={props.state}
                                                column="s12"
                                                id={guest.last_name.id}
                                                handleChange={props.handleChange}
                                                type="text"
                                                label={setLangLabel('lastName')}
                                                requiredMessage={setLangLabel('required')}
                                            />
                                            {/* <Datepicker
                                                column="s12"
                                                id={guest.birth.id}
                                                label={setLangLabel('birthDate')}
                                                onFocus={props.onFocus}
                                                requiredMessage={setLangLabel('required')}
                                            /> */}


                                            <div className={`input-field col s12`}>
                                                <label htmlFor={guest.birth.id}>
                                                    {setLangLabel('birthDate')}
                                                    <small style={{color:"#ff5722"}}>&nbsp;{setLangLabel('required')}</small>
                                                </label>
                                                <input type="date" id={guest.birth.id} name={guest.birth.id} placeholder={moment().subtract(18, "years").format('DD/MM/YYYY')} min={getBirthDateDateLimits()[0]} max={getBirthDateDateLimits()[1]} className="deep-blue" onChange={props.handleChange}/>
                                            </div>
                                            <div className="col s6" align="center" style={{margin: '30px 0'}}>
                                                <Switch
                                                    off={setLangLabel('male')}
                                                    on={setLangLabel('female')}
                                                    id={guest.genre.id}
                                                    toggleSwitch={props.handleToggleSwitch}
                                                />
                                            </div>
                                            <div className="col s6" align="center" style={{margin: '30px 0'}}>
                                                {key===0? '':
                                                <button className="btn deep-blue" style={{width: '100%'}} type="button" onClick={props.handleRemoveGuest.bind(this, key)}>{setLangLabel('removeGuest')}</button>
                                                }
                                            </div>

                                            <div className="col s12"><hr /></div>
                                        </React.Fragment>

                                    </React.Fragment>
                                )
                            })}
                            </Scrollbars>
                            <div className="col s12" style={{margin: '15px 0'}}>
                                <div className="col s12">
                                    <button className="btn deep-orange" style={{width: '100%'}} type="button" onClick={props.handleAddGuest}>{setLangLabel('addGuest')}</button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                                <div className="col s12 m6 push-m3">
                                    <button className="btn blue-sky-bg" style={{width: '100%'}} type="submit" >{setLangLabel('saveInfo')}</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Card>
    )
}

export default Complementation