import React from 'react'
import { BrowserRouter as Router, Switch as RouterSwitch, Route } from 'react-router-dom'
import { TransitionGroup, Transition } from 'react-transition-group'
import { play, exit } from './Functions/timelines'
import 'materialize-css/dist/css/materialize.min.css'
import 'materialize-css/dist/js/materialize.min.js'
import 'izitoast/dist/css/iziToast.min.css'
import './css/styles.scss'
import './css/material.css'
import Navbar from './Components/Navbar/Navbar'
import Sidebar from './Components/Navbar/Sidebar'
import Login from './Components/Login/Login'
import Home from './Components/Home/Home'
import Authenticate from './Components/Authenticate/Authenticate'
import Bulkload from './Components/Bulkload'
import Projects from './Components/Projects/Projects'
import Tasks from './Components/Tasks/Tasks'
import Foliator from './Components/Foliator/Foliator'
import ShowConfigurations from './Components/Configuration/ShowConfigurations'
import Reports from './Components/Reports/Reports'
import Externals from './Components/Externals/Externals'
import { L10n } from '@syncfusion/ej2-base';

class App extends React.Component{    

    
  componentWillMount() {
    L10n.load({
      "es-MX": {
        "uploader": {
          "Browse": "Examinar",
          "Clear": "Limpiar",
          "Upload": "Cargar",
          "cancel": "Cancelar",
          "delete": "Eliminar",
          "dropFilesHint": "Arrastrar archivos aquí",
          "inProgress": "Procesando",
          "invalidFileType": "El tipo de archivo no está permitido",
          "invalidMaxFileSize": "El tamaño del archivo supera los 28 MB",
          "invalidMinFileSize": "¡El tamaño del archivo es demasiado pequeño! Sube archivos con un tamaño mínimo de 10 KB",
          "readyToUploadMessage": "Listo para cargar",
          "remove": "Remover",
          "removedFailedMessage": "No se pudo borrar el archivo",
          "removedSuccessMessage": "Archivo eliminado correctamente",
          "uploadFailedMessage": "No se puede cargar el archivo",
          "uploadSuccessMessage": "El archivo se ha subido correctamente",
        },
        'grid': {
          'EmptyDataSourceError': 'La fuente de datos no debe estar vacío en la primera carga, ya que las columnas del dataSource en la cuadrícula de columnas AutoGenerate',
          'EmptyRecord': 'No hay registros para mostrar',
          'GroupDropArea': 'Arrastre un encabezado de columna aquí para agrupar su columna',
          'Item': 'Registro',
          'Items': 'Registros',
          'UnGroup': 'Haga clic aquí para desagrupar',
          'Excelexport': 'Exportar a Excel'
        },
        'pager': {
          'currentPageInfo': '{0} de {1} páginas',
          'firstPageTooltip': 'Ir a la primera página',
          'lastPageTooltip': 'Ir a la última página',
          'nextPageTooltip': 'Ir a la siguiente página',
          'nextPagerTooltip': 'Ir a la siguiente página',
          'previousPageTooltip': 'Ir a la última página',
          'previousPagerTooltip': 'Ir a la página anterior',
          'totalItemsInfo': '({0} Registros)'
        },
        'pivotview': {
            'grandTotal': 'Gran Total',
            'total': 'Total',
            'value': 'Valor',
            'noValue': 'Sin valor',
            'row': 'Registro',
            'column': 'Columna',
            'collapse': 'Contraer',
            'expand': 'Expandir'
        },
        "pivotfieldlist": {
            'fieldList': 'Lista de campos',
            'dropRowPrompt': 'Soltar registro inmediatamente',
            'dropColPrompt': 'Soltar columna inmediatamente',
            'dropValPrompt': 'Soltar valor inmediatamente',
            'dropFilterPrompt': 'Soltar filtro aquí',
            'addPrompt': 'Agregar campo',
            'centerHeader': 'Arrastre las columnas entre las áreas de abajo:',
            'add': 'Agregar',
            'drag': 'Arrastrar',
            'filters': 'Filtros',
            'rows': 'Registros',
            'columns': 'Columnas',
            'values': 'Valores',
            'error': 'Error',
            'dropAction': 'El campo calculado no puede estar en ninguna otra región que no sea el eje de valores.',
            'search': 'Buscar',
            'close': 'Cerrar',
            'cancel': 'Cancelar',
            'delete': 'Eliminar',
            'alert': 'Alerta',
            'warning': 'Advertencia',
            'ok': 'Aceptar',
            'allFields': 'Todos los campos',
            'noMatches': 'Sin coincidencias'
        }
      }

    })
  }

    render(){
        return (
            <Router>
                <Route render={({location}) => {
                    const { pathname, key } = location
                    const externals = pathname.split('/')
                    return (
                        <TransitionGroup component={null}>
                            {(pathname !== '/' && externals[1] !== 'external') &&
                                <Navbar />
                            }
                            <Transition
                                key={key}
                                appear={true}
                                onEnter={(node, appears) => play(pathname, node, appears)}
                                onExit={(node, appears) => exit(node, appears)}
                                timeout={{enter: 750, exit: 0}}
                            >
                                 <div className="row">
                                {(pathname !== '/' && externals[1] !== 'external' && process.env.REACT_APP_REPORTES_GERENCIALES != "1") &&
                                    <div className="col m2 hide-on-small-only">
                                        <Sidebar pathname={pathname} />
                                    </div>
                                }
                                <RouterSwitch location={location}>
                                    <Route path="/external/:type">
                                        <Externals />
                                    </Route>
                                    <React.Fragment>
                                            <div className={`col s12 ` + (pathname !== '/' ? ((pathname === ( process.env.REACT_APP_REPORTES_GERENCIALES == "1" ? '/reports' : '/home') || pathname === '/Tasks') ? `homeHeightColumn ${process.env.REACT_APP_REPORTES_GERENCIALES == "1" ? "m12" : "m10"}`: 'm10') : 'm12')}>
                                                <Route exact path="/">
                                                    <Login />
                                                </Route>
                                                <Route exact path="/home">
                                                    <Home />
                                                </Route>
                                                <Route exact path="/authenticate">
                                                    <Authenticate />
                                                </Route>
                                                <Route exact path="/bulkload">
                                                    <Bulkload />
                                                </Route>
                                                <Route exact path="/projects">
                                                    <Projects />
                                                </Route>
                                                <Route exact path="/tasks">
                                                    <Tasks />
                                                </Route>
                                                <Route exact path="/foliator">
                                                    <Foliator />
                                                </Route>
                                                <Route exact path="/reports">
                                                    <Reports />
                                                </Route> 
                                                <Route exact path="/configuration" >
                                                    <ShowConfigurations />
                                                </Route>
                                            </div>
                                    </React.Fragment>
                                </RouterSwitch>
                                </div>
                            </Transition>
                        </TransitionGroup>
                    )}}
                />
            </Router>
        )
    }
}

export default App
