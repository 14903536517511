import React from 'react'
import Preloader from '../../Loader/Preloader'
import UserCard from './UserCard'
import Success from '../../IziToast/Success'
import Error from '../../IziToast/Error'
import M from 'materialize-css/dist/js/materialize.min.js'
import {config, configUpdate, users, userUpdate, user} from '../../../Functions/users'
import {companiesNames} from '../../../Functions/companies'
import {departmentsNames} from '../../../Functions/departments'
import {request} from '../../../services/request'
import R from '../../../services/routes'
import UserInfo from './UserInfo'
import Toast from '../../IziToast/Toast'
const authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV

export default class ShowUsers extends React.Component {

    constructor(){
        super()
        this.state = {
            user: null,
            usersData : null,
            usersDataUrl: null,
            company: '',
            companies: null,
            companiesNames: null,
            department: '',
            departments: null,
            departmentsNames: null,
            options: null,
            showPreloader: true,
            showUsers: true,
            showUpdateUserPreloader: false,
            showAddUserPreloader: false,
            search: '',
            passwordType: 'password',
            requiredPassword: true,
            sites: null,
            modules: null,
            showDescription: false,
        }
    }

    async componentDidMount(){
        const cNames = await companiesNames()
        await this.setState({companiesNames: cNames})
        const dNames = await departmentsNames()
        await this.setState({departmentsNames: dNames})
        const res = await users(authenticate + '/api/user/allUsers/null/null/null')
        await this.setState({ usersData: res.users, showPreloader: false, usersDataUrl: res.url })
        await this.getCompanies()
        await this.getDepartments()
        await this.getAllModules()
        M.AutoInit()
    }

    getCompanies = async () => {
        const res = await request('GET', R.getCompaniesNames, true, true, false)
        if(res.success)
            await this.setState({companies: res.companies})
    }

    getDepartments = async () => {
        const res = await request('GET', R.getDepartmentsNames, true, true, false)
        if(res.success)
            await this.setState({departments: res.departments})
    }

    getAllModules = async () => {
        const res = await request('GET', R.getModulesOrder, true, true, false)
        if(res.success){
            const modulesSites = this.resetModules(res.modules)
            await this.setState({modules: modulesSites})
        }
    }

    resetModules = modules => {
        return modules.map(mSite => {
            mSite['s'].checked = false
            let mSiteData = mSite['m'].map(module => {
                module['checked'] = false
                return module
            })
            mSite['m'] = mSiteData
            return mSite
        })
    }

    async handlePaginationClick(url){
        await this.setState({showPreloader: true})
        const res = await users(url)
        await this.setState({ usersData: res.users, showPreloader: false, usersDataUrl: res.url })
    }

    handleGetUser = async id => {
        const res = await request('GET', `${R.getUser}/${id}`, true, true, false)
        if(res.success){
            const modulesState = this.state.modules
            if(res.access.length > 0){
                modulesState.map(mSites => {
                    let mSitesModules = mSites['m'].map(module => {
                        let filtered = res.access.filter(access => access.module_id === module.id)
                        if(filtered.length > 0){
                            module.checked = true
                        }
                        return module
                    })
                    mSites['m'] = mSitesModules
                    return mSitesModules
                })
            }
            const {name, email} = res.user
            const {id_company, department} = JSON.parse(res.user.options)
            console.log(id_company)
            await this.setState({
                user: res.user,
                modules: modulesState,
                editUser: true,
                showUsers: false,
                name: name,
                email: email,
                company: id_company,
                department: department,
                requiredPassword: false,
            })
            const selects = document.querySelectorAll('select')
            M.FormSelect.init(selects, {})
            M.updateTextFields()
        }
    }

    handleGetConfig = async id => {
        const data = await config(id)
        await this.setState({options: data})
        const element = document.getElementById('modalEditConfig')
        const modal = M.Modal.init(element, {preventScrolling: false, dismissible: false, startingTop: '0%', opacity: 0})
        modal.open()
    }

    handleCloseModal = id => {
        const element = document.getElementById(id)
        const modal =  M.Modal.getInstance(element)
        modal.close()
        this.setState({user: null, options: null})
    }

    handleDeleteUser = id => {

    }

    handleUpdateUser = async e => {
        e.preventDefault()
        e.stopPropagation()

        if(this.state.department !== ''){
            await this.setState({showPreloader: true})
            const sitesIndex = []
            const modules = []
            this.state.modules.map(mSite => {
                mSite['m'].map(module => {
                    if(module.checked){
                        modules.push({site_id: module.sId, module_id: module.id})
                        sitesIndex.push(mSite['s'].name)
                    }
                })
            })
            const sites = sitesIndex.filter(this.distinct)
            
            let data = {}
            if(this.state.password !== ''){
                data = {
                    id: this.state.user.id,
                    name: this.state.name,
                    email: this.state.email,
                    password: this.state.password,
                    modules: modules,
                    options: {
                        access: sites,
                        department: this.state.department,
                        id_company: this.state.company,
                    },
                }
            } else {
                data = {
                    id: this.state.user.id,
                    name: this.state.name,
                    email: this.state.email,
                    modules: modules,
                    options: {
                        access: sites,
                        department: this.state.department,
                        id_company: this.state.company,
                    },
                }
            }
            
            const res = await request('PUT', R.updateUser, true, true, JSON.stringify(data))
            if(res.success){
                Toast.showSuccess({
                    title: 'Usuario actualizado',
                    message: 'El usuario fue actualizado satisfactoriamente',
                    position: 'topCenter',
                })
                const modules = this.resetModules(this.state.modules)
                this.setState({
                    showPreloader: false,
                    showUsers: true,
                    editUser: false,
                    user: null,
                    modules: modules,
                    name: '',
                    email: '',
                    password: '',
                    department: '',
                    company: '',
                    requiredPassword: true,
                })
            }
        } else {
            Toast.showWarning({
                title: 'Atención',
                message: 'No se ha seleccionado el departamento',
                position: 'topCenter'
            })
        }
    }

    handleUpdateConfig = async options => {
        await this.setState({showUpdateUserPreloader: true})
        const data = await configUpdate(options)
        if(data.success){
            Success('Guardado', 'Se guardaron correctamente los datos de usuario', 'bottomCenter')
        } else {
            Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
        }
        const element = document.getElementById('modalEditConfig')
        const modal = M.Modal.init(element)
        modal.close()
        await this.setState({showUpdateUserPreloader: false, options: null})
        users(this.state.usersDataUrl)
    }

    handleChange = async  e =>{
        const value = e.target.value
        const id = e.target.id
        if(id === 'search'){
            await this.setState({search : value})  
            if(value !== ''){
                const res = await users(authenticate + '/api/user/search/' + value)
                this.setState({ usersData: res.users, showPreloader: false, usersDataUrl: res.url })
            } 
        } else {
            this.setState({
                ...this.state,
                [id]: value,
            })
        }  
    }

    handlePassword = () => {
        const passwordType = this.state.passwordType === 'password' ? 'text' : 'password' 
        this.setState({showPassword: !this.state.showPassword, passwordType: passwordType})
    }

    handleNewUser = async () => {
        await this.setState({showUsers: false, newUser: true, requiredPassword: true,})
        M.updateTextFields()
        const selects = document.querySelectorAll('select')
        M.FormSelect.init(selects, {})
    }

    handleStoreUser = async e => {
        e.preventDefault()
        e.stopPropagation()

        if(this.state.department !== ''){
            await this.setState({showPreloader: true})
            const sitesIndex = []
            const modules = []
            this.state.modules.map(mSite => {
                mSite['m'].map(module => {
                    if(module.checked){
                        modules.push({site_id: module.sId, module_id: module.id})
                        sitesIndex.push(mSite['s'].name)
                    }
                })
            })
            const sites = sitesIndex.filter(this.distinct)
            const data = {
                name: this.state.name,
                email: this.state.email,
                password: this.state.password,
                modules: modules,
                options: {
                    access: sites,
                    department: this.state.department,
                    id_company: this.state.company,
                },
            }
            const res = await request('POST', R.storeUser, true, true, JSON.stringify(data))
            if(res.success){
                Toast.showSuccess({
                    title: 'Usuario creado',
                    message: 'El usuario fue creado satisfactoriamente',
                    position: 'topCenter',
                })
                const modules = this.resetModules(this.state.modules)
                this.setState({showUsers: true, newUser: false, modules: modules, showPreloader: false})
            }
        } else {
            Toast.showWarning({
                title: 'Atención',
                message: 'No se ha seleccionado el departamento',
                position: 'topCenter'
            })
        }
    }

    toggleCheckbox = e => {
        const { id } = e.target
        const modulesSites = this.state.modules
        const mS = modulesSites.map(mSite => {
            let mSiteData = mSite['m'].map(module => {
                if(module.id == id){
                    mSite['s'].checked = false
                    module['checked'] = !module['checked']
                }
                return module
            })
            mSite['m'] = mSiteData
            return mSite
        })
        this.setState({modules: mS})
    }

    toggleSelectAll = e => {
        const { id } = e.target
        const modulesSites = this.state.modules
        const mS = modulesSites.map(mSite => {
            if(mSite['s'].id == id){
                mSite['s'].checked = !mSite['s'].checked
                let mSiteData = mSite['m'].map(module => {
                    module['checked'] = mSite['s'].checked
                    return module
                })
                mSite['m'] = mSiteData
            }
            return mSite
        })
        this.setState({modules: mS})
    }

    toggleSwitch = () => {
        this.setState({showDescription: !this.state.showDescription})
    }

    distinct = (value, index, self) => {
        return self.indexOf(value) === index
    }

    render(){
        return(
            <React.Fragment>
                {this.state.showPreloader &&
                    <Preloader color="blue" position="relative" />
                }
                {this.state.showUsers &&
                <div className="row">
                    <div className="col s12">
                        {this.state.usersData &&
                            <div className="row valign-wrapper">
                                <div className="col s12 m6">
                                    <div className="input-field">
                                        <input id="search" type="text" className="validate deep-blue" value={this.state.search} onChange={this.handleChange} />
                                        <label htmlFor="search">Buscar</label>
                                    </div>
                                </div>
                                <div className="col s12 m6">
                                    <ul className="pagination" style={{float: 'right', margin: '3px'}}>
                                        <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClick(this.state.usersData.first_page_url)}}><i className="material-icons">first_page</i></a></li>
                                        {this.state.usersData.prev_page_url &&
                                            <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClick(this.state.usersData.prev_page_url)}}>{this.state.usersData.current_page - 1}</a></li>
                                        }
                                        <li className="waves-effect active"><a href="#" className="white-text">{this.state.usersData.current_page}</a></li>
                                        {this.state.usersData.next_page_url &&
                                            <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClick(this.state.usersData.next_page_url)}}>{this.state.usersData.current_page + 1}</a></li>
                                        }
                                        <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClick(this.state.usersData.last_page_url)}}><i className="material-icons">last_page</i></a></li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col s12">
                        <div>
                            {this.state.usersData &&
                                <div className="row card-list">
                                    {this.state.usersData.data.map(user => {
                                            return (
                                                <UserCard 
                                                    key={user.id} 
                                                    user={user} 
                                                    callbackEdit={this.handleGetUser.bind(this)}
                                                    callbackConfig={this.handleGetConfig.bind(this)}
                                                    callbackDelete={this.handleDeleteUser.bind(this)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <button className="waves-effect waves-light btn btn-bottom yellownee-gradient" onClick={this.handleNewUser} style={{position: 'fixed', bottom: '-30px', left: 0}}><i className="material-icons left">add</i>Nuevo usuario</button>
                </div>
                }
                {this.state.newUser &&
                    <UserInfo
                        state={this.state}
                        handleStoreUser={this.handleStoreUser.bind(this)}
                        handleChange={this.handleChange.bind(this)}
                        handlePassword={this.handlePassword.bind(this)}
                        toggleCheckbox={this.toggleCheckbox.bind(this)}
                        toggleSelectAll={this.toggleSelectAll.bind(this)}
                        toggleSwitch={this.toggleSwitch.bind(this)}
                    />
                }
                {this.state.editUser &&
                    <UserInfo
                        state={this.state}
                        handleStoreUser={this.handleUpdateUser.bind(this)}
                        handleChange={this.handleChange.bind(this)}
                        handlePassword={this.handlePassword.bind(this)}
                        toggleCheckbox={this.toggleCheckbox.bind(this)}
                        toggleSelectAll={this.toggleSelectAll.bind(this)}
                        toggleSwitch={this.toggleSwitch.bind(this)}
                    />
                }
            </React.Fragment>
        )
    }
}