import React from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
import axios from 'axios'
import chipImage from './../../img/person.png'
const iziToast = require('izitoast')
const clients = {
    'Client 1': null,
    'Client 2': null,
    'Client 3': null,
}
const chipStyle = {
    cursor: 'pointer',
    float: 'right',
    fontSize: '16px',
    lineHeight: '32px',
    paddingLeft: '8px'
}

export default class AddProject extends React.Component {

    authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV

    constructor(){
        super()
        this.state = {
            name: '',
            client: '',
            business_name: '',
            rfc: '',
            zip_code: '',
            address: '',
            colony: '',
            municipality: '',
            state: '',
            beginning_of_operations: '',
            billing_start: '',
            billing_frequency: '',
            billing_form: '',
            billing_type: '',
            billing_concept: '',
            billing_observations: '',
            database_user: '',
            database_user_email: '',
            payment: '',
            sales_channels: '',
            assistance_cost_per_person: '',
            penalties: '',
            status: '',
            service_explanation: '',
            showChipClient: false,
        }
    }

    componentDidMount(){
        M.updateTextFields()
        const selects = document.querySelectorAll('select')
        M.FormSelect.init(selects)
        const autocomplete = document.getElementById('client')
        M.Autocomplete.init(autocomplete,
            {
                data: clients,
                onAutocomplete: complete => {
                    this.setState({client: complete, showChipClient: true})
                }
            }
        )
        const pickers = document.querySelectorAll('.datepicker')
        M.Datepicker.init(pickers, {
            format: 'yyyy-mm-dd',
            i18n: {
                done: 'Aceptar',
                cancel: 'Cancelar',
                months:[
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Diciembre'
                ],
                monthsShort: [
                    'Ene',
                    'Feb',
                    'Mar',
                    'Abr',
                    'May',
                    'Jun',
                    'Jul',
                    'Ago',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                ],
                weekdays: [
                    'Domingo',
                    'Lunes',
                    'Martes',
                    'Miércoles',
                    'Jueves',
                    'Viernes',
                    'Sábado'
                ],
                weekdaysShort: [
                    'Dom',
                    'Lun',
                    'Mar',
                    'Mié',
                    'Jue',
                    'Vie',
                    'Sáb'
                ],
                weekdaysAbbrev: ['D','L','M','M','J','V','S']
            },
            onClose: data => {
                this.setState({beginning_of_operations: document.getElementById('beginning_of_operations').value, billing_start: document.getElementById('billing_start').value})
            }
        })
    }

    handleChange = e => {
        const value = e.target.value;
        if(e.target.id === 'zip_code'){
            if(value.length === 5){
                this.getZipCode(value)
                .then(address => {
                    this.setState({colony: address.colony, municipality: address.municipality, state: address.state})
                })
            }
        }
        this.setState({
            ...this.state,
            [e.target.id]: value
        })
    }

    async getZipCode(zip_code){
        const response = await axios.get(this.authenticate + '/api/project/zip_code/' + zip_code, {
            headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
        })
        .then(res => {
            return res.data.zip_code[0]
        })
        return response
    }

    handleCloseChip = () => {
        this.setState({showChipClient: false, client: ''})
    }

    handleSubmit = e => {
        e.preventDefault()
        e.stopPropagation()
        this.storeProject()
        .then(data => {
            if(data.success){
                iziToast.show({
                    iconColor: '#0fd12c',
                    titleColor: '#0fd12c',
                    messageColor: '#0fd12c',
                    progressBarColor: '#0fd12c',
                    icon: 'far fa-check-circle',
                    title: 'Guardado',
                    message: 'Se creó el proyecto correctamente',
                    position: 'center',
                    timeout: 2000,
                })
                this.setState({
                    name: '',
                    client: '',
                    business_name: '',
                    rfc: '',
                    zip_code: '',
                    address: '',
                    colony: '',
                    municipality: '',
                    state: '',
                    beginning_of_operations: '',
                    billing_start: '',
                    billing_frequency: '',
                    billing_form: '',
                    billing_type: '',
                    billing_concept: '',
                    billing_observations: '',
                    database_user: '',
                    database_user_email: '',
                    payment: '',
                    sales_channels: '',
                    assistance_cost_per_person: '',
                    penalties: '',
                    status: '',
                    service_explanation: '',
                    showChipClient: false,
                })
            } else {
                iziToast.show({
                    iconColor: '#e62020',
                    titleColor: '#e62020',
                    messageColor: '#e62020',
                    progressBarColor: '#e62020',
                    icon: 'fas fa-times',
                    backgroundColor: '#fff',
                    title: 'Error',
                    message: 'Ocurrió algún error desconocido',
                    position: 'center',
                    timeout: 2000
                })
            }
        })
    }

    async storeProject(){
        const data = {
            name: this.state.name,
            client: this.state.client,
            business_name: this.state.business_name,
            rfc: this.state.rfc,
            address: this.state.address,
            colony: this.state.colony,
            municipality: this.state.municipality,
            state: this.state.state,
            beginning_of_operations: this.state.beginning_of_operations,
            billing_start: this.state.billing_start,
            billing_frequency: this.state.billing_frequency,
            billing_form: this.state.billing_form,
            billing_type: this.state.billing_type,
            billing_concept: this.state.billing_concept,
            billing_observations: this.state.billing_observations,
            database_user: this.state.database_user,
            database_user_email: this.state.database_user_email,
            payment: this.state.payment,
            sales_channels: this.state.sales_channels,
            assistance_cost_per_person: this.state.assistance_cost_per_person,
            penalties: this.state.penalties,
            status: this.state.status,
            service_explanation: this.state.service_explanation,
            belongs_to: localStorage.getItem('id'),
            options: {status: true},
        }
    
        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
            data: JSON.stringify(data),
            url: this.authenticate + '/api/project/store'
        }
        const response = await axios(options)
        .then(res => {
            return res.data
        })
        .catch(e => {
            console.log(e)
        })
        return response
    }

    render(){
        return(
            <div className="row">
                <form onSubmit={this.handleSubmit} className="col s12">
                    <span className="deep-blue">Datos generales</span>
                    <hr className="form-divider"/>
                    <div className="row" style={{marginBottom: '0'}}>
                        <div className="input-field col s12 m4">
                            <input placeholder="Nombre del proyecto" className="validate deep-blue" value={this.state.name}  onChange={this.handleChange} id="name" type="text" required />
                            <label className="blue-sky" htmlFor="name">Nombre del proyecto</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input placeholder="RFC" id="rfc" className="validate deep-blue" value={this.state.rfc}  onChange={this.handleChange} type="text" />
                            <label className="blue-sky" htmlFor="rfc">RFC</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input placeholder="Inicio de operaciones" id="beginning_of_operations" className="validate deep-blue datepicker" value={this.state.beginning_of_operations}  onChange={this.handleChange} type="text" />
                            <label className="blue-sky" htmlFor="beginning_of_operations">Inicio de operaciones</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input placeholder="Nombre del cliente" id="client" className="validate autocomplete deep-blue" type="text" />
                            <label className="blue-sky" htmlFor="client">Nombre del cliente</label>
                            {this.state.showChipClient !== false &&
                                <div className="chip">
                                    <img src={chipImage} alt="Contact Person" />
                                    {this.state.client}
                                    <i className="material-icons" style={chipStyle} onClick={this.handleCloseChip}>close</i>
                                </div>
                            }
                        </div>
                        <div className="input-field col s12 m4">
                            <input placeholder="Razón social" id="business_name" className="validate deep-blue" value={this.state.business_name}  onChange={this.handleChange} type="text" />
                            <label className="blue-sky" htmlFor="business_name">Razón social</label>
                        </div>
                    </div>
                    <span className="deep-blue">Dirección</span>
                    <hr className="form-divider"/>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <input placeholder="Código postal" id="zip_code" className="validate deep-blue" value={this.state.zip_code}  onChange={this.handleChange} type="text" />
                            <label className="blue-sky" htmlFor="zip_code">Código postal</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input placeholder="Dirección" id="address" className="validate deep-blue" value={this.state.address}  onChange={this.handleChange} type="text" />
                            <label className="blue-sky" htmlFor="address">Dirección</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input placeholder="Colonia" id="colony" className="validate deep-blue" value={this.state.colony}  onChange={this.handleChange} type="text" />
                            <label className="blue-sky" htmlFor="colony">Colonia</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input placeholder="Municipio" id="municipality" className="validate deep-blue" value={this.state.municipality}  onChange={this.handleChange} type="text" />
                            <label className="blue-sky" htmlFor="municipality">Municipio</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input placeholder="Estado" id="state" className="validate deep-blue" value={this.state.state}  onChange={this.handleChange} type="text" />
                            <label className="blue-sky" htmlFor="state">Estado</label>
                        </div>
                    </div>
                    <span className="deep-blue">Facturación</span>
                    <hr className="form-divider"/>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <input placeholder="Inicio de facturación" id="billing_start" className="validate deep-blue datepicker" value={this.state.billing_start}  onChange={this.handleChange} type="text" />
                            <label className="blue-sky" htmlFor="billing_start">Inicio de facturación</label>
                        </div>
                        <div className="input-field deep-blue col s12 m4">
                            <select id="billing_frequency" onChange={this.handleChange} value={this.state.billing_frequency}>
                                <option value="" disabled>Selecciona una opción</option>
                                <option value="0">Mensual</option>
                                <option value="1">Anual</option>
                            </select>
                            <label>Periodo de facturación</label>
                        </div>
                        <div className="input-field deep-blue col s12 m4">
                            <select id="billing_form" onChange={this.handleChange} value={this.state.billing_form}>
                                <option value="" disabled>Selecciona una opción</option>
                                <option value="0">Mes vencido</option>
                                <option value="1">Mes corriente</option>
                            </select>
                            <label>Forma de facturación</label>
                        </div>
                        <div className="input-field deep-blue col s12 m4 push-m4">
                            <select id="billing_type" onChange={this.handleChange} value={this.state.billing_type}>
                                <option value="" disabled>Selecciona una opción</option>
                                <option value="0">Por expuesto</option>
                                <option value="1">Iguala</option>
                            </select>
                            <label>Tipo de facturación</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m6">
                            <textarea placeholder="Concepto de facturación" id="billing_concept" className="materialize-textarea deep-blue" value={this.state.billing_concept}  onChange={this.handleChange}></textarea>
                            <label className="blue-sky" htmlFor="billing_concept">Concepto de facturación</label>
                        </div>
                        <div className="input-field col s12 m6">
                            <textarea placeholder="Observaciones de facturación" id="billing_observations" className="materialize-textarea deep-blue" value={this.state.billing_observations}  onChange={this.handleChange} ></textarea>
                            <label className="blue-sky" htmlFor="billing_observations">Observaciones de facturación</label>
                        </div>
                    </div>
                    <span className="deep-blue">Detalles</span>
                    <hr className="form-divider"/>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <input placeholder="Usuario que envía la base de datos" id="database_user" className="validate deep-blue" value={this.state.database_user}  onChange={this.handleChange} type="text" />
                            <label className="blue-sky" htmlFor="database_user">Usuario que envía la base de datos</label>
                        </div>
                        <div className="input-field deep-blue col s12 m4">
                            <input placeholder="Email" id="database_user_email" className="validate deep-blue" value={this.state.database_user_email}  onChange={this.handleChange} type="email" />
                            <label className="blue-sky" htmlFor="database_user_email">Email</label>
                        </div>
                        <div className="input-field deep-blue col s12 m4">
                            <select id="payment" onChange={this.handleChange} value={this.state.payment}>
                                <option value="" disabled>Selecciona una opción</option>
                                <option value="0">Mensual por  usuario</option>
                                <option value="1">Anualidad por  usuario</option>
                                <option value="2">Iguala</option>
                            </select>
                            <label>Cobro</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input placeholder="Canales de venta" id="sales_channels" className="validate deep-blue" value={this.state.sales_channels}  onChange={this.handleChange} type="text" />
                            <label className="blue-sky" htmlFor="sales_channels">Canales de venta</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input placeholder="Costo de asistencia por persona" id="assistance_cost_per_person" className="validate deep-blue" value={this.state.assistance_cost_per_person}  onChange={this.handleChange} type="number" />
                            <label className="blue-sky" htmlFor="assistance_cost_per_person">Costo de asistencia por persona</label>
                        </div>
                        <div className="input-field col s12 m4">
                            <input placeholder="Penalidades" id="penalties" className="validate deep-blue" value={this.state.penalties}  onChange={this.handleChange} type="text" />
                            <label className="blue-sky" htmlFor="penalties">Penalidades</label>
                        </div>
                        <div className="input-field deep-blue col s12 m4">
                            <select id="status" onChange={this.handleChange} value={this.state.status}>
                                <option value="" disabled>Selecciona una opción</option>
                                <option value="0">Negociación con cliente</option>
                                <option value="1">Firma de contrato/anexo</option>
                                <option value="2">En proceso de implementación</option>
                                <option value="3">Implementado/Operando</option>
                                <option value="4">Implementado/Sin Operar</option>
                                <option value="5">Programado sin iniciar</option>
                                <option value="6">En espera</option>
                            </select>
                            <label>Estatus</label>
                        </div>
                        <div className="input-field col s12 m8">
                            <textarea placeholder="Explicación del servicio" id="service_explanation" className="materialize-textarea deep-blue" value={this.state.service_explanation}  onChange={this.handleChange}></textarea>
                            <label className="blue-sky" htmlFor="service_explanation">Explicación del servicio</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m4 push-m4">
                            <button className="btn waves-effect waves-light blue-gradient" type="submit" style={{width: '100%'}}>Guardar</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

}