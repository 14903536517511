import React from 'react'
import Card from './Card'

const List = props => {
    return props.sites.data.map(site => {
        return(
           <div className="col s12 masonry-element">
                <Card
                    key={props.id}
                    id={site.id}
                    name={site.name}
                    private_ip={site.private_ip}
                    public_ip={site.public_ip}
                    description={site.description}
                    handleEdit={props.handleEdit}
                    handleDelete={props.handleDelete}
                />
           </div>
        )
    })
}

export default List