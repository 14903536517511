import axios from 'axios'

const companiesNames = async function(){
    const response = await axios.get((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/companies/names', 
    {
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const companies = res.data.companies
        let names = []
        for(let i in companies){
            let company = companies[i]
            names[company.id] = company.name
        }
        return names
    })
    .catch(e => {
        console.log(e)
        if(e.response.data.message === 'Server Error'){
            return this.companiesNames()
        }
    })
    return response
}

export {companiesNames}