import React from 'react'
import Checkbox from '../../Checkbox/Checkbox'
import ModulesCard from './ModulesCard'
import Switch from '../../Checkbox/Switch'

const UserInfo = props => {
    return(
        <React.Fragment>
            <br/>
            <div className="col s12 m4 push-m4 flex-center" style={{textAlign: 'center'}}>
                <span className="panelTitle">Agregar nuevo usuario</span>
            </div>
            <br/>
            <form onSubmit={props.handleStoreUser}>
                <div className="input-field col s12">
                    <input placeholder="Nombre" className="deep-blue" value={props.state.name}  onChange={props.handleChange} id="name" type="text" required />
                    <label className="blue-sky" htmlFor="name">Nombre</label>
                </div>
                <div className="input-field col s12">
                    <input placeholder="Correo electrónico" className="deep-blue" value={props.state.email}  onChange={props.handleChange} id="email" type="text" required />
                    <label className="blue-sky" htmlFor="email">Correo electrónico</label>
                </div>
                <div className="input-field col s12">
                    {!props.state.showPassword ?
                        <i className="material-icons showPassword" onClick={props.handlePassword}>visibility</i>
                        :
                        <i className="material-icons showPassword" onClick={props.handlePassword}>visibility_off</i>
                    }
                    {props.state.requiredPassword ? 
                    <input placeholder="Contraseña" className="deep-blue" value={props.state.password} onChange={props.handleChange} id="password" type={props.state.passwordType} required />
                    :
                    <input placeholder="Contraseña" className="deep-blue" value={props.state.password} onChange={props.handleChange} id="password" type={props.state.passwordType} />
                    }
                    <label className="blue-sky" htmlFor="pssw">Contraseña</label>
                </div>
                <div className="input-field col s12 deep-blue">
                    <select id="company" onChange={props.handleChange} value={props.state.company}>
                        <option value="" disabled>Selecciona una opción</option>
                        {props.state.companies &&
                            props.state.companies.map(company => {
                                return(
                                    <option key={company.id} value={company.id}>{company.name}</option>
                                )
                            })
                        }
                    </select>
                    <label>Compañía</label>
                </div>
                <div className="input-field col s12 deep-blue">
                    <select id="department" onChange={props.handleChange} value={props.state.department}>
                        <option value="" disabled>Selecciona una opción</option>
                        {props.state.departments &&
                            props.state.departments.map(department => {
                                return(
                                    <option key={department.id} value={department.id}>{department.name}</option>
                                )
                            })
                        }
                    </select>
                    <label>Departamento</label>
                </div>
                <Switch
                    off="Sin descripción"
                    on="Descripción"
                    toggleSwitch={props.toggleSwitch}
                />
                <div className="col s12 masonry">
                {props.state.modules &&
                    props.state.modules.map(site => {
                        return(
                            <div key={site['s'].id} className="col s12 masonry-element">
                            <ModulesCard 
                                id={site['s'].id}
                                name={site['s'].name}
                                description={site['s'].description}
                                showDescription={props.state.showDescription}
                                checked={site['s'].checked}
                                toggleSelectAll={props.toggleSelectAll}
                                content={
                                    site['m'].map(item => {
                                        return(
                                            <div key={`${site['s'].id}-${item.id}`} className="card-panel dark-bg" style={{padding: '1px 0px 5px 10px'}}>
                                                <Checkbox 
                                                    id={item.id}
                                                    name={item.name}
                                                    color="white-text"
                                                    checked={item.checked}
                                                    toggleCheckbox={props.toggleCheckbox}
                                                />
                                                <span className={`panelTitle ${!props.state.showDescription ? 'hide':''}`}>{item.description}</span>
                                            </div>
                                        )
                                    })
                                } 
                            />
                            </div>
                        )
                    })
                }
                </div>
                <div className="col s12 m8 push-m2">
                    <button className="btn deep-blue-bg waves-effect waves-light" type="submit" style={{width: '100%'}}>
                        <i className="material-icons right">save</i>
                        Guardar
                    </button>
                </div>
            </form>
        </React.Fragment>
    )
}

export default UserInfo
