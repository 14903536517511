import React from 'react'

/**
 * @param {Function} handleSubmit Handler for submitting
 * @param {String} column Number of columns for the form
 * @param {Object} children Information
 * @description Form
*/
const Form = props => {
    return(
        <form onSubmit={props.handleSubmit} className={`col ${props.column}`}>
            {props.children}
        </form>
    )
}

export default Form