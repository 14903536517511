import React from 'react'
import {superFolio} from '../../Functions/folios'
const qS = require('query-string')
let parsed = null
export default class Information extends React.Component {

    constructor(){
        super()
        this.state = {
            folio: null,
            options: null
        }
    }

    componentDidMount(){
        parsed = qS.parse(window.location.search)
        superFolio(parsed.folio, parsed.key)
        .then(data => {
            const options = JSON.parse(data.folio.options)
            this.setState({folio: data.folio, options: options})
            console.log(data)
        })
        .catch(e => {
            console.log(e)
        })
    }

    render(){
        return(
            <React.Fragment>
                {this.state.folio &&
                    <div className="row">
                        <div className="col s12 m8 push-m2">
                            <div className="card-panel" style={{marginLeft: '10px', borderRadius: '3px'}}>
                                <div className="ribbon">
                                    <span className="ribbon-title">Información recolectada</span>
                                </div>
                                <div className="row" style={{marginTop: '15px'}}>
                                    <div className="col s6 task-description-name" style={{textAlign: 'right'}}>
                                        Correo electrónico
                                    </div>
                                    <div className="col s6 task-description-content">
                                        {this.state.options.email}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s6 task-description-name" style={{textAlign: 'right'}}>
                                        Folio del producto
                                    </div>
                                    <div className="col s6 task-description-content">
                                        {this.state.folio.folio}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s6 task-description-name" style={{textAlign: 'right'}}>
                                        Comprado por
                                    </div>
                                    <div className="col s6 task-description-content">
                                        {`${this.state.folio.beneficiary_name} ${this.state.folio.beneficiary_last_name} ${this.state.folio.beneficiary_m_last_name}`}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s6 task-description-name" style={{textAlign: 'right'}}>
                                        Beneficiario
                                    </div>
                                    <div className="col s6 task-description-content">
                                    {`${this.state.folio.affiliate_name} ${this.state.folio.affiliate_last_name} ${this.state.folio.affiliate_m_last_name}`}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s6 task-description-name" style={{textAlign: 'right'}}>
                                        Lugar de compra
                                    </div>
                                    <div className="col s6 task-description-content">
                                        {this.state.folio.store}
                                    </div>
                                </div>
                                <div className="row">
                                    <img src={this.state.options.image} style={{width: '80%', marginLeft: '10%'}} alt="ticket"/>
                                    <div className="col s12 task-description-name" style={{textAlign: 'center'}}>
                                        Ticket de compra
                                    </div>
                                </div>
                                <div className="row">
                                    <img src={this.state.options.signature} style={{width: '80%', marginLeft: '10%'}} alt="signature"/>
                                    <div className="col s12 task-description-name" style={{textAlign: 'center'}}>
                                        Firma digital
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}