import React from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'

export default class EmailSendModal extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            email: ''
        }
    }

    componentDidMount(){
        M.updateTextFields()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.setState({
                ...this.state
            })
        }
    }

    handleChange = e => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            [e.target.id]: value
        })
    }

    handleSubmit = e => {
        e.preventDefault()
        e.stopPropagation()
        this.props.callbackSubmit({email: this.state.email})
    }

    handleClose = () => {
        this.props.callbackClose('modalSendEmail')
    }

    render(){
        return(
            <div id="modalSendEmail" className="modal modal-large deep-blue-bg">
                <i className="material-icons close-modal-btn" onClick={this.handleClose}>close</i>
                <div className="modal-content">
                    <div className="row">
                        <form onSubmit={this.handleSubmit} className="col s12">
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <input placeholder="Correo electrónico" className="white-text" value={this.state.email}  onChange={this.handleChange} id="email" type="text" required />
                                    <label className="blue-sky" htmlFor="email">Correo electrónico</label>
                                </div>
                            </div>
                            <div>
                                <button className="btn waves-effect waves-light blue-gradient" type="submit" style={{width: '100%'}}>Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}