import React from 'react'
import ShowUsers from './Users/ShowUsers'
import ShowDepartments from './ShowDepartments'
import ShowSites from './Sites/ShowSites'
import ShowModules from './Modules/ShowModules'
import {verify, refresh} from '../../Functions/verify'
import UrlCard from '../Cards/UrlCard'

const cards = [
    {
        title: 'Usuarios',
        text: 'Ver todo el listado de usuarios, editar, eliminar y asignar permisos.',
        button: 'Ver listado',
        section: 'users'
    },
    {
        title: 'Departamentos',
        text: 'Ver todo el listado de departamentos, editar y deshabilitar.',
        button: 'Ver listado',
        section: 'departments'
    },
    {
        title: 'Sitios',
        text: 'Ver todo el listado de sitios, editar y deshabilitar.',
        button: 'Ver listado',
        section: 'sites'
    },
    {
        title: 'Modulos',
        text: 'Ver todo el listado de modulos, editar y deshabilitar.',
        button: 'Ver listado',
        section: 'modules'
    },
]

export default class Authenticate extends React.Component {

    constructor(){
        super()
        this.state = {
            show: 'cards'
        }
    }

    componentDidMount(){
        
    }

    getSection = section => {
        this.setState({show: section})
    }

    handleClickBack = () => {
        this.setState({show: 'cards'})
    }

    render(){
        return(
            <div className="content">
                <div className="row">
                    {this.state.show === 'cards' &&
                        cards.map(card => {
                            return(
                                <UrlCard 
                                    key={card.title}
                                    title={card.title} 
                                    text={card.text}  
                                    button={card.button} 
                                    section={card.section}
                                    callback={this.getSection.bind(this)}
                                />
                            )
                        })
                    }
                </div>
                {this.state.show === 'users' &&
                    <React.Fragment>
                         <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                            <i className="material-icons left">arrow_back</i>Regresar
                        </button>
                        <ShowUsers />
                    </React.Fragment>
                }
                {this.state.show === 'departments' &&
                    <React.Fragment>
                         <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                            <i className="material-icons left">arrow_back</i>Regresar
                        </button>
                        <ShowDepartments />
                    </React.Fragment>
                }
                {this.state.show === 'sites' &&
                    <React.Fragment>
                         <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                            <i className="material-icons left">arrow_back</i>Regresar
                        </button>
                        <ShowSites />
                    </React.Fragment>
                }
                {this.state.show === 'modules' &&
                    <React.Fragment>
                         <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                            <i className="material-icons left">arrow_back</i>Regresar
                        </button>
                        <ShowModules />
                    </React.Fragment>
                }
            </div>
        )
    }
}