import React from 'react'

export default function PanelCards(props){
    return(
        <div className="col s12 m12">
            <div className={`card-panel white-text ${props.color}`} style={{padding: '5px 15px'}}>
                <div className="row">
                        <span style={{fontSize: '13px'}}>{props.title}</span>
                        <br/>
                        <span style={{fontSize: '14px'}}>{props.content}</span>
                </div>
            </div>
        </div>
    )
}