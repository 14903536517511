const axios = require('axios')

const verify = async function(){
    const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV
    if(localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null){
        const response = await axios.get(url + '/api/authenticate',{
            headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
        })
        .then(res => {
            const data = res.data
            if(data.success){
                console.log(data.message)
                return true
            } else {
                return false
            }
        })
        .catch(err => {
            console.log(err)
        })
        return response
    } else {
        return false
    }
}

const refresh = async function(){
    const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV
    const response = await axios.get(url + '/api/refresh',{
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const data = res.data
        if(data.token){
            localStorage.setItem('token', data.token)
            localStorage.setItem('token_type', data.token_type)
        }
        return 'Token refreshed'
    })
    .catch(err => {
        return err
    })
    return response
}

export {verify, refresh}