import React from 'react'
import logoMAS from '../../../img/logo.png'
// import logo from '../../../img/blue-diamond-logo.png'
import Preloader from '../../Loader/Preloader'
import Toast from '../../IziToast/Toast'
import Registration from './Registration'
import M from 'materialize-css/dist/js/materialize.min.js'
import Complementation from './Complementation'
import { request } from '../../../services/request'
import R from '../../../services/routes'
import './BlueDiamond.scss'
import Information from './Information'
import Login from './Login'
import EditGuest from './EditGuest'
import AddGuest from './AddGuest'
import Switch from '../../Checkbox/Switch'
import moment from 'moment';
import axios from 'axios';
export default class BlueDiamond extends React.Component {

    opsB = {}
    opsIn = {}
    opsOut = {}
    months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Deciembre',
    ]
    monthsShort = [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
    ]
    weekdays = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
    ]
    weekdaysShort = [
        'Dom',
        'Lun',
        'Mar',
        'Mié',
        'Jue',
        'Vie',
        'Sáb',
    ]
    weekdaysAbbrev = ['D','L','M','M','J','V','S']

    constructor(){
        super()
        this.state = {
            showPreloader: false,
            showWelcomeAlert: true,
            email: '',
            reservation_number: '',
            params: null,
            reservationNumberParam: null,
            statusParam: null,
            hotel: '',
            country: '',
            phone: '',
            check_in: '',
            check_out: '',
            data: null,
            guestsData: [
                {
                    first_name: {
                        id: 'guest0_first_name',
                    },
                    last_name: {
                        id: 'guest0_last_name',
                    },
                    birth: {
                        id: 'guest0_birth',
                    },
                    genre: {
                        id: 'guest0_genre',
                    },
                }
            ],
            disabled: false,
            step: -1,
            birthSelected: '',
            toDay: new Date(),
            guestToEdit: '',
            guestFirstName: '',
            guestLastName: '',
            guestBirth: '',
            guestGenre: false,
            language: JSON.parse(!localStorage.getItem('language') ? false:localStorage.getItem('language')),
            registryEntities: [
                'EXPEDIA',
                'PRICELINE',
                'FUNJET VACATIONS',
                'AMERICAN AIRLINES',
                'PLEASANT HOLIDAYS',
                'VACATION EXPRESS',
                'BOOKING.COM',
                'DELTA VACATIONS',
                'FLIGHT CENTER',
                'SOUTHWEST VACATIONS',
                'TRAVEL IMPRESSIONS',
                'CHEAP CARIBBEAN',
                'APPLE VACATIONS',
                'HOTELBEDS',
                'UNITED VACATIONS',
                'COSTCO TRAVEL',
                'CLASSIC VACATIONS',
                'HOTEL WEBSITE',
                'OTHER'
            ], 
            registryEntity: '',
            showOtherRegistryEntity: false,
            otherRegistryEntity: ""
        }

        this.opsB = {
            autoClose: false,
            format: 'dddd dd mm, yyyy',
            onSelect: this.handleSelectPickerBirth,
            minDate: new Date('1921-01-01'),
            maxDate: new Date('2021-12-31'),
            yearRange: 18,
        }

        this.opsIn = {
            autoClose: false,
            format: 'dddd dd mm, yyyy',
            minDate: new Date('Y-m-d'),
            onSelect: this.handleSelectPickerCheckIn,
            yearRange: 0,
        }

        document.documentElement.lang = 'en'
        document.title = 'MAS Servicios'
    }
    
    async componentDidMount(){
        if(!this.session()){
            await this.setState({step: -1, language: JSON.parse(localStorage.getItem('language'))})
        } else {
            await this.handleShowComplementation();
        }
    }

    handleSelectPickerBirth = date => {
        const parsed = new Date(date)
        const day = parsed.getDate() < 10 ? `0${parsed.getDate()}` : parsed.getDate()
        const month = (parsed.getMonth() + 1) < 10 ? `0${(parsed.getMonth() + 1)}` : (parsed.getMonth() + 1)
        const year = parsed.getFullYear()
        this.setState({[this.state.birthSelected]: `${year}-${month}-${day}`})
    }

    handleSelectPickerCheckIn = date => {
        // const parsed = new Date(date)
        // const day = parsed.getDate() < 10 ? `0${parsed.getDate()}` : parsed.getDate()
        // const dayPlusOne = (parsed.getDate() + 1) < 10 ? `0${(parsed.getDate() + 1)}` : (parsed.getDate() + 1)
        // const month = (parsed.getMonth() + 1) < 10 ? `0${(parsed.getMonth() + 1)}` : (parsed.getMonth() + 1)
        // const year = parsed.getFullYear()
        // this.setState({check_in: `${year}-${month}-${day}`})
        // this.opsOut = {
        //     autoClose: false,
        //     format: 'dddd dd mm, yyyy',
        //     minDate: new Date(`${year}-${month}-${dayPlusOne}T00:00:00`),
        //     onSelect: this.handleSelectPickerCheckOut,
        //     yearRange: 10,
        // }
        // const checkOut = document.querySelector('#check_out')
        // M.Datepicker.init(checkOut, this.opsOut)
    }

    handleSelectPickerCheckOut = date => {
        // const parsed = new Date(date)
        // const day = parsed.getDate() < 10 ? `0${parsed.getDate()}` : parsed.getDate()
        // const month = (parsed.getMonth() + 1) < 10 ? `0${(parsed.getMonth() + 1)}` : (parsed.getMonth() + 1)
        // const year = parsed.getFullYear()
        // this.setState({check_out: `${year}-${month}-${day}`})
    }

    async handleShowComplementation() {
        const record = JSON.parse(localStorage.getItem('record'));
        if(record.status === 0){
            const record = JSON.parse(localStorage.getItem('record'))
            await this.setState({step: 1, statusParam: record.status, reservationNumber: record.reservation, language: JSON.parse(localStorage.getItem('language'))})
            this.getRecords()
        }
        if(record.status === 1){
            await this.setState({step: 2, statusParam: record.status, reservationNumber: record.reservation, language: JSON.parse(localStorage.getItem('language'))})
            this.getRecords()
        }
        if(this.state.language){
            this.opsB.i18n = {
                cancel: 'Cancel',
                done: 'Aceptar',
                months: this.months,
                monthsShort: this.monthsShort,
                weekdays: this.weekdays,
                weekdaysShort: this.weekdaysShort,
                weekdaysAbbrev: this.weekdaysAbbrev,
            }
            this.opsIn.i18n = {
                cancel: 'Cancel',
                done: 'Aceptar',
                months: this.months,
                monthsShort: this.monthsShort,
                weekdays: this.weekdays,
                weekdaysShort: this.weekdaysShort,
                weekdaysAbbrev: this.weekdaysAbbrev,
            }
            this.opsOut.i18n = {
                cancel: 'Cancel',
                done: 'Aceptar',
                months: this.months,
                monthsShort: this.monthsShort,
                weekdays: this.weekdays,
                weekdaysShort: this.weekdaysShort,
                weekdaysAbbrev: this.weekdaysAbbrev,
            }
            document.documentElement.lang = 'es'
        }
    }

    session = () => {
        if(localStorage.getItem('record') !== null){
            const record = JSON.parse(localStorage.getItem('record'))
            const recordDate = new Date(record.session_started_at)
            const offset = this.summer_schedule() ? -5 : -6
            const date = this.calcTime(offset)
            const between = date.getTime() - recordDate.getTime()
            const comp = Math.round(between / (1000*60))
            if(comp < 60){
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    calcTime = offset => {
        const d = new Date()
        const utc = d.getTime() + (d.getTimezoneOffset() * 60000)
        const nd = new Date(utc + (3600000*offset))
        return nd
    }

    summer_schedule = () => {
        const today = new Date()
        const yr = today.getFullYear()
        const dst_start = new Date('April 07, ' + yr + ' 10:00:00')
        const dst_end = new Date('October 31, ' + yr + ' 07:00:00')
        let day = dst_start.getDay()
        dst_start.setDate(7 - day)
        day = dst_end.getDay()
        dst_end.setDate(31 - day)
        if(today >= dst_start && today < dst_end)
            return true
        return false
    }

    getRecords = async () => {
        const res = await request('GET',R.getRecords + '?number=' + this.state.reservationNumber, true, false, false);
        if(res.success){
            await this.setState({data: res.records, statusParam: res.records[0].status})
            const guests = res.records.length
            const records = res.records
            if(guests > 0 && records[0].status === 0){
                // const birth = document.querySelectorAll('.datepicker')
                // const checkIn = document.querySelector('#check_in')
                // M.Datepicker.init(birth, this.opsB)
                // M.Datepicker.init(checkIn, this.opsIn)
                await this.setState({
                    id: records[guests-1].id,
                    email: records[0].email,
                    guests: guests,
                })
                M.updateTextFields()

            } 
            if(records[0].status === 1){
                this.setState({step: 2, guests: guests,})
            }
            localStorage.setItem('record', JSON.stringify(records[0]))
            M.updateTextFields()
            const selects = document.querySelectorAll('select')
            M.FormSelect.init(selects)
        }

    }

    handleSubmit = async e => {
        e.preventDefault()
        e.stopPropagation()
        if (!this.state.reservation_number || !this.state.email || !this.state.password) {
            alert(this.state.language ? 'Verifique sus datos' : 'Check your data');return false;
        }
        await this.setState({showPreloader: true})

        const record = await request('GET', `${R.getRecordByReservation}/${this.state.reservation_number}`, true, true, false)
        
        if(record.record){
            if(this.state.language)
                Toast.showWarning({
                    timeout: false,
                    title: 'Reserva ya registrada',
                    message: 'Este número de reserva ya se ha registrado anteriormente, por lo que no podrá volver a registrarlo. Revise su reserva y vuelva a intentarlo.',
                    position: 'bottomCenter',
                })
            else
                Toast.showWarning({
                    timeout: false,
                    title: 'Reservation already registered',
                    message: 'This reservation number has already been registered previously so you will not be able to register it again. Please review your reservation and try again.',
                    position: 'bottomCenter',
                })
        } else {
            const data = {
                email: this.state.email,
                reservation: this.state.reservation_number,
                password: this.state.password,
            }
            const bd = await request('POST', R.storeStartRecord, true, false, JSON.stringify(data))
            if(bd.success){
                localStorage.setItem('record', JSON.stringify(bd.record));
                this.handleShowComplementation();
                // window.location.reload();
            }
        }
        await this.setState({showPreloader: false})
    }

    handleSubmitLogin = async e => {
        e.preventDefault()
        e.stopPropagation()

        await this.setState({showPreloader: true})

        const data = {
            email: this.state.login_email,
            reservation: this.state.login_reservation,
            password: this.state.password,
        }

        const response = await request('POST', R.loginBlueDiamond, true, true, JSON.stringify(data))
        
        if(response.success){
            if(this.state.language)
                Toast.showSuccess({
                    title: 'Bienvenido!',
                    message: 'Iniciaste sesión',
                    position: 'topCenter',
                })
            else
                Toast.showSuccess({
                    title: 'Welcome!',
                    message: 'You logged in',
                    position: 'topCenter',
                })
            
            localStorage.setItem('record', JSON.stringify(response.record))
            window.location.reload()
        } else {
            if(this.state.language)
                Toast.showError({
                    title: 'Error',
                    message: 'Algo salió mal. Error al iniciar sesión, verifique sus credenciales (usuario, password, y número de reservación)',
                    position: 'topCenter',
                })
            else
                Toast.showError({
                    title: 'Error',
                    message: 'Something went wrong. Login failed, verify your credentials (user, password and reservation/voucher number',
                    position: 'topCenter',
                })
        }
        await this.setState({showPreloader: false})
    }

    handleSubmitInfo = async e => {
        e.preventDefault();
        e.stopPropagation();

        await this.setState({showPreloader: true, disabled: true})

        let data = []
        let guests = 1
        let obj = {}
        this.state.guestsData.forEach(guest => {
            if(guests === 1){
                obj = {
                    id: this.state.id,
                    reservation: this.state.reservationNumber,
                    first_name: this.state[guest.first_name.id],
                    last_name: this.state[guest.last_name.id],
                    birth_date: this.state[guest.birth.id],
                    genre: this.state[guest.genre.id] === undefined ? false:this.state[guest.genre.id],
                    hotel: this.state.hotel,
                    country: this.state.country,
                    country_birth: this.state.countryBirth,
                    registry_entity: this.state.registryEntity,
                    other_registry_entity: this.state.otherRegistryEntity,
                    address: this.state.address,
                    phone: this.state.phone,
                    check_in: this.state.check_in,
                    check_out: this.state.check_out,
                }
                data.push(obj);
            } else {
                obj = {
                    reservation: this.state.reservationNumber,
                    first_name: this.state[guest.first_name.id],
                    last_name: this.state[guest.last_name.id],
                    email: this.state.email,
                    birth_date: this.state[guest.birth.id],
                    genre: this.state[guest.genre.id] === undefined ? false:this.state[guest.genre.id],
                    hotel: this.state.hotel,
                    country: this.state.country,
                    country_birth: this.state.countryBirth,
                    registry_entity: this.state.registryEntity,
                    other_registry_entity: this.state.otherRegistryEntity,
                    address: this.state.address,
                    phone: this.state.phone,
                    check_in: this.state.check_in,
                    check_out: this.state.check_out,
                }
                data.push(obj);
            }
            guests++;
        });

        await data.forEach(async guest => {
            await request('POST', R.storeRecord, true, false, JSON.stringify(guest))
        })

        if(this.state.language)
            Toast.showSuccess({
                title: 'Información guardada',
                message: 'Toda su información fue guardada; para acceder en el futuro, no olvide que su contraseña fue enviada por correo electrónico desde noreply@masservicios.com.mx',
                position: 'topCenter',
                closingFn: async () => {
                    const data = {
                        reservation: this.state.reservationNumber,
                        status: 1,
                    }
                    const res = await request('PUT', R.updateRecordsStatus, true, true, JSON.stringify(data))
                    if(res.success) {
                        window.location.reload()
                    } else {
                        alert("No se han podido almacenar los cambios, verifique si todos los campos requeridos han sido capturados");
                        this.setState({
                            disabled: false
                        });
                    }
                }
            })
        else
            Toast.showSuccess({
                title: 'Data saved',
                message: 'All your data was saved; for future access, don\'t forget that your password was sent by email from noreply@masservicios.com.mx',
                position: 'topCenter',
                closingFn: async () => {
                    const data = {
                        reservation: this.state.reservationNumber,
                        status: 1,
                    }
                    const res = await request('PUT', R.updateRecordsStatus, true, true, JSON.stringify(data))
                    if(res.success) {
                        window.location.reload()
                    } else {
                        alert("Your data can not be stored, some required fields are missing");
                        this.setState({
                            disabled: false
                        });
                    }
                }
            })

        await this.setState({showPreloader: false})
    }

    handleSubmitRegistryInfo = async (e) => {
        let updatedRegistryData =  {}
        updatedRegistryData.id = this.state.data[0].id;
        updatedRegistryData.hotel = this.state.hotel;
        updatedRegistryData.registry_entity = this.state.registry_entity;
        updatedRegistryData.other_registry_entity = this.state.other_registry_entity;

        const authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV
        let apiUrl = `${authenticate}${R.updateRecordsHotelRegistryEntity}/${updatedRegistryData.id}`;

        axios
        .put(apiUrl, updatedRegistryData)
        .then(({data}) => {
            Toast.showSuccess({
                title: '',
                message: 'Ok',
                position: 'center',
            });
        })
        .catch((errors) => {
            console.error("errors: ", errors);
        })

    }

    handleChange = async e => {
        const {id, value} = e.target;

        if ((id == 'registryEntity' || id == 'registry_entity') && value == 'OTHER') {
            await this.setState({
                ...this.state,
                [id]: value,
                'registry_entity': value,
                showOtherRegistryEntity: true,
            });
        } else if ((id == 'registryEntity' || id == 'registry_entity') && value != 'OTHER') {
            await this.setState({
                ...this.state,
                [id]: value,
                'registry_entity': value,
                showOtherRegistryEntity: false,
                otherRegistryEntity: ''
            });
        }  else {
            await this.setState({
                ...this.state,
                [id]: value
            })
        }
    }

    handleBlur = (e) => {
        let {id, value} = e.target;

        let noSpaces = value.replace(/\s/g, '');
        let onlyTwelveChars = noSpaces.substring(0, 12);

        this.setState({
            ...this.state,
            [id]: onlyTwelveChars
        });

    }

    handleToggleSwitch = async e => {
        const {id} = e.target
        await this.setState({
            ...this.state,
            [id]: !this.state[id],
            showWelcomeAlert: true,
            country: '',
            countryBirth: '',
        })
        if(id === 'language')
            if(this.state.language){
                document.documentElement.lang = 'es'
                localStorage.setItem('language', true)
                this.opsB.i18n = {
                    cancel: 'Cancel',
                    done: 'Aceptar',
                    months: this.months,
                    monthsShort: this.monthsShort,
                    weekdays: this.weekdays,
                    weekdaysShort: this.weekdaysShort,
                    weekdaysAbbrev: this.weekdaysAbbrev,
                }
                this.opsIn.i18n = {
                    cancel: 'Cancelar',
                    done: 'Aceptar',
                    months: this.months,
                    monthsShort: this.monthsShort,
                    weekdays: this.weekdays,
                    weekdaysShort: this.weekdaysShort,
                    weekdaysAbbrev: this.weekdaysAbbrev,
                }
                this.opsOut.i18n = {
                    cancel: 'Cancelar',
                    done: 'Aceptar',
                    months: this.months,
                    monthsShort: this.monthsShort,
                    weekdays: this.weekdays,
                    weekdaysShort: this.weekdaysShort,
                    weekdaysAbbrev: this.weekdaysAbbrev,
                }
            } else {
                document.documentElement.lang = 'en'
                localStorage.setItem('language', false)
                this.opsB.i18n = {}
                this.opsIn.i18n = {}
                this.opsOut.i18n = {}
            }
    }

    handleRemoveGuest = async (key) => {
        const guestsLength = this.state.guestsData.length

        this.state.guestsData.splice(key, key);

        await this.setState({guestsData: this.state.guestsData})

    }

    handleRemoveGuestFromDB = async (guestId, key) => {
        console.log("Remove guest from data base: ", guestId);
        let response = request('PUT', R.deleteGuestRecord + '/' + guestId, true, true, false);
        console.log('handleRemoveGuestFromDB.response: ', response);

        response.then((data) => {
            console.log("handleRemoveGuestFromDB.response.then.data ", data);
            if (data.success == true) {
                this.state.data.splice(key, key);
                this.setState({guestsData: this.state.data});
            }
        })
        .catch(e => {
            let message = this.state.language ? 'No se pudo eliminar el huesped' : 'Can not delete the guest';
            Toast.showError({
                title: 'Error',
                message: message,
                position: 'topCenter',
            })

        })

    }

    handleAddGuest = async () => {
        const guestsLength = this.state.guestsData.length
        if(guestsLength < 12){
            const newGuest = {
                first_name: {
                    id: `guest${guestsLength}_first_name`,
                },
                last_name: {
                    id: `guest${guestsLength}_last_name`,
                },
                birth: {
                    id: `guest${guestsLength}_birth`,
                },
                genre: {
                    id: `guest${guestsLength}_genre`,
                },
            }
            const guetsData = this.state.guestsData
            guetsData.push(newGuest)
            await this.setState({guestsData: guetsData})
            // const birth = document.querySelector(`#guest${guestsLength}_birth`)
            // M.Datepicker.init(birth, this.opsB)
            M.updateTextFields()
        }
    }

    handleLogout = async () => {
        let selectedLang = this.state.language == true ? 'es' : 'en';
        let langTilte = {
            'es' : 'Reservación registrado',
            'en' : 'Registered reservation'
        };
        let langMessage = {
            'es' : 'Tu reservación está registrada. Recibirás un correo electrónico con tu contraseña si necesitas agregar invitados. Si no recibes esta información por correo electrónico, contáctanos por los siguientes medios:<br/>México || Teléfono: +52800 953 1674 | Whatsapp: +57 316 763 6033 | Correo electrónico: atencionviajero@masservicios.com.mx <br> <br> EE.UU. (llamada gratuita) || Teléfono: +1800391 0540 <br> <br> Resto del mundo || Teléfono: +1954516 6976 <br> <br>',
            'en' : 'Your reservation is registered. You will receive an email with your password, if you need to add guests. If you do not recive the information by email, you can contact Us by the following means:<br/>México || Teléfono: +52800 953 1674 | Whatsapp: +57 316 763 6033 | Email: atencionviajero@masservicios.com.mx <br> <br> EE.UU. (toll free) || Phone: +1800391 0540 <br> <br> World wide || Phone: +1954516 6976 <br> <br>'
        };
        let welcomeTitle = langTilte[selectedLang];
        let welcomeMessage = langMessage[selectedLang];
        Toast.showSuccess({
            title: welcomeTitle,
            message: welcomeMessage,
            position: 'center',
        });

        await this.setState({showCustom: true})
        localStorage.clear()
        await this.setState({
            showPreloader: false,
            email: '',
            reservation_number: '',
            params: null,
            reservationNumberParam: null,
            statusParam: null,
            first_name: '',
            last_name: '',
            birth: '',
            rfc: '',
            genre: false,
            hotel: '',
            country: '',
            phone: '',
            check_in: '',
            check_out: '',
            data: null,
            guests: 1,
            disabled: false,
            step: -1,
        });
        M.updateTextFields()
    }

    handleEditGuest = async id => {
        const guests = this.state.data
        const guest = guests.filter(guest => guest.id === id)
        await this.setState({
            step: 3,
            guestToEdit: id,
            guestFirstName: guest[0].first_name,
            guestLastName: guest[0].last_name,
            guestBirth: guest[0].birth_date,
            guestGenre: guest[0].genre,
        })
        // const init = this.opsB
        // init.defaultDate = new Date(`${guest[0].birth_date}T00:00:00`)
        // init.setDefaultDate = true
        // const birth = document.querySelector('#guestBirth')
        // M.Datepicker.init(birth, init)
        M.updateTextFields();
    }

    handleUpdateGuest = async () => {
        await this.setState({showPreloader: true})
        const data = {
            id: this.state.guestToEdit,
            first_name: this.state.guestFirstName,
            last_name: this.state.guestLastName,
            birth_date: this.state.guestBirth,
            genre: this.state.guestGenre,
        }
        const res = await request('PUT', R.updateRecordsGuest, true, true, JSON.stringify(data))
        if(res.success){
            await this.setState({showPreloader: false})
            if(this.state.language)
                Toast.showSuccess({
                    title: 'Huésped actualizado',
                    message: 'La información del huésped fue actualizada con exito',
                    position: 'topCenter',
                    closingFn: async () => {
                        window.location.reload()
                    }
                })
            else
                Toast.showSuccess({
                    title: 'Guest updated',
                    message: 'Guest information updated successfully',
                    position: 'topCenter',
                    closingFn: async () => {
                        window.location.reload()
                    }
                })
        }
    }

    handleInfoAddGuest = async () => {
        await this.setState({step: 4})
        // const birth = document.querySelector('#guestBirth')
        // M.Datepicker.init(birth, this.opsB)
        M.updateTextFields()
    }

    handleSaveGuest = async () => {
        const data ={
            reservation: this.state.data[0].reservation,
            first_name: this.state.guestFirstName,
            last_name: this.state.guestLastName,
            email: this.state.data[0].email,
            birth_date: this.state.guestBirth,
            genre: this.state.guestGenre === undefined ? false:this.state.guestGenre,
            hotel: this.state.data[0].hotel,
            country: this.state.data[0].country,
            country_birth: this.state.data[0].countryBirth,
            registry_entity: this.state.data[0].registryEntity,
            other_registry_entity: this.state.data[0].otherRegistryEntity,
            phone: this.state.data[0].phone,
            check_in: this.state.data[0].check_in,
            check_out: this.state.data[0].check_out,
        }
                
        const res = await request('POST', R.storeRecord, true, false, JSON.stringify(data))
        if(res.success)
            if(this.state.language)
                Toast.showSuccess({
                    title: 'Huésped guardado',
                    message: 'La información del huésped fue guardada con exito',
                    position: 'topCenter',
                    closingFn: () => {
                        window.location.reload()
                    }
                })
            else
                Toast.showSuccess({
                    title: 'Guest saved',
                    message: 'Guest information saved successfully',
                    position: 'topCenter',
                    closingFn: () => {
                        window.location.reload()
                    }
                })
    }

    goTo = async step => {
        await this.setState({step})
        M.updateTextFields()
    }

    onFocus = async e => {
        // const {id} = e.target
        // const el = await document.getElementById(id)
        // await this.setState({birthSelected: id})
        // const instance = M.Datepicker.getInstance(el)
        // instance.options.minDate = new Date('1921-01-01');
        // instance.options.maxDate = new Date('2021-12-31');
        // instance.options.yearRange = 0;
        // instance.open()
    }

    // to display welcome message
    showWelcomeAlert = () => {
        let selectedLang = this.state.language == true ? 'es' : 'en';
        let langMessage = {
            'es' : 'Ha iniciado su proceso de registro con MAS Servicios<br/>Recuerda ingresar correctamente la información solicitada para obtener asistencia médica',
            'en' : 'You have started your registration process with MAS Servicios<br/>Remember to enter correctly all the requested data in order to obtain medical assistance'
        };
        let welcomeMessage = langMessage[selectedLang];
        if (this.state.showWelcomeAlert == true) {
            this.setState({
                showWelcomeAlert: false
            }, () => {
                Toast.showSuccess({
                    timeout: false,
                    buttons: {
                        ok:{
                            name: 'Ok'
                        },
                        cancel:{
                            name: 'Cancel'
                        }
                    },
                    title: '',
                    message: welcomeMessage,
                    position: 'center',
                });
            });
        }
    }

    handleSendConfirmation = async (row) =>  {
        let selectedLang = this.state.language == true ? 'es' : 'en';
        const responseSendConfirmation = await request('POST', '/api/blue_diamond/confirmation/' + row, true, false, JSON.stringify(row));

        let isReadyToSend = responseSendConfirmation.data;
        let sentMessage = '';
        if (isReadyToSend) {
            let langMessageReady = {
                'es' : 'El alta del registro será enviado por email.',
                'en' : 'Your registry will be send via email'
            };
            sentMessage = langMessageReady[selectedLang];
            Toast.showSuccess({
                timeout: false,
                buttons: {
                    ok:{
                        name: 'Ok'
                    },
                    cancel:{
                        name: 'Cancel'
                    }
                },
                title: '',
                message: sentMessage,
                position: 'center',
            });
        } else {
            let langMessageReady = {
                'es' : 'La solicitud no pudo ser procesada, por favor verifique su información',
                'en' : 'Request can not been processed, please verify your information'
            };
            sentMessage = langMessageReady[selectedLang];
            Toast.showError({
                timeout: false,
                buttons: {
                    ok:{
                        name: 'Ok'
                    },
                    cancel:{
                        name: 'Cancel'
                    }
                },
                title: '',
                message: sentMessage,
                position: 'center',
            });
        }

    }

    render(){
        return(
            <div className="blue_diamond">
                <div className="resort_background"></div>
                <div className="language-switch" align="center">
                    <Switch
                        off="English"
                        on="Español"
                        id="language"
                        checked={this.state.language}
                        toggleSwitch={this.handleToggleSwitch.bind(this)}
                    />
                </div>
                {this.state.showPreloader &&
                    <Preloader color="blue" />
                }
                {this.state.step === -1 ?
                
                    <Registration
                        handleSubmit={this.handleSubmit.bind(this)}
                        handleChange={this.handleChange.bind(this)}
                        handleBlur={this.handleBlur.bind(this)}
                        goTo={this.goTo.bind(this)}
                        state={this.state}
                        logoMAS={logoMAS}
                        displayWelcomeAlert={this.showWelcomeAlert.bind(this)}
                    />
                    :
                    <React.Fragment>
                        {this.state.step === 0 &&
                            <Login
                                handleSubmit={this.handleSubmitLogin.bind(this)}
                                handleChange={this.handleChange.bind(this)}
                                handleBlur={this.handleBlur.bind(this)}
                                state={this.state}
                                logoMAS={logoMAS}
                                displayWelcomeAlert={this.showWelcomeAlert.bind(this)}
                            />
                        }
                        {this.state.step === 1 &&
                            <React.Fragment>
                                <button className="btn waves-effect waves-light red" style={{position: 'fixed', top: '5px', right: '5px',}} onClick={this.handleLogout}><i className="material-icons left">power_settings_new</i>{this.state.language?'Cerrar sesión':'Log out'}</button>
                                <Complementation
                                    state={this.state}
                                    selectedLang={this.state.language}
                                    handleSubmit={this.handleSubmitInfo.bind(this)}
                                    handleChange={this.handleChange.bind(this)}
                                    handleToggleSwitch={this.handleToggleSwitch.bind(this)}
                                    handleAddGuest={this.handleAddGuest.bind(this)}
                                    handleRemoveGuest={this.handleRemoveGuest.bind(this)}
                                    onFocus={this.onFocus.bind(this)}
                                />
                            </React.Fragment>
                        }
                        {this.state.step === 2 &&
                            <React.Fragment>
                                <button className="btn waves-effect waves-light red" style={{position: 'fixed', top: '5px', right: '5px',}} onClick={this.handleLogout}><i className="material-icons left">power_settings_new</i>Log out</button>
                                <Information
                                    state={this.state}
                                    selectedLang={this.state.language}
                                    editGuest={this.handleEditGuest.bind(this)}
                                    addGuest={this.handleInfoAddGuest.bind(this)}
                                    removeGuest={this.handleRemoveGuestFromDB.bind(this)}
                                    handleSendConfirmation={this.handleSendConfirmation.bind(this)}
                                    handleSubmitRegistryInfo={this.handleSubmitRegistryInfo.bind(this)}
                                    handleChange={this.handleChange.bind(this)}
                                />
                            </React.Fragment>
                        }
                        {this.state.step === 3 &&
                            <React.Fragment>
                                <EditGuest
                                    id={this.state.guestToEdit}
                                    state={this.state}
                                    onFocus={this.onFocus.bind(this)}
                                    handleChange={this.handleChange.bind(this)}
                                    handleToggleSwitch={this.handleToggleSwitch.bind(this)}
                                    handleCancelAddEditGuest={() => {this.setState({step: 2})}}
                                    action={this.handleUpdateGuest.bind(this)}
                                />
                            </React.Fragment>
                        }
                        {this.state.step === 4 &&
                            <React.Fragment>
                                <AddGuest
                                    id={this.state.guestToEdit}
                                    state={this.state}
                                    onFocus={this.onFocus.bind(this)}
                                    handleChange={this.handleChange.bind(this)}
                                    handleToggleSwitch={this.handleToggleSwitch.bind(this)}
                                    handleCancelAddEditGuest={() => {this.setState({step: 2})}}
                                    action={this.handleSaveGuest.bind(this)}
                                />
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>
        )
    }
}