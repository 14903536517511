import React from 'react'

const Select = props => {
    const getValue = () => {
        let customValue = '';
        if (props.value) {
            customValue =props.value
        } else {
            customValue = props.id ? props.state[props.id]: undefined;
        } 
        return customValue;
    }
    let isRequired = props.requiredMessage ? true : false;

    return(
        <div className={`input-field col ${props.columns}`}>
            <select id={props.id} name={props.id} onChange={props.handleChange} value={getValue()} required={isRequired}>
                {props.children}
            </select>
            <label>
                {props.label}
                {props.requiredMessage ? 
                    <small style={{color:"#ff5722"}}>&nbsp;{props.requiredMessage}</small>
                : ''}
            </label>
        </div>
    )
}

export default Select