import React from 'react'
import './Loader.css'

export default function Loader(){
    return(
        <div className="principalLoader">
            <div className="progress">
                <div className="indeterminate"></div>
            </div>
        </div>
    )
}