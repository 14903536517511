import React from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
import Preloader from '../Loader/Preloader'
import FolioCard from './FolioCard'
import AssignModal from './AssignModal'
import Success from '../IziToast/Success'
import Error from '../IziToast/Error'
import {foliosSearch, assignInfo} from '../../Functions/folios'
const authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV

export default class AssignInfo extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            showPreloader: false,
            folios: null,
            idToSend: null,
            search: ''
        }
    }

    componentDidMount(){
        M.updateTextFields()
        const selects = document.querySelectorAll('select')
        M.FormSelect.init(selects)
    }

    handleChange = e => {
        const value = e.target.value
        this.setState({
            ...this.state,
            [e.target.id]: value
        })
    }

    handlePaginationClickFolios(url){
        this.setState({showPreloader: true})
        foliosSearch(url)
        .then(folios => {
            this.setState({ folios: folios.folios, showPreloader: false })
        })
    }

    handleSearch = () =>{
        foliosSearch(authenticate + '/api/folio/search/' + this.state.search)
        .then(folios => {
            this.setState({folios: folios.folios, showPreloader: false})
        })
    }

    handleCloseModal = id => {
        const element = document.getElementById(id)
        const modal =  M.Modal.getInstance(element)
        modal.close()
        this.setState({ idToSend: null })
    }

    handleFolioClick = id => {
        this.assign(id)
        .then(() => {
            const element = document.getElementById('modalAssignInfo')
            const modal = M.Modal.init(element, {preventScrolling: false, dismissible: false, opacity: 0})
            modal.open()
        })
    }

    assign = async id => {
        await this.setState({ idToSend: id })
    }

    handleSubmit = data => {
        const id = 'modalAssignInfo'
        this.setState({ showPreloader: true })
        assignInfo(data)
        .then(response => {
            if(response.success){
                Success('Guardado', 'Se guardó la información del folio', 'bottomCenter')
            } else {
                Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
            }
            const element = document.getElementById(id)
            const modal =  M.Modal.getInstance(element)
            modal.close()
            this.setState({showPreloader: false, idToSend: null})
        })
        .catch(e => {
            console.log(e)
            Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
            const element = document.getElementById(id)
            const modal =  M.Modal.getInstance(element)
            modal.close()
            this.setState({showPreloader: false, idToSend: null})
        })
    }

    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {this.state.showPreloader &&
                        <Preloader color="yellownee" />
                    }
                    <div className="col s12">
                        <div className="row" style={{marginBottom: '0'}}>
                            <div className="col s10 m5">
                                <div className="input-field">
                                    <input id="search" type="text" className="deep-blue" value={this.state.search} onChange={this.handleChange} />
                                    <label htmlFor="search">Buscar folio</label>
                                </div>
                            </div>
                            <div className="col s2 m1">
                                <button className="btn-floating waves-effect waves-light tooltipped" style={{marginTop: '1rem'}} data-position="top" data-tooltip="Buscar" onClick={this.handleSearch}>
                                    <i className="material-icons deep-blue white">search</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{overflow: 'hidden'}}>
                    <div className="col s12">
                        {this.state.folios &&
                            <div className="row valign-wrapper">
                                <div className="col s12 m6">
                                    <ul className="pagination" style={{float: 'right', margin: '3px'}}>
                                        <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClickFolios(this.state.folios.first_page_url)}}><i className="material-icons">first_page</i></a></li>
                                        {this.state.folios.prev_page_url &&
                                            <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClickFolios(this.state.folios.prev_page_url)}}>{this.state.folios.current_page - 1}</a></li>
                                        }
                                        <li className="waves-effect active"><a href="#" className="white-text">{this.state.folios.current_page}</a></li>
                                        {this.state.folios.next_page_url &&
                                            <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClickFolios(this.state.folios.next_page_url)}}>{this.state.folios.current_page + 1}</a></li>
                                        }
                                        <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClickFolios(this.state.folios.last_page_url)}}><i className="material-icons">last_page</i></a></li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                    {this.state.folios &&
                        this.state.folios.data.map(folio => {
                            return(
                                <FolioCard
                                    key={folio.id}
                                    folio={folio}
                                    callbackClick={this.handleFolioClick.bind(this)}
                                />
                            )
                        })
                    }
                </div>
                {this.state.idToSend !== null &&
                    <AssignModal
                        id={this.state.idToSend}
                        callbackClose={this.handleCloseModal.bind(this)}
                        callbackSubmit={this.handleSubmit.bind(this)}
                    />
                }
            </React.Fragment>
        )
    }
}