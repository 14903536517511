const iziToast = require('izitoast')

export default function(title, message, position, callBack = function(){console.log('callback')}){

    iziToast.settings({
        timeout: 0,
        theme: 'light',
        icon: 'fa fa-question',
        overlay: true,
        resetOnHover: true,
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        iconColor: '#ff5555',
        titleColor: '#282c36',
        messageColor: '#7e8086',
        progressBarColor: '#ff5555',
        backgroundColor: '#ffffff'
    })

    iziToast.question({
        title: title,
        message: message,
        position: position,
        inputs: [
            ['<input type="text">', 'keyup', function (instance, toast, input, e) {
            }, true],
        ],
        buttons: [
            ['<button style="margin-left:15px;">Aceptar</button>', async function (instance, toast, button, e, inputs) {
                localStorage.setItem('textCallBack', inputs[0].value)
                await callBack()
                instance.hide({
                    transitionOut: 'fadeOutUp',
                }, toast, 'buttonName')
            }], // true to focus
            ['<button>Cancelar</button>', function (instance, toast) {
                instance.hide({
                    transitionOut: 'fadeOutUp',
                }, toast, 'buttonName')
            }]
        ],
    })
}