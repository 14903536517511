import axios from 'axios'

const updateWatchers = async function(task, watchers){
    const options = {
        method: 'PUT',
        headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
        url: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/task/update/watchers/'+ task +'/' + watchers,
    }
    const response = await axios(options)
                         .then(res => {
                             const data = res.data
                             return data
                         })
                         .catch(e => {
                             console.log(e)
                         })

    return response
}

const pending = async function(){
    return await axios.get((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/task/pending/' + localStorage.getItem('id'), {
        headers: { Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(response => {
        const data = response.data
        return data
    })
    .catch(e => {
        console.log(e)
        return {
            success: false,
            error: e
        }
    })
}

export {updateWatchers, pending}