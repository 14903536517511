import React from 'react'
import UrlPanelCard from '../Cards/UrlPanelCard'
import AddProject from './AddProject'
import {verify, refresh} from '../../Functions/verify'
const cards = [
    {
        title: 'Crear proyecto',
        text: 'Iniciar un nuevo proyecto para un cliente.',
        color: 'green',
        section: 'new_project'
    }
]

export default class Projects extends React.Component {

    constructor(){
        super()
        this.state = {
            show: 'cards'
        }
    }

    componentDidMount(){
        this.check()
        .then(auth => {
            console.log(auth)
            if(!auth){
                window.location.href = '/'
            }
        })
    }

    check = async () => {
        const auth = await verify()
        return auth
    }

    getSection = section => {
        this.setState({show: section})
    }

    handleClickBack = () => {
        this.setState({show: 'cards'})
    }

    render(){
        return(
            <div className="content">
                <div className="row">
                    {this.state.show === 'cards' &&
                        cards.map(card => {
                            return(
                                <UrlPanelCard 
                                    key={card.title}
                                    title={card.title} 
                                    text={card.text}  
                                    color={card.color} 
                                    section={card.section}
                                    callback={this.getSection.bind(this)}
                                />
                            )
                        })
                    }
                </div>
                {this.state.show === 'new_project' &&
                    <React.Fragment>
                         <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                            <i className="material-icons left">arrow_back</i>Regresar
                        </button>
                        <AddProject />
                    </React.Fragment>
                }
            </div>
        )
    }
}