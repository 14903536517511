import React from 'react'
import PanelCards from '../Cards/PanelCards'
import Profile from './Profile'
import {verify, refresh} from '../../Functions/verify'
import {pending} from '../../Functions/tasks'
import './Home.css'
import { ResponsiveLine } from '@nivo/line'
import { Scrollbars } from 'react-custom-scrollbars'

const data = 
[
    {
      "id": "japan",
      "color": "hsl(230, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 85
        },
        {
          "x": "helicopter",
          "y": 24
        },
        {
          "x": "boat",
          "y": 68
        },
        {
          "x": "train",
          "y": 124
        },
        {
          "x": "subway",
          "y": 266
        },
        {
          "x": "bus",
          "y": 38
        },
        {
          "x": "car",
          "y": 22
        },
        {
          "x": "moto",
          "y": 283
        },
        {
          "x": "bicycle",
          "y": 148
        },
        {
          "x": "horse",
          "y": 275
        },
        {
          "x": "skateboard",
          "y": 175
        },
        {
          "x": "others",
          "y": 22
        }
      ]
    },
    {
      "id": "france",
      "color": "hsl(295, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 65
        },
        {
          "x": "helicopter",
          "y": 276
        },
        {
          "x": "boat",
          "y": 152
        },
        {
          "x": "train",
          "y": 204
        },
        {
          "x": "subway",
          "y": 84
        },
        {
          "x": "bus",
          "y": 191
        },
        {
          "x": "car",
          "y": 63
        },
        {
          "x": "moto",
          "y": 111
        },
        {
          "x": "bicycle",
          "y": 158
        },
        {
          "x": "horse",
          "y": 234
        },
        {
          "x": "skateboard",
          "y": 228
        },
        {
          "x": "others",
          "y": 118
        }
      ]
    },
    {
      "id": "us",
      "color": "hsl(225, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 277
        },
        {
          "x": "helicopter",
          "y": 195
        },
        {
          "x": "boat",
          "y": 252
        },
        {
          "x": "train",
          "y": 285
        },
        {
          "x": "subway",
          "y": 168
        },
        {
          "x": "bus",
          "y": 18
        },
        {
          "x": "car",
          "y": 243
        },
        {
          "x": "moto",
          "y": 39
        },
        {
          "x": "bicycle",
          "y": 104
        },
        {
          "x": "horse",
          "y": 169
        },
        {
          "x": "skateboard",
          "y": 125
        },
        {
          "x": "others",
          "y": 164
        }
      ]
    },
    {
      "id": "germany",
      "color": "hsl(62, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 172
        },
        {
          "x": "helicopter",
          "y": 275
        },
        {
          "x": "boat",
          "y": 53
        },
        {
          "x": "train",
          "y": 110
        },
        {
          "x": "subway",
          "y": 221
        },
        {
          "x": "bus",
          "y": 90
        },
        {
          "x": "car",
          "y": 104
        },
        {
          "x": "moto",
          "y": 33
        },
        {
          "x": "bicycle",
          "y": 68
        },
        {
          "x": "horse",
          "y": 234
        },
        {
          "x": "skateboard",
          "y": 223
        },
        {
          "x": "others",
          "y": 180
        }
      ]
    },
    {
      "id": "norway",
      "color": "hsl(166, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 101
        },
        {
          "x": "helicopter",
          "y": 76
        },
        {
          "x": "boat",
          "y": 107
        },
        {
          "x": "train",
          "y": 44
        },
        {
          "x": "subway",
          "y": 20
        },
        {
          "x": "bus",
          "y": 47
        },
        {
          "x": "car",
          "y": 185
        },
        {
          "x": "moto",
          "y": 28
        },
        {
          "x": "bicycle",
          "y": 97
        },
        {
          "x": "horse",
          "y": 68
        },
        {
          "x": "skateboard",
          "y": 246
        },
        {
          "x": "others",
          "y": 99
        }
      ]
    }
]

export default class Home extends React.Component{

  authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV

    constructor(){
        super()
        this.state = {
          showTask: false,
          tasks: null,
          pending: null,
          yet: null
        }
    }

    componentDidMount(){
        this.check()
        .then(auth => {
            if(!auth){
                window.location.href = '/'
            } else {
                this.getPending()
                const acs = JSON.parse(localStorage.getItem('access'))
                if(acs.indexOf('Tasks') !== -1){
                  this.setState({showTask: true})
                }
            }
        })
    }

    check = async () => {
        const auth = await verify()
        return auth
    }

    getPending = async () => {
      await pending()
      .then(data => {
          if(data.success){
              this.setState({ tasks: data.tasks, pending: data.pending, yet: data.yet })
          } else {
            this.getPending()
          }
      })
      .catch(e => {
        this.getPending()
      })
    }

    render(){
        return (
            <div className="content" style={{height: '100%'}}>
                <div className="row" style={{height: '100%'}}>
                    <div className="col s12 m4">
                        {this.state.tasks &&
                          <Profile pending={this.state.pending} yet={this.state.yet} />
                        }
                    </div>
                    <div className="col s12 m8" style={{height: '100%'}}>
                        <Scrollbars style={{height: '100%', width: '100%'}}
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
                        >
                            {this.state.showTask &&
                              <div className="card-panel">
                                <span className="content--inner deep-blue" style={{fontSize: '18px'}}>Tareas pendientes</span>
                                <div className="content--inner row">
                                    {this.state.tasks &&
                                        this.state.tasks.map(task => {
                                            return(
                                                <PanelCards 
                                                    key={task.id} 
                                                    color={'deep-blue-bg'} 
                                                    title={task.name} 
                                                    content={task.observations} 
                                                />
                                            )
                                        })
                                    }
                                    <div className="col s12">
                                        <a href="/Tasks" className="waves-effect waves-light btn-flat center-align" style={{width: '100%', color: '#ababab'}} >Ir a tareas</a>
                                    </div>
                                </div>
                              </div>
                            }
                            <div className={`card-panel`}>
                                <div className="row">
                                    <div className="col s12 m12" style={{height: '350px'}}>
                                    <ResponsiveLine
                                        data={data}
                                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                        xScale={{ type: 'point' }}
                                        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            orient: 'bottom',
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'transportation',
                                            legendOffset: 36,
                                            legendPosition: 'middle'
                                        }}
                                        axisLeft={{
                                            orient: 'left',
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'count',
                                            legendOffset: -40,
                                            legendPosition: 'middle'
                                        }}
                                        colors={{ scheme: 'nivo' }}
                                        pointSize={10}
                                        pointColor={{ theme: 'background' }}
                                        pointBorderWidth={2}
                                        pointBorderColor={{ from: 'serieColor' }}
                                        pointLabel="y"
                                        pointLabelYOffset={-12}
                                        useMesh={true}
                                        legends={[
                                            {
                                                anchor: 'bottom-right',
                                                direction: 'column',
                                                justify: false,
                                                translateX: 100,
                                                translateY: 0,
                                                itemsSpacing: 0,
                                                itemDirection: 'left-to-right',
                                                itemWidth: 80,
                                                itemHeight: 20,
                                                itemOpacity: 0.75,
                                                symbolSize: 12,
                                                symbolShape: 'circle',
                                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                                            itemOpacity: 1
                                                        }
                                                    }
                                                ]
                                            }
                                        ]}
                                    />
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        )
    }
}