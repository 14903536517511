import React from 'react'

const NewSite = (props) => {
    return(
        <React.Fragment>
            <br/>
            <div className="col s12 m4 push-m4 flex-center" style={{textAlign: 'center'}}>
                <span className="panelTitle">Agregar nuevo sitio</span>
            </div>
            <br/>
            <form onSubmit={props.handleStoreSite}>
                <div className="input-field col s12 m8 push-m2">
                    <input placeholder="Nombre" className="deep-blue" value={props.state.siteName}  onChange={props.handleChange} id="siteName" type="text" required />
                    <label className="blue-sky" htmlFor="siteName">Nombre</label>
                </div>
                <div className="input-field col s12 m8 push-m2">
                    <input placeholder="IP privada" className="deep-blue" value={props.state.sitePrivateIp}  onChange={props.handleChange} id="sitePrivateIp" type="text" required />
                    <label className="blue-sky" htmlFor="sitePrivateIp">IP privada</label>
                </div>
                <div className="input-field col s12 m8 push-m2">
                    <input placeholder="IP pública" className="deep-blue" value={props.state.sitePublicIp}  onChange={props.handleChange} id="sitePublicIp" type="text" required />
                    <label className="blue-sky" htmlFor="sitePublicIp">IP pública</label>
                </div>
                <div className="input-field col s12 m8 push-m2">
                    <textarea placeholder="Descripción" className="deep-blue materialize-textarea" value={props.state.siteDescription} onChange={props.handleChange} id="siteDescription" type="text" required ></textarea>
                    <label className="blue-sky" htmlFor="siteDescription">Descripción</label>
                </div>
                <div className="col s12 m8 push-m2">
                    <button className="btn deep-blue-bg waves-effect waves-light" type="submit" style={{width: '100%'}}>
                        <i className="material-icons right">save</i>
                        Guardar
                    </button>
                </div>
            </form>
        </React.Fragment>
    )
}

export default NewSite