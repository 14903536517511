import React from 'react'
import Axios from 'axios'
import Preloader from '../Loader/Preloader'
import { DatePicker, DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { MultiSelectModel, MultiSelectComponent, CheckBoxSelection, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Legend, DataLabel, Category, ChartComponent, ColumnSeries, DateTime, DateTimeCategory, LineSeries, SeriesCollectionDirective, SeriesDirective, Tooltip, Export, Double, SplineSeries, Sparkline } from '@syncfusion/ej2-react-charts';
import { PivotViewComponent, FieldList, GroupingBar, VirtualScroll, Inject, CalculatedField, PivotFieldListComponent } from '@syncfusion/ej2-react-pivotview';
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-react-inputs';
import pdf from '../../img/pdf.png'
import excel from '../../img/excel.png'

const CHART_CSS = `
    #gradient-chart-line stop {
		stop-color: #3762B0;
	}
	#gradient-chart-line stop[offset="0"] {
		stop-opacity: 0;
	}
	#gradient-chart-line stop[offset="1"] {
		stop-opacity: 0.95;
	}
    #gradient-chart-line-2 stop {
		stop-color: #26A69A;
	}
	#gradient-chart-line-2 stop[offset="0"] {
		stop-opacity: 0;
	}
	#gradient-chart-line-2 stop[offset="1"] {
		stop-opacity: 0.95;
	}`;

export default class Graficas extends React.Component {
    authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV

    dtpFechaIniChart: DatePicker | null;
    dtpFechaFinChart: DatePicker | null;
    ddlAcountChart: MultiSelectModel | null;
    ddlPlanChart: MultiSelectModel | null;
    chartInstance: ChartComponent;
    chartInstance2: ChartComponent;
    formObject: FormValidator | null;
    options: FormValidatorModel | null;
    pivotInstance: PivotViewComponent | null;
    fieldlistObj: PivotFieldListComponent | null;
    pivotInstanceTiempoAsignacion: PivotViewComponent | null;
    fieldlistObjTiempoAsignacion: PivotFieldListComponent | null;
    pivotInstanceTiempoArribo: PivotViewComponent | null;
    fieldlistObjTiempoArribo: PivotFieldListComponent | null;

    graficas_data = [
        { id: 1, descripcion: "Distribución de asistencias por estado" },
        { id: 2, descripcion: "Distribución por tipo de asistencia" },
        { id: 3, descripcion: "Tiempo promedio de asignación y arribo" },
        { id: 5, descripcion: "% de cumplimiento de Tiempo de asignación y arribo" },
        { id: 7, descripcion: "Tabla de distribución de Tiempo de arribo General" },
        { id: 9, descripcion: "Tabla de distribución de Tiempo de arribo por Estado" }
    ]

    primaryxAxis = { valueType: 'Category', fill: '#7F46AA', interval: 1, majorGridLines: { width: 0 } };
    animation = { enable: true, duration: 1200, delay: 100 };
    titlestyle = {
        fontFamily: "Arial", fontStyle: 'italic', fontWeight: 'regular',
        color: "#7572C1", size: '20px'
    }



    constructor() {
        super()

        this.state = {
            showPreloader: false,
            plans_chart: null,
            account_chart: '',
            states: null,
            data_chart: null,
            data_chart_secondary: null,
            state: '',
            type: 1
        }

        this.dataSourceSettings = {
        }
    }

    //#region Methods
    handleSubmitCharts = async e => {
        e.preventDefault()
        e.stopPropagation()

        let filters = null;
        let options = null;
        let response = null;

        if (this.formObject.validate()) {
            await this.setState({ showPreloader: true, data_chart: null, data_chart_secondary: null })

            filters = {
                type: this.ddlGrafica.value,
                accounts: this.ddlAccountChart.value != null ? (this.ddlAccountChart.value.length == 0 ? '' : this.ddlAccountChart.value.join()) : '',
                plans: this.ddlPlanChart.value != null ? (this.ddlPlanChart.value.length == 0 ? '' : this.ddlPlanChart.value.join()) : '',
                states: this.ddlStateChart.value != null ? (this.ddlStateChart.value.length == 0 ? '' : this.ddlStateChart.value.join()) : '',
                indicators: '',
                date_ini: this.dtpFechaIniChart.value === null ? '' : this.dtpFechaIniChart.value.getFullYear() + '-' + (this.dtpFechaIniChart.value.getMonth() < 9 ? '0' + (this.dtpFechaIniChart.value.getMonth() + 1) : (this.dtpFechaIniChart.value.getMonth() + 1)) + '-' + (this.dtpFechaIniChart.value.getDate() <= 9 ? '0' + this.dtpFechaIniChart.value.getDate() : this.dtpFechaIniChart.value.getDate()),
                date_fin: this.dtpFechaFinChart.value === null ? '' : this.dtpFechaFinChart.value.getFullYear() + '-' + (this.dtpFechaFinChart.value.getMonth() < 9 ? '0' + (this.dtpFechaFinChart.value.getMonth() + 1) : (this.dtpFechaFinChart.value.getMonth() + 1)) + '-' + (this.dtpFechaFinChart.value.getDate() <= 9 ? '0' + this.dtpFechaFinChart.value.getDate() : this.dtpFechaFinChart.value.getDate()),
                indicator_type: ''
            }


            options = {
                method: 'POST',
                headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
                data: JSON.stringify(filters),
                url: this.authenticate + '/api/soa/myreportcharts'
            }

            response = await Axios(options)

            if (this.ddlGrafica.value === 3 || this.ddlGrafica.value === 5 || this.ddlGrafica.value === 7) {

                await this.setState({ data_chart: response.data.report });

                filters = {}
                options = {}
                response = {}

                filters = {
                    type: this.ddlGrafica.value + 1,
                    accounts: this.ddlAccountChart.value != null ? (this.ddlAccountChart.value.length == 0 ? '' : this.ddlAccountChart.value.join()) : '',
                    plans: this.ddlPlanChart.value != null ? (this.ddlPlanChart.value.length == 0 ? '' : this.ddlPlanChart.value.join()) : '',
                    states: this.ddlStateChart.value != null ? (this.ddlStateChart.value.length == 0 ? '' : this.ddlStateChart.value.join()) : '',
                    indicators: '',
                    date_ini: this.dtpFechaIniChart.value === null ? '' : this.dtpFechaIniChart.value.getFullYear() + '-' + (this.dtpFechaIniChart.value.getMonth() < 9 ? '0' + (this.dtpFechaIniChart.value.getMonth() + 1) : (this.dtpFechaIniChart.value.getMonth() + 1)) + '-' + (this.dtpFechaIniChart.value.getDate() <= 9 ? '0' + this.dtpFechaIniChart.value.getDate() : this.dtpFechaIniChart.value.getDate()),
                    date_fin: this.dtpFechaFinChart.value === null ? '' : this.dtpFechaFinChart.value.getFullYear() + '-' + (this.dtpFechaFinChart.value.getMonth() < 9 ? '0' + (this.dtpFechaFinChart.value.getMonth() + 1) : (this.dtpFechaFinChart.value.getMonth() + 1)) + '-' + (this.dtpFechaFinChart.value.getDate() <= 9 ? '0' + this.dtpFechaFinChart.value.getDate() : this.dtpFechaFinChart.value.getDate()),
                    indicator_type: ''
                }


                options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
                    data: JSON.stringify(filters),
                    url: this.authenticate + '/api/soa/myreportcharts'
                }

                response = await Axios(options)

                await this.setState({ data_chart_secondary: response.data.report, showPreloader: false })
            }
            else {

                await this.setState({ data_chart: response.data.report, showPreloader: false })

            }
        }


    }

    getAccounts = async () => {
        const res = await Axios.get(this.authenticate + '/api/soa/accounts', {
            headers: { Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') }
        })
        await this.setState({ accounts: res.data.accounts })
        //this.initSelects()
    }

    getPlansChart = async () => {

        await this.setState({ showPreloader: true })

        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
            data: JSON.stringify(this.state.account_chart),
            url: this.authenticate + '/api/soa/plans'
        }
        const response = await Axios(options)

        await this.setState({ showPreloader: false, plans_chart: response.data.plans })
        //this.initSelects()
    }

    getStates = async () => {
        await this.setState({ showPreloader: true })

        const res = await Axios.get(this.authenticate + '/api/soa/states', {
            headers: { Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') }
        })
        await this.setState({ states: res.data.states })

        await this.setState({ showPreloader: false })
        //this.initSelects()
    }

    //#endregion

    //#region Events

    componentDidMount = async () => {

        this.options = {
            // validation rules
            rules: {
                'dtpFechaIniChart': {
                    required: [true, '* Requerido']
                },
                'dtpFechaFinChart': {
                    required: [true, '* Requerido']
                }
            }
        };

        this.formObject = new FormValidator('#formGraficas', this.options)

        await this.getPlansChart()
        await this.getAccounts()
        await this.getStates()
    }

    ddlAccountChart_change = async (args) => {
        await this.setState({ showPreloader: true, account_chart: args.value, plans_chart: '' })

        this.ddlPlanChart.value = null;

        await this.getPlansChart()

        await this.setState({ showPreloader: false })
    }

    ddlAccountChart_removed = async (args) => {
        if (this.ddlAccountChart.value === null) {
            await this.setState({ account_chart: '' })
            this.ddlPlanChart.value = null;
            await this.getPlansChart()
        }
    }

    chart_tooltipRender = (args) => {
        //console.log(args)
    }

    chart_onLoaded = (args) => {
        if (!this.state.showPreloader)
            window.scrollTo(0, document.body.scrollHeight);
    }

    chart_export = (e) => {
        e.preventDefault()
        e.stopPropagation()

        let description_chart = ''

        if (this.ddlGrafica.value == 3) {
            description_chart = 'Tiempo promedio de asignación'
        }

        else if (this.ddlGrafica.value == 5) {
            description_chart = 'Porcentaje de cumplimiento de asignación'
        }

        else {
            description_chart = this.ddlGrafica.text
        }

        const date_ini = this.dtpFechaIniChart.value === null ? '' : this.dtpFechaIniChart.value.getFullYear() + '' + (this.dtpFechaIniChart.value.getMonth() < 9 ? '0' + (this.dtpFechaIniChart.value.getMonth() + 1) : (this.dtpFechaIniChart.value.getMonth() + 1)) + '' + (this.dtpFechaIniChart.value.getDate() <= 9 ? '0' + this.dtpFechaIniChart.value.getDate() : this.dtpFechaIniChart.value.getDate())
        const date_fin = this.dtpFechaFinChart.value === null ? '' : this.dtpFechaFinChart.value.getFullYear() + '' + (this.dtpFechaFinChart.value.getMonth() < 9 ? '0' + (this.dtpFechaFinChart.value.getMonth() + 1) : (this.dtpFechaFinChart.value.getMonth() + 1)) + '' + (this.dtpFechaFinChart.value.getDate() <= 9 ? '0' + this.dtpFechaFinChart.value.getDate() : this.dtpFechaFinChart.value.getDate())
        this.chartInstance.export('PDF', `${date_ini}_${date_fin}_${description_chart}`);
    }

    chart_export2 = (e) => {
        e.preventDefault()
        e.stopPropagation()

        let description_chart = ''

        if (this.ddlGrafica.value == 3) {
            description_chart = 'Tiempo promedio de arribo'
        }

        else if (this.ddlGrafica.value == 5) {
            description_chart = 'Porcentaje de cumplimiento de arribo'
        }

        else {
            description_chart = this.ddlGrafica.text
        }

        const date_ini = this.dtpFechaIniChart.value === null ? '' : this.dtpFechaIniChart.value.getFullYear() + '' + (this.dtpFechaIniChart.value.getMonth() < 9 ? '0' + (this.dtpFechaIniChart.value.getMonth() + 1) : (this.dtpFechaIniChart.value.getMonth() + 1)) + '' + (this.dtpFechaIniChart.value.getDate() <= 9 ? '0' + this.dtpFechaIniChart.value.getDate() : this.dtpFechaIniChart.value.getDate())
        const date_fin = this.dtpFechaFinChart.value === null ? '' : this.dtpFechaFinChart.value.getFullYear() + '' + (this.dtpFechaFinChart.value.getMonth() < 9 ? '0' + (this.dtpFechaFinChart.value.getMonth() + 1) : (this.dtpFechaFinChart.value.getMonth() + 1)) + '' + (this.dtpFechaFinChart.value.getDate() <= 9 ? '0' + this.dtpFechaFinChart.value.getDate() : this.dtpFechaFinChart.value.getDate())
        this.chartInstance2.export('PDF', `${date_ini}_${date_fin}_${description_chart}`);
    }

    pivot_export = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (this.pivotInstance)


            this.pivotInstance.excelExport()
    }

    pivot_export_tiempo_asignacion = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (this.pivotInstanceTiempoAsignacion)


            this.pivotInstanceTiempoAsignacion.excelExport()
    }

    pivot_export_tiempo_arribo = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (this.pivotInstanceTiempoArribo)


            this.pivotInstanceTiempoArribo.excelExport()
    }

    afterPopulate = (e) => {
        if (this.fieldlistObj && this.pivotInstance) {
            this.fieldlistObj.updateView(this.pivotInstance);
        }
    }
    afterPopulateTiempoAsignacion = (e) => {
        if (this.fieldlistObjTiempoAsignacion && this.pivotInstanceTiempoAsignacion) {
            this.fieldlistObjTiempoAsignacion.updateView(this.pivotInstanceTiempoAsignacion);
        }
    }
    afterPopulateTiempoArribo = (e) => {
        if (this.fieldlistObjTiempoArribo && this.pivotInstanceTiempoArribo) {
            this.fieldlistObjTiempoArribo.updateView(this.pivotInstanceTiempoArribo);
        }
    }

    afterPivotPopulate = (e) => {
        if (this.fieldlistObj && this.pivotInstance) {
            this.fieldlistObj.update(this.pivotInstance);
        }

    }
    afterPivotPopulateTiempoAsignacion = (e) => {
        if (this.fieldlistObjTiempoAsignacion && this.pivotInstanceTiempoAsignacion) {
            this.fieldlistObjTiempoAsignacion.update(this.pivotInstanceTiempoAsignacion);
        }

    }
    afterPivotPopulateTiempoArribo = (e) => {
        if (this.fieldlistObjTiempoArribo && this.pivotInstanceTiempoArribo) {
            this.fieldlistObjTiempoArribo.update(this.pivotInstanceTiempoArribo);
        }

    }

    rendereComplete = () => {
        this.fieldlistObj.updateView(this.pivotInstance);
    }
    rendereCompleteTiempoAsignacion = () => {
        this.fieldlistObjTiempoAsignacion.updateView(this.pivotInstanceTiempoAsignacion);
    }
    rendereCompleteTiempoArribo = () => {
        this.fieldlistObjTiempoArribo.updateView(this.pivotInstanceTiempoArribo);
    }

    pivot_drill = (e) => {
        this.fieldlistObj.updateView(this.pivotInstance);
    }
    pivot_drill_tiempo_asignacion = (e) => {
        this.fieldlistObjTiempoAsignacion.updateView(this.pivotInstanceTiempoAsignacion);
    }
    pivot_drill_tiempo_arribo = (e) => {
        this.fieldlistObjTiempoArribo.updateView(this.pivotInstanceTiempoArribo);
    }
    //#endregion

    render() {
        return (
            <div className="row">
                <style>
                    {CHART_CSS}
                </style>
                <form id="formGraficas" name="formGraficas" onSubmit={this.handleSubmitCharts} className="col s12">
                    <div className="row">
                        <div className="col s-12 m12">
                            <DropDownListComponent id="ddlGrafica" name="ddlGrafica" ref={p => this.ddlGrafica = p} value={this.state.type} placeholder="Gráfica" dataSource={this.graficas_data} fields={{ text: 'descripcion', value: 'id' }}></DropDownListComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s6 m6">
                            <DatePickerComponent id="dtpFechaIniChart" name="dtpFechaIniChart" ref={p => this.dtpFechaIniChart = p} allowEdit={false} openOnFocus={true} placeholder="Desde" format="yyyy-MM-dd" data-msg-containerid="dtpFechaIniChartError"></DatePickerComponent>
                            <div id="dtpFechaIniChartError" />
                        </div>
                        <div className="input-field col s6 m6">
                            <DatePickerComponent id="dtpFechaFinChart" name="dtpFechaFinChart" ref={p => this.dtpFechaFinChart = p} allowEdit={false} openOnFocus={true} placeholder="Hasta" format="yyyy-MM-dd" data-msg-containerid="dtpFechaFinChartError"></DatePickerComponent>
                            <div id="dtpFechaFinChartError" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s-12 m6">
                            <label>Cuenta</label>
                            <MultiSelectComponent ref={(ms) => { this.ddlAccountChart = ms; }} changeOnBlur={false} dataSource={this.state.accounts}
                                fields={{ text: 'NOMBRE', value: 'IDCUENTA' }} placeholder="Selecciona una opción" mode="CheckBox" showSelectAll={true}
                                showDropDownIcon={true} filterBarPlaceholder="Buscar cuenta" popupHeight="350px" change={this.ddlAccountChart_change} removed={this.ddlAccountChart_removed}>
                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                        </div>
                        <div className="col s-12 m6">
                            <label>Plan</label>
                            <MultiSelectComponent ref={(ms) => { this.ddlPlanChart = ms; }} changeOnBlur={false} dataSource={this.state.plans_chart}
                                fields={{ text: 'NOMBRE', value: 'IDPROGRAMA' }} placeholder="Selecciona una opción" mode="CheckBox" showSelectAll={true}
                                showDropDownIcon={true} filterBarPlaceholder="Buscar plan" popupHeight="350px">
                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s-12 m6">
                            <label>Estado</label>
                            <MultiSelectComponent ref={(ms) => { this.ddlStateChart = ms; }} changeOnBlur={false} dataSource={this.state.states}
                                fields={{ text: 'DESCRIPCION', value: 'ID' }} placeholder="Selecciona una opción" mode="CheckBox" showSelectAll={true}
                                showDropDownIcon={true} filterBarPlaceholder="Buscar estado" popupHeight="350px">
                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                        </div>
                    </div>
                    {this.state.showPreloader &&
                        <Preloader color="yellownee" />
                    }
                    <div className="row">
                        <div className="col s12 m6 push-m3">
                            <br />
                            <button class="waves-effect waves-light btn" style={{ width: '100%' }}><i class="material-icons right">search</i>Buscar</button>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col s-12 m6">
                            <label>Mes</label>
                            <DatePickerComponent id="dtpMonthChart" start="Decade" depth="Month" placeholder="Ingrese el mes" />

                        </div>
                        <div className="col s-12 m6">
                            <label>Estado</label>
                            <MultiSelectComponent ref={(ms) => { this.ddlMonthChart = ms; }} changeOnBlur={false} dataSource={this.state.states}
                                fields={{ text: 'DESCRIPCION', value: 'ID' }} placeholder="Selecciona una opción" mode="CheckBox" showSelectAll={true}
                                showDropDownIcon={true} filterBarPlaceholder="Buscar plan" popupHeight="350px">
                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col s12 m12" style={{ width: '100%' }}>
                            {this.state.data_chart != null && this.ddlGrafica && this.ddlGrafica.value === 1 &&
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col s12 m4">
                                            <button onClick={this.chart_export} class="waves-effect waves-light btn">Exportar&nbsp;&nbsp;<img src={pdf} alt="" className="img_accordion" /></button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <ChartComponent loaded={this.chart_onLoaded} titleStyle={this.titlestyle} ref={(chart) => { this.chartInstance = chart; }} height='600px' style={{ "width": "100%" }} id='charts' center={{ x: '100%', y: '100%' }} enableAnimation={true}
                                                primaryXAxis={{ valueType: 'Category', fill: '#3762B0', interval: 1, majorGridLines: { width: 0 }, labelIntersectAction: 'Rotate90' }}
                                                primaryYAxis={{ visible:false, majorGridLines: { width: 0 } }}
                                                chartArea={{ border: { width: 0 } }}
                                                tooltip={{ enable: true, format: '${point.tooltip}: ${point.y}' }}
                                                title='Distribución de asistencias por estado'>
                                                <Inject services={[ColumnSeries, Legend, Tooltip, Export, DataLabel, Category]} />
                                                <SeriesCollectionDirective>
                                                    <SeriesDirective animation={this.animation} dataSource={this.state.data_chart} tooltipMappingName="ESTADO" xName='ESTADO' yName='ASISTENCIAS' name='Asistencias' type="Column"
                                                        marker={{ dataLabel: { visible: true, position: 'Outer', font: { fontWeight: '600', color: '#3762B0' } } }} fill='#3762B0' opacity={0.8} />
                                                </SeriesCollectionDirective>
                                            </ChartComponent>
                                            <svg style={{ height: '0' }}>
                                                <defs>
                                                    <linearGradient id="gradient-chart-line" x1="0" x2="0" y1="0" y2="1">
                                                        <stop offset="0" />
                                                        <stop offset="1" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {this.state.data_chart != null && this.ddlGrafica && this.ddlGrafica.value === 2 &&
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <button onClick={this.chart_export} class="waves-effect waves-light btn">Exportar&nbsp;&nbsp;<img src={pdf} alt="" className="img_accordion" /></button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <ChartComponent loaded={this.chart_onLoaded} titleStyle={this.titlestyle} ref={(chart) => { this.chartInstance = chart; }} height='1000px' style={{ "width": "100%" }} id='charts' center={{ x: '100%', y: '100%' }}
                                                primaryXAxis={{ valueType: 'Category', fill: '#3762B0', interval: 1, majorGridLines: { width: 0 }, labelIntersectAction: 'Rotate90' }}
                                                primaryYAxis={{ visible: false, majorGridLines: { width: 0 } }}
                                                chartArea={{ border: { width: 0 } }}
                                                tooltip={{ enable: true }}
                                                title='Distribución por tipo de asistencias'>
                                                <Inject services={[LineSeries, Legend, DataLabel, Export, Tooltip, Category]} />
                                                <SeriesCollectionDirective>
                                                    <SeriesDirective animation={this.animation} width={2} dataSource={this.state.data_chart} tooltipMappingName="MES" xName='TIPO_ASISTENCIA' yName='ASISTENCIAS' name='Asistencias'
                                                        marker={{ dataLabel: { visible: true, position: 'Top', font: { color: "#3762B0", fontWeight: "600" } }, visible: true, height: 10, width: 10, border: { width: 2, color: '#3762B0' } }} type='Line' fill='#3762B0'>
                                                    </SeriesDirective>
                                                </SeriesCollectionDirective>
                                            </ChartComponent>
                                            <svg style={{ height: '0' }}>
                                                <defs>
                                                    <linearGradient id="gradient-chart-line" x1="0" x2="0" y1="0" y2="1">
                                                        <stop offset="0" />
                                                        <stop offset="1" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </React.Fragment>

                            }
                            {this.state.data_chart != null && this.ddlGrafica && this.ddlGrafica.value === 3 &&
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <button onClick={this.chart_export} class="waves-effect waves-light btn">Exportar&nbsp;&nbsp;<img src={pdf} alt="" className="img_accordion" /></button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <ChartComponent titleStyle={this.titlestyle} ref={(chart) => { this.chartInstance = chart; }} height='600px' style={{ "height": "100%", "width": "100%" }} id='charts' center={{ x: '100%', y: '100%' }}
                                                primaryXAxis={{ visible: false, valueType: 'Category', majorGridLines: { width: 0 } }}
                                                primaryYAxis={{ valueType: 'Double', interval: 1 }}
                                                chartArea={{ border: { width: 0 } }}
                                                tooltip={{ enable: true, format: '${point.y}: ${point.tooltip}' }}
                                                title='Tiempos promedio de asignacion'
                                                isTransposed={true}
                                            >
                                                <Inject services={[ColumnSeries, LineSeries, Legend, Export, DataLabel, Tooltip, DateTime, Category]} />
                                                <SeriesCollectionDirective>
                                                    {/* <SeriesDirective animation={this.animation} width={2} dataSource={this.state.data_chart} tooltipMappingName="TIEMPO_ASIGNACION_L" xName='MES' yName='TIEMPO_ASIGNACION_L'
                                                         type='Column' fill='url(#gradient-chart-line)'>
                                                    </SeriesDirective> */}
                                                    <SeriesDirective animation={this.animation} width={2} dataSource={this.state.data_chart} tooltipMappingName='TIEMPO_ASIGNACION_L' xName='TIEMPO_ASIGNACION_L' yName='MES' name='Local'
                                                        marker={{ dataLabel: { name: 'TIEMPO_ASIGNACION_L', visible: true, position: 'Top', font: { color: "#3762B0", fontWeight: "600" } }, visible: true, height: 10, width: 10, border: { width: 2, color: '#3762B0' } }} type='Line' fill='#3762B0'>
                                                    </SeriesDirective>
                                                    <SeriesDirective animation={this.animation} width={2} dataSource={this.state.data_chart} tooltipMappingName='TIEMPO_ASIGNACION_F' xName='TIEMPO_ASIGNACION_F' yName='MES' name='Foraneo'
                                                        marker={{ dataLabel: { name: 'TIEMPO_ASIGNACION_F', visible: true, position: 'Top', font: { color: "#26A69A", fontWeight: "600" } }, visible: true, height: 10, width: 10, border: { width: 2, color: '#26A69A' } }} type='Line' fill='#26A69A'>
                                                    </SeriesDirective>
                                                </SeriesCollectionDirective>
                                            </ChartComponent>

                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <button onClick={this.chart_export2} class="waves-effect waves-light btn">Exportar&nbsp;&nbsp;<img src={pdf} alt="" className="img_accordion" /></button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <ChartComponent loaded={this.chart_onLoaded} height='600px' titleStyle={this.titlestyle} ref={(chart) => { this.chartInstance2 = chart; }} style={{ "height": "100%", "width": "100%" }} id='charts2' center={{ x: '100%', y: '100%' }}
                                                primaryXAxis={{ visible: false, valueType: 'Category', majorGridLines: { width: 0 } }}
                                                primaryYAxis={{ valueType: 'Double', interval: 1 }}
                                                chartArea={{ border: { width: 0 } }}
                                                tooltip={{ enable: true, format: '${point.y}: ${point.tooltip}' }}
                                                title='Tiempos promedio de arribo'
                                                isTransposed={true}
                                            >
                                                <Inject services={[LineSeries, Legend, DataLabel, Tooltip, Export, DateTimeCategory, Category]} />
                                                <SeriesCollectionDirective>
                                                    <SeriesDirective animation={this.animation} width={2} dataSource={this.state.data_chart_secondary} tooltipMappingName='TIEMPO_ARRIBO_L' xName='TIEMPO_ARRIBO_L' yName='MES' name='Local'
                                                        marker={{ dataLabel: { name: 'TIEMPO_ARRIBO_L', visible: true, position: 'Top', font: { color: "#3762B0", fontWeight: "600" } }, visible: true, height: 10, width: 10, border: { width: 2, color: '#3762B0' } }} type='Line' fill='#3762B0'>
                                                    </SeriesDirective>
                                                    <SeriesDirective animation={this.animation} width={2} dataSource={this.state.data_chart_secondary} tooltipMappingName='TIEMPO_ARRIBO_F' xName='TIEMPO_ARRIBO_F' yName='MES' name='Foraneo'
                                                        marker={{ dataLabel: { name: 'TIEMPO_ARRIBO_F', visible: true, position: 'Bottom', font: { color: "#26A69A", fontWeight: "600" } }, visible: true, height: 10, width: 10, border: { width: 2, color: '#26A69A' } }} type='Line' fill='#26A69A'>
                                                    </SeriesDirective>
                                                </SeriesCollectionDirective>
                                            </ChartComponent>
                                        </div>
                                    </div>
                                    <svg style={{ height: '0' }}>
                                        <defs>
                                            <linearGradient id="gradient-chart-line" x1="0" x2="0" y1="0" y2="1">
                                                <stop offset="0" />
                                                <stop offset="1" />
                                            </linearGradient>
                                            <linearGradient id="gradient-chart-line-2" x1="0" x2="0" y1="0" y2="1">
                                                <stop offset="0" />
                                                <stop offset="1" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </React.Fragment>
                            }
                            {this.state.data_chart != null && this.ddlGrafica && this.ddlGrafica.value === 5 &&
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <button onClick={this.chart_export} class="waves-effect waves-light btn">Exportar&nbsp;&nbsp;<img src={pdf} alt="" className="img_accordion" /></button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <ChartComponent titleStyle={this.titlestyle} height='600px' ref={(chart) => { this.chartInstance = chart; }} style={{ "height": "100%", "width": "100%" }} id='charts' center={{ x: '100%', y: '100%' }}
                                                primaryXAxis={{ valueType: 'Category', fill: '#7F46AA', interval: 1, majorGridLines: { width: 0 } }}
                                                primaryYAxis={{ visible: false, valueType: 'Double', labelFormat: 'p2', maximum: 1, rangePadding: 'Auto' }}
                                                chartArea={{ border: { width: 0 } }}
                                                tooltip={{ enable: true, format: '${point.x}: ${point.tooltip}' }}

                                                title='Porcentaje de cumplimiento tiempo de asignacion'
                                            >
                                                <Inject services={[LineSeries, Legend, DataLabel, Tooltip, Export, Category]} />
                                                <SeriesCollectionDirective>
                                                    <SeriesDirective animation={this.animation} width={2} dataSource={this.state.data_chart} tooltipMappingName='TIEMPO_CUMPLIMIENTO_ASIGNACION_L' xName='MES' yName='TIEMPO_CUMPLIMIENTO_ASIGNACION_L' name='Local'
                                                        marker={{ shape: 'Diamond', dataLabel: { visible: true, position: 'Top', font: { color: "#2C4E8A", fontWeight: "600" } }, visible: true, height: 10, width: 10, border: { width: 2, color: '#7F46AA' } }} type='Line' fill='#7F46AA'>
                                                    </SeriesDirective>
                                                    <SeriesDirective animation={this.animation} width={2} dataSource={this.state.data_chart} tooltipMappingName='TIEMPO_CUMPLIMIENTO_ASIGNACION_F' xName='MES' yName='TIEMPO_CUMPLIMIENTO_ASIGNACION_F' name='Foraneo'
                                                        marker={{ shape: 'Diamond', dataLabel: { visible: true, position: 'Bottom', font: { color: "#26A69A", fontWeight: "600" } }, visible: true, height: 10, width: 10, border: { width: 2, color: '#26A69A' } }} type='Line' fill='#26A69A'>
                                                    </SeriesDirective>
                                                </SeriesCollectionDirective>
                                            </ChartComponent>

                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <button onClick={this.chart_export2} class="waves-effect waves-light btn">Exportar&nbsp;&nbsp;<img src={pdf} alt="" className="img_accordion" /></button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <ChartComponent loaded={this.chart_onLoaded} height='600px' titleStyle={this.titlestyle} ref={(chart) => { this.chartInstance2 = chart; }} style={{ "height": "100%", "width": "100%" }} id='charts2' center={{ x: '100%', y: '100%' }}
                                                primaryXAxis={{ valueType: 'Category', fill: '#7F46AA', interval: 1, majorGridLines: { width: 0 } }}
                                                primaryYAxis={{ visible: false, valueType: 'Double', labelFormat: 'p2', maximum: 1, rangePadding: 'Auto' }}
                                                chartArea={{ border: { width: 0 } }}
                                                tooltip={{ enable: true, format: '${point.x}: ${point.tooltip}' }}
                                                title='Porcentaje de cumplimiento tiempo de arribo'
                                            >
                                                <Inject services={[LineSeries, Legend, DataLabel, Tooltip, Export, Category]} />
                                                <SeriesCollectionDirective>
                                                    <SeriesDirective animation={this.animation} width={2} dataSource={this.state.data_chart_secondary} tooltipMappingName='TIEMPO_CUMPLIMIENTO_ARRIBO_L' xName='MES' yName='TIEMPO_CUMPLIMIENTO_ARRIBO_L' name='Local'
                                                        marker={{ shape: 'Diamond', dataLabel: { margin:{
                                                            left:5,
                                                            right:5,
                                                            top:5,
                                                            bottom:5
                                                        }, visible: true, position: 'Top', font: { color: "#2C4E8A", fontWeight: "600" } }, visible: true, height: 10, width: 10, border: { width: 2, color: '#7F46AA' } }} type='Line' fill='#7F46AA'>
                                                    </SeriesDirective>
                                                    <SeriesDirective animation={this.animation} width={2} dataSource={this.state.data_chart_secondary} tooltipMappingName='TIEMPO_CUMPLIMIENTO_ARRIBO_F' xName='MES' yName='TIEMPO_CUMPLIMIENTO_ARRIBO_F' name='Foraneo'
                                                        marker={{ shape: 'Diamond', dataLabel: { margin:{
                                                            left:5,
                                                            right:5,
                                                            top:5,
                                                            bottom:5
                                                        }, visible: true, position: 'Bottom', font: { color: "#26A69A", fontWeight: "600" } }, visible: true, height: 10, width: 10, border: { width: 2, color: '#26A69A' } }} type='Line' fill='#26A69A'>
                                                    </SeriesDirective>
                                                </SeriesCollectionDirective>
                                            </ChartComponent>
                                        </div>
                                    </div>
                                    <svg style={{ height: '0' }}>
                                        <defs>
                                            <linearGradient id="gradient-chart-line" x1="0" x2="0" y1="0" y2="1">
                                                <stop offset="0" />
                                                <stop offset="1" />
                                            </linearGradient>
                                            <linearGradient id="gradient-chart-line-2" x1="0" x2="0" y1="0" y2="1">
                                                <stop offset="0" />
                                                <stop offset="1" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </React.Fragment>
                            }
                            {this.state.data_chart != null && this.ddlGrafica && this.ddlGrafica.value === 7 &&
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <button onClick={this.pivot_export_tiempo_asignacion} class="waves-effect waves-light btn">Exportar&nbsp;&nbsp;<img src={excel} alt="" className="img_accordion" /></button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <PivotViewComponent drill={this.pivot_drill_tiempo_asignacion} id='pivotTableTiempoAsignacion' locale='es-MX' dataSourceSettings={{
                                                dataSource: this.state.data_chart,
                                                expandAll: false,
                                                filters: [],
                                                columns: [{ name: 'TIEMPO_ARRIBO', caption: 'Tiempo de Arribo' }],
                                                formatSettings: [{ name: 'CANTIDAD', format: 'N0' }, { name: 'PORCENTAJE', format: 'P2' }],
                                                rows: [{ name: 'TIPO_ARRIBO', caption: 'Tipo de Arribo' }],
                                                values: [{ name: 'CANTIDAD', caption: 'Cantidad' },{ name: 'PORCENTAJE', caption: 'Porcentaje de Arribo' }]
                                            }} enginePopulated={this.afterPivotPopulateTiempoAsignacion.bind(this)} ref={d => this.pivotInstanceTiempoAsignacion = d} allowExcelExport={true} showFieldList={false} showGroupingBar={true} height={500} width={'100%'}>
                                                <Inject services={[FieldList, GroupingBar, VirtualScroll]} />
                                            </PivotViewComponent>
                                            <PivotFieldListComponent allowDeferLayoutUpdate={true} id='PivotFieldListTiempoAsignacion' ref={d => this.fieldlistObjTiempoAsignacion = d} enginePopulated={this.afterPopulateTiempoAsignacion.bind(this)} dataSourceSettings={{
                                                dataSource: this.state.data_chart,
                                                expandAll: false,
                                                filters: [],
                                                columns: [{ name: 'TIEMPO_ARRIBO', caption: 'Tiempo de Arribo' }],
                                                formatSettings: [{ name: 'CANTIDAD', format: 'N0' }, { name: 'PORCENTAJE', format: 'P2' }],
                                                rows: [{ name: 'TIPO_ARRIBO', caption: 'Tipo de Arribo' },{ name: 'PORCENTAJE', caption: 'Porcentaje de Arribo' }],
                                                values: [{ name: 'CANTIDAD', caption: 'Cantidad' }]
                                            }} renderMode={"Popup"} allowCalculatedField={true}><Inject services={[CalculatedField]} /></PivotFieldListComponent>
                                            </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <button onClick={this.pivot_export_tiempo_arribo} class="waves-effect waves-light btn">Exportar&nbsp;&nbsp;<img src={excel} alt="" className="img_accordion" /></button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <PivotViewComponent drill={this.pivot_drill_tiempo_arribo} id='pivotTable' locale='es-MX' dataSourceSettings={{
                                                dataSource: this.state.data_chart_secondary,
                                                expandAll: false,
                                                filters: [],
                                                columns: [{ name: 'TIEMPO_ARRIBO', caption: 'Tiempo de Arribo' }],
                                                formatSettings: [{ name: 'CANTIDAD', format: 'N0' }, { name: 'PORCENTAJE', format: 'P2' }],
                                                rows: [{ name: 'ESTADO', caption: 'Estado' }, { name: 'TIPO_ARRIBO', caption: 'Tipo de Arribo' }],
                                                values: [{ name: 'CANTIDAD', caption: 'Cantidad' }, { name: 'PORCENTAJE', caption: 'Porcentaje de Arribo' }]
                                            }} enginePopulated={this.afterPivotPopulateTiempoArribo.bind(this)} ref={d => this.pivotInstanceTiempoArribo = d} allowExcelExport={true} showFieldList={false} showGroupingBar={true} height={500} width={'100%'}>
                                                <Inject services={[FieldList, GroupingBar, VirtualScroll]} />
                                            </PivotViewComponent>
                                            <PivotFieldListComponent allowDeferLayoutUpdate={true} id='PivotFieldList' ref={d => this.fieldlistObj = d} enginePopulated={this.afterPopulateTiempoArribo.bind(this)} dataSourceSettings={{
                                                dataSource: this.state.data_chart,
                                                expandAll: false,
                                                filters: [],
                                                columns: [{ name: 'TIEMPO_ARRIBO', caption: 'Tiempo de Arribo' }],
                                                formatSettings: [{ name: 'CANTIDAD', format: 'N0' }, { name: 'PORCENTAJE', format: 'P2' }],
                                                rows: [{ name: 'ESTADO', caption: 'Estado' }, { name: 'TIPO_ARRIBO', caption: 'Tipo de Arribo' }],
                                                values: [{ name: 'CANTIDAD', caption: 'Cantidad' }, { name: 'PORCENTAJE', caption: 'Porcentaje de Arribo' }]
                                            }} renderMode={"Popup"} allowCalculatedField={true}><Inject services={[CalculatedField]} /></PivotFieldListComponent>
                                            </div>
                                    </div>
                                </React.Fragment>
                            }
                            {this.state.data_chart != null && this.ddlGrafica && this.ddlGrafica.value === 9 &&
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <button onClick={this.pivot_export} class="waves-effect waves-light btn">Exportar&nbsp;&nbsp;<img src={excel} alt="" className="img_accordion" /></button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12 m12">
                                            <PivotViewComponent drill={this.pivot_drill} id='pivotTable' locale='es-MX' dataSourceSettings={{
                                                dataSource: this.state.data_chart,
                                                expandAll: false,
                                                filters: [],
                                                columns: [{ name: 'TIEMPO_ARRIBO', caption: 'Tiempo de Arribo' }],
                                                formatSettings: [{ name: 'CANTIDAD', format: 'N0' }, { name: 'PORCENTAJE_ARRIBO', format: 'P2' }],
                                                rows: [{ name: 'ESTADO', caption: 'Estado' }, { name: 'TIPO_ARRIBO', caption: 'Tipo de Arribo' }],
                                                values: [{ name: 'CANTIDAD', caption: 'Cantidad' }, { name: 'PORCENTAJE_ARRIBO', caption: 'Porcentaje de Arribo' }]
                                            }} enginePopulated={this.afterPivotPopulate.bind(this)} ref={d => this.pivotInstance = d} allowExcelExport={true} showFieldList={true} showGroupingBar={true} height={500} width={'100%'}>
                                                <Inject services={[FieldList, GroupingBar, VirtualScroll]} />
                                            </PivotViewComponent>
                                            <PivotFieldListComponent allowDeferLayoutUpdate={true} id='PivotFieldList' ref={d => this.fieldlistObj = d} enginePopulated={this.afterPopulate.bind(this)} dataSourceSettings={{
                                                dataSource: this.state.data_chart,
                                                expandAll: false,
                                                filters: [],
                                                columns: [{ name: 'TIEMPO_ARRIBO', caption: 'Tiempo de Arribo' }],
                                                formatSettings: [{ name: 'CANTIDAD', format: 'N0' }, { name: 'PORCENTAJE_ARRIBO', format: 'P2' }],
                                                rows: [{ name: 'ESTADO', caption: 'Estado' }, { name: 'TIPO_ARRIBO', caption: 'Tipo de Arribo' }],
                                                values: [{ name: 'CANTIDAD', caption: 'Cantidad' }, { name: 'PORCENTAJE_ARRIBO', caption: 'Porcentaje de Arribo' }]
                                            }} renderMode={"Popup"} allowCalculatedField={true}><Inject services={[CalculatedField]} /></PivotFieldListComponent>
                                            </div>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </form>
            </div >)
    }

}