import React from 'react'
import Preloader from '../Loader/Preloader'
import M from 'materialize-css/dist/js/materialize.min.js'
import person from '../../img/person.png'

export default class DepartmentModal extends React.Component {

    constructor(props){
        super(props)
        console.log(this.props)
        this.state = {
            name: this.props.department.name,
            departments: this.props.departments,
            belongsToId: this.props.department.belongs_to === null ? '' : this.props.department.belongs_to,
            belongsToName: this.props.belongsTo === null ? '' : this.props.belongsTo,
            bossId: this.props.department.bossId,
            bossName: this.props.department.bossName,
            users: this.props.users,
            showPreloader: this.props.showPreloader,
            showChip: this.props.department.bossName
        }
    }

    componentDidMount(){
        M.updateTextFields()
        const selects = document.querySelectorAll('select')
        M.FormSelect.init(selects)
        const autocomplete = document.getElementById('bossId')
        M.Autocomplete.init(autocomplete,
            {
                data: this.state.users.names,
                onAutocomplete: complete => {
                    const filtered = this.state.users.data.filter(user => user.name === complete)
                    this.setState({bossId: filtered[0].id, bossName: filtered[0].name, showChip: true})
                }
            }
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.setState({
                ...this.state,
                name: this.props.department.name,
                departments: this.props.departments,
                belongsToId: this.props.department.belongs_to,
                belongsToName: this.props.belongsTo,
                bossId: this.props.department.bossId,
                bossName: this.props.department.bossName,
                showPreloader: this.props.showPreloader,
                chip: this.props.department.bossName,
                showChip: this.props.department.bossName
            })
        }
        const selects = document.querySelectorAll('select')
        M.FormSelect.init(selects)
    }

    handleChange = e => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            [e.target.id]: value
        })
    }

    handleSubmit = e => {
        e.preventDefault()
        e.stopPropagation()
        this.props.callbackSubmit({
            id: this.props.department.id,
            name: this.state.name,
            belongsTo: this.state.belongsToId,
            bossId: this.state.bossId
        })
    }

    handleClose = () => {
        this.props.callbackClose('modalEditDepartment')
    }

    handleCloseChip = () => {
        this.setState({showChip: null, bossId: null})
    }

    render(){
        return(
            <div id="modalEditDepartment" className="modal modal-large deep-blue-bg">
                {this.state.showPreloader ?
                    <Preloader color="yellownee" />
                    :
                    ''
                }
                <i className="material-icons close-modal-btn" onClick={this.handleClose}>close</i>
                <div className="modal-content">
                    <div className="row">
                        <form onSubmit={this.handleSubmit} className="col s12">
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <input placeholder="Nombre" className="validate" value={this.state.name}  onChange={this.handleChange} id="name" type="text" required />
                                    <label className="blue-sky" htmlFor="name">Nombre</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12">
                                    <input placeholder="Jefe de departamento" className="validate autocomplete" id="bossId" type="text" />
                                    <label className="blue-sky" htmlFor="name">Jefe de departamento</label>
                                </div>
                                <div className="col s12">
                                    {this.state.showChip !== null &&
                                        <div className="chip">
                                            <img src={person} alt="person" />
                                            {this.state.bossName}
                                            <i className="close material-icons" onClick={this.handleCloseChip}>close</i>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12">
                                    <select id="belongsToId" onChange={this.handleChange} value={this.state.belongsToId}>
                                        <option value="" disabled>Selecciona una opción</option>
                                        {
                                            this.state.departments.map((dto, key) => {
                                                return(
                                                    <option key={key} value={key}>{dto}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label>Pertenece a</label>
                                </div>
                            </div>
                            <div>
                                <button className="btn waves-effect waves-light blue-gradient" type="submit" style={{width: '100%'}}>Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}