import React from 'react'
import UrlCard from '../Cards/UrlCard'
import AddFolios from './AddFolios'
import ShowFolios from './ShowFolios'
import PanelCards from '../Cards/PanelCards'
import AssignInfo from './AssignInfo'
import {verify, refresh} from '../../Functions/verify'
import {companiesNames} from '../../Functions/companies'

const cards = [
    {
        title: 'Agregar Folios',
        text: 'Cargar un nuevo lote de folios agregado a una compañía.',
        button: 'Agregar',
        section: 'add'
    },
    {
        title: 'Ver Folios',
        text: 'Ver, buscar, descargar y enviar folios creados previamente',
        button: 'Ver folios',
        section: 'show'
    },
    {
        title: 'Asignar información',
        text: 'Asignar información del afiliado y asegurado al que pertenece un folio',
        button: 'Asignar',
        section: 'assign'
    }
]

export default class Foliator extends React.Component {

    constructor(){
        super()
        this.state = {
            show: 'cards',
            companies: null
        }
    }

    componentDidMount(){
        this.check()
        .then(auth => {
            console.log(auth)
            if(!auth){
                window.location.href = '/'
            } else {
                companiesNames()
                .then(names => {
                    this.setState({companies: names})
                })
            }
        })
    }

    check = async () => {
        const auth = await verify()
        return auth
    }

    getSection = section => {
        this.setState({show: section})
    }

    handleClickBack = () => {
        this.setState({show: 'cards'})
    }

    render(){
        return(
            <div className="content">
                <div className="row">
                    {this.state.show === 'cards' &&
                        cards.map(card => {
                            return(
                                <UrlCard 
                                    key={card.title}
                                    title={card.title} 
                                    text={card.text}  
                                    button={card.button} 
                                    section={card.section}
                                    callback={this.getSection.bind(this)}
                                />
                            )
                        })
                    }
                </div>
                {this.state.show === 'add' &&
                    (localStorage.getItem('level') === '1' ?
                        <React.Fragment>
                            <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                                <i className="material-icons left">arrow_back</i>Regresar
                            </button>
                            <AddFolios
                                companies={this.state.companies}
                            />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                                <i className="material-icons left">arrow_back</i>Regresar
                            </button>
                            <div className="row">
                                <div className="col s12 m4 push-m4">
                                <PanelCards 
                                    color="deep-blue-bg"
                                    title="Oops!"
                                    content="Aún no tienes acceso a esta parte de la aplicación, si necesitas acceso llama a tu administrador."
                                />
                                </div>
                            </div>
                        </React.Fragment>
                    )
                    
                }
                {this.state.show === 'show' &&
                    (localStorage.getItem('level') === '1' ?
                        <React.Fragment>
                            <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                                <i className="material-icons left">arrow_back</i>Regresar
                            </button>
                            <ShowFolios
                                companies={this.state.companies}
                            />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                                <i className="material-icons left">arrow_back</i>Regresar
                            </button>
                            <div className="row">
                                <div className="col s12 m4 push-m4">
                                <PanelCards 
                                    color="deep-blue-bg"
                                    title="Oops!"
                                    content="Aún no tienes acceso a esta parte de la aplicación, si necesitas acceso llama a tu administrador."
                                />
                                </div>
                            </div>
                        </React.Fragment>
                    )
                    
                }
                {this.state.show === 'assign' &&
                    <React.Fragment>
                        <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                            <i className="material-icons left">arrow_back</i>Regresar
                        </button>
                        <AssignInfo />
                    </React.Fragment>
                }
            </div>
        )
    }
}