import React from 'react';
import { request } from '../../services/request';
import { verify } from '../../Functions/verify';
import R from '../../services/routes';
import Pagination from './Pagination';
import Select from '../Inputs/Select';
import ActionButton from '../Buttons/ActionButton';
import M from 'materialize-css/dist/js/materialize.min.js';
import Input from '../Inputs/Input';
import Preloader from '../Loader/Preloader';
import RecordModal from './RecordModal';
import './Bulkload.scss';
import { DatePicker, DatePickerComponent } from '@syncfusion/ej2-react-calendars';

class Bulkload extends React.Component {
  dtpFechaIni: DatePicker | null;
  dtpFechaFin: DatePicker | null;

  type = ['Nuevo', 'Sin cambios', 'Editado', 'Inactivo'];
  blueStatus = ['Registered', 'Complete'];

  constructor() {
    super();
    this.state = {
      data: null,
      showPreloader: true,
      companies: [],
      company: '',
      columns: [],
      column: '',
      search: '',
      record: null,
      isOpened: false,
      opcion: 'A'
    };
  }

  componentDidMount = async () => {
    const auth = await verify();
    if (!auth) {
      localStorage.clear();
      window.location.href = '/';
    }

    const modules = JSON.parse(localStorage.getItem('modules'));
    let variables = [];
    for (let i in modules) {
      if (modules[i].module.indexOf(':') !== -1) {
        variables.push(modules[i].module);
      }
    }

    const response = await request('GET', R.getCompaniesNames, true, true, false);
    let companies = response.companies;
    if (variables.length > 0) {
      const filters = variables.map(variable => {
        const splitted = variable.split(':');
        if (splitted.length > 0) {
          if (splitted[0] === 'Records')
            return splitted[1]
        }
      })
      let filtered = []
      for (let c in response.companies) {
        console.log(response.companies[c])
        if (filters.indexOf(response.companies[c].name) > -1) {
          filtered.push(response.companies[c])
        }
      }
      companies = filtered;
    }

    await this.setState({ companies, showPreloader: false });

    M.AutoInit();
  }

  handleDonwloadFile = async () => {
    await this.setState({ showPreloader: true });
    const { opcion } = this.state;
    const dateini = (this.dtpFechaIni.value !== null) ? this.dtpFechaIni.value.getFullYear() + '-' + (this.dtpFechaIni.value.getMonth() < 9 ? '0' + (this.dtpFechaIni.value.getMonth() + 1) : (this.dtpFechaIni.value.getMonth() + 1)) + '-' + (this.dtpFechaIni.value.getDate() <= 9 ? '0' + this.dtpFechaIni.value.getDate() : this.dtpFechaIni.value.getDate()) : null;
    const datefin = (this.dtpFechaFin.value !== null) ? this.dtpFechaFin.value.getFullYear() + '-' + (this.dtpFechaFin.value.getMonth() < 9 ? '0' + (this.dtpFechaFin.value.getMonth() + 1) : (this.dtpFechaFin.value.getMonth() + 1)) + '-' + (this.dtpFechaFin.value.getDate() <= 9 ? '0' + this.dtpFechaFin.value.getDate() : this.dtpFechaFin.value.getDate()) : null;
    const response = await request('POST', R.exportBlueDiamond, true, false, { opcion, dateini, datefin });
    const authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV
    window.open(`${authenticate}${R.downloadBlueDiamond}/${response.name}`, '_blank')
    await this.setState({ showPreloader: false });
  }

  async handlePaginationClick(url) {
    await this.setState({ showPreloader: true });
    const res = await request('GET', url, false, true, false);
    await this.setState({ data: res.records, columns: res.columns, showPreloader: false });
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'company') {

      this.dtpFechaIni.value = null;
      this.dtpFechaFin.value = null;

      this.setState({
        ...this.state,
        [id]: value,
        column: '',
        search: '',
        opcion: 'A'
      });
    } else {
      this.setState({
        ...this.state,
        [id]: value,
      });
    }
  }

  getRecords = async () => {
    try {
      await this.setState({ showPreloader: true });
      const column = (this.state.column !== '') ? this.state.column : 'null';
      const search = (this.state.search !== '') ? this.state.search : 'null';
      const dateini = (this.dtpFechaIni.value !== null) ? this.dtpFechaIni.value.getFullYear() + '-' + (this.dtpFechaIni.value.getMonth() < 9 ? '0' + (this.dtpFechaIni.value.getMonth() + 1) : (this.dtpFechaIni.value.getMonth() + 1)) + '-' + (this.dtpFechaIni.value.getDate() <= 9 ? '0' + this.dtpFechaIni.value.getDate() : this.dtpFechaIni.value.getDate()) : 'null';
      const datefin = (this.dtpFechaFin.value !== null) ? this.dtpFechaFin.value.getFullYear() + '-' + (this.dtpFechaFin.value.getMonth() < 9 ? '0' + (this.dtpFechaFin.value.getMonth() + 1) : (this.dtpFechaFin.value.getMonth() + 1)) + '-' + (this.dtpFechaFin.value.getDate() <= 9 ? '0' + this.dtpFechaFin.value.getDate() : this.dtpFechaFin.value.getDate()) : 'null';
      const response = await request('GET', `${R.getBulkloadRecords}/${this.state.company}/${search}/${column}/${dateini}/${datefin}`, true, true, false);
      await this.setState({ data: response.records, columns: response.columns, showPreloader: false });
      M.AutoInit();
    } catch (e) {
      console.log(e);
    }
  }

  handleClickRecord = async (id) => {
    try {
      await this.setState({ showPreloader: true })
      const route = this.state.company === 'BLUEDIAMOND' ? R.getBulkloadRecordByIdBlue : R.getBulkloadRecordById;
      const response = await request('GET', `${route}/${id}`, true, true, false);
      const content = (response) ? JSON.parse(response.record.content) : [];
      console.log(content);
      if (this.state.company === 'BLUEDIAMOND') {
        content.Reservation = response.record.reservation;
        content.Email = response.record.email;
        content.Status = this.blueStatus[response.record.status];
        content.FirstName = response.record.first_name;
        content.LastName = response.record.last_name;
        content.BirthDate = response.record.birth_date;
        content.Genre = (response.record.genre === 1) ? 'Female' : 'Male';
        content.Hotel = response.record.hotel;
        content.Country = response.record.country;
        content.Phone = response.record.phone;
        content.CheckIn = response.record.check_in;
        content.CheckOut = response.record.check_out;
        content.UpdatedAt = response.record.updated_at;
      }
      this.setState({ record: content, showPreloader: false, isOpened: true });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { companies, data, columns, showPreloader, isOpened, record } = this.state;
    return (
      <div className="row">
        <div className="col s12">
          <div className="row">
            {showPreloader &&
              <Preloader color="yellownee" />
            }

            <div className={this.state.company === 'BLUEDIAMOND' && this.state.opcion === 'A' ? 'col s3 m3' : 'col s4 m4'}>
              <div style={{ paddingTop: 17 }}></div>
              <Select
                columns="s12 deep-blue"
                id="company"
                handleChange={this.handleChange.bind(this)}
                state={this.state}
                label="Compañía"
              >
                <option value="" selected disabled>Seleccione una opción</option>
                {(companies && companies.length > 0) &&
                  companies.map(company => (
                    <option key={company.id} value={company.name}>{company.name}</option>
                  ))
                }
              </Select>
            </div>
              <div className={this.state.company === 'BLUEDIAMOND' && this.state.opcion === 'A' ? 'col s3 m3' : 'col s4 m4'} style={{ display: this.state.company === 'BLUEDIAMOND' ? '' : 'none' }}>
                <div style={{ paddingTop: 17 }}></div>
                <Select
                  columns="s12 deep-blue"
                  id="opcion"
                  handleChange={this.handleChange.bind(this)}
                  state={this.state}
                  label="Filtrar por"
                >
                  <option value="A" selected>Rango de fechas</option>
                  <option value="B">Checkin</option>
                </Select>
              </div>
                <div className={this.state.company === 'BLUEDIAMOND' && this.state.opcion === 'A' ? 'col s3 m3' : 'col s4 m4'}>
                  <DatePickerComponent id="dtpFechaIni" name="dtpFechaIni" ref={p => this.dtpFechaIni = p} allowEdit={false} openOnFocus={true} placeholder={this.state.opcion === 'B' ? 'Checkin' : 'Fecha Inicio'} format="yyyy-MM-dd" floatLabelType="Auto"></DatePickerComponent>
                </div>
                <div style={{ display: (this.state.opcion === '' || this.state.opcion === 'A') ? '' : 'none'}} className={this.state.company === 'BLUEDIAMOND' && this.state.opcion === 'A' ? 'col s3 m3' : 'col s4 m4'}>
                  <DatePickerComponent id="dtpFechaFin" name="dtpFechaFin" ref={p => this.dtpFechaFin = p} allowEdit={false} openOnFocus={true} placeholder="Fecha Fin" format="yyyy-MM-dd" floatLabelType="Auto"></DatePickerComponent>
                </div>
                
          </div>
          <div className="row">
            {columns.length > 0 &&
              <>
                <Select
                  columns="s12 m4 deep-blue"
                  id="column"
                  handleChange={this.handleChange.bind(this)}
                  state={this.state}
                  label="Filtrar por"
                >
                  <option value="" selected disabled>Seleccione una opción</option>
                  {
                    columns.map(column => (
                      <option key={column} value={column}>{column}</option>
                    ))
                  }
                </Select>
                <Input
                  placeholder="Búsqueda"
                  column="s12 m4"
                  id="search"
                  state={this.state}
                  handleChange={this.handleChange.bind(this)}
                  type="text"
                  label="Búsqueda"
                />
              </>
            }
          </div>
          <div className="row">
            <div className="col s4 m4 push-m2">
              <ActionButton
                color="deep-blue-bg white-text input-field"
                action={this.getRecords}
                fullWidth="true"
                icon="search"
                text="Buscar"
              />
            </div>
            {this.state.company === 'BLUEDIAMOND' &&
              <div className="col s4 m4 push-m2">
                <ActionButton
                  color="deep-blue-bg white-text input-field"
                  action={this.handleDonwloadFile}
                  fullWidth="true"
                  icon="file_download"
                  text="Exportar"
                />
              </div>
            }
          </div>
          {(data) &&
            <>
              <div className="col s12">
                <Pagination
                  state={this.state}
                  handlePaginationClick={this.handlePaginationClick.bind(this)}
                />
              </div>
              <div className="col s12">
                Total de registros: {data.total}
                <table className="selectable-table">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Tipo de carga</th>
                      <th>Fecha de actualización</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.data.map(record => (
                        <tr key={record.id} onClick={() => { this.handleClickRecord(record.id) }}>
                          <td>{record.id_affiliate}</td>
                          <td>{this.type[record.type]}</td>
                          <td>{record.updated_at}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
              <RecordModal title="Registro" isOpened={isOpened} onClose={() => { this.setState({ isOpened: false }) }}>
                {record &&
                  <table>
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(record).map(key => (
                        <tr key={key}>
                          <td className="text-white">{key}</td>
                          <td className="text-white">{record[key]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              </RecordModal>
            </>
          }
        </div>
      </div>
    );
  }
}

export default Bulkload;