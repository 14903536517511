import React from 'react'
import Preloader from '../../Loader/Preloader'
import Pagination from './Pagination'
import List from './List'
import NewSite from './NewSite'
import EditSite from './EditSite'
import M from 'materialize-css/dist/js/materialize.min.js'
import {request} from '../../../services/request'
import R from '../../../services/routes'
import Toast from '../../IziToast/Toast'

export default class ShowSites extends React.Component {

    constructor(){
        super()
        this.state = {
            showPreloader: true,
            sites: null,
            newSite: false,
            editSite: false,
            idToEdit: null,
            siteName: '',
            sitePrivateIp: '', 
            sitePublicIp: '',
            siteDescription: '',
            siteNameEdit: '',
            sitePrivateIpEdit: '', 
            sitePublicIpEdit: '',
            siteDescriptionEdt: '',
        }
    }

    componentDidMount(){
        this.getSites()
    }

    getSites = async () => {
        await this.setState({showPreloader: true})
        const res = await request('GET', R.getSites, true, true, false)
        if(res.success){
            await this.setState({sites: res.sites})
        }
        await this.setState({showPreloader: false})
    }

    getSite = async id => {
        await this.setState({showPreloader: true})
        const res = await request('GET', `${R.getSite}/${id}`, true, true, false)
        if(res.success){
            await this.setState({
                siteNameEdit: res.site.name,
                sitePrivateIpEdit: res.site.private_ip,
                sitePublicIpEdit: res.site.public_ip,
                siteDescriptionEdit: res.site.description,
            })
        } else {
            Toast.showError({
                title: 'Error',
                message: 'Ocurrió un error inesperado',
                position: 'topCenter',
            })
        }
        await this.setState({showPreloader: false})
    }

    handleEdit = async id => {
        await this.getSite(id)
        await this.setState({sites: null, editSite: true, idToEdit: id})
        M.updateTextFields()

    }

    handleUpdateSite = async e => {
        e.preventDefault()
        e.stopPropagation()
        await this.setState({showPreloader: true})
        const data = {
            id: this.state.idToEdit,
            name: this.state.siteNameEdit,
            private_ip: this.state.sitePrivateIpEdit,
            public_ip: this.state.sitePublicIpEdit,
            description: this.state.siteDescriptionEdit,
        }
        const res = await request('PUT', R.updateSite, true, true, JSON.stringify(data))
        if(res.success){
            Toast.showSuccess({
                title: 'Sitio Actualizado',
                message: 'El sitio fue actualizado correctamente',
                position: 'topCenter',
            })
        } else {
            Toast.showError({
                title: 'Error',
                message: 'Ocurrió un error inesperado',
                position: 'topCenter',
            })
        }
        await this.setState({
            showPreloader: false,
            editSite: false,
            idToEdit: null,
            siteNameEdit: '',
            sitePrivateIpEdit: '', 
            sitePublicIpEdit: '',
            siteDescriptionEdt: '',
        })
        this.getSites()
    }

    handleDelete = id => {
        Toast.showCallBack({
            title: 'Eliminar sitio',
            message: '¿Seguro que desea eliminar este elemento?',
            position: 'center',
            callBack: () => {
                this.handleCallBackDelete(id)
            }
        })
    }

    handleCallBackDelete = async id => {
        const res = await request('DELETE', `${R.deleteSite}/${id}`, true, true, false)
        if(res.success){
            Toast.showSuccess({
                title: 'Eliminado',
                message: 'Sitio eliminado correctamente',
                position: 'topCenter',
            })
        } else {
            Toast.showError({
                title: 'Error',
                message: 'Ocurrió un error inesperado',
                position: 'topCenter',
            })
        }
        this.getSites()
    }

    handleNewSite = async () => {
        await this.setState({sites: null, newSite: true})
        M.updateTextFields()
    }

    handleStoreSite = async e => {
        e.preventDefault()
        e.stopPropagation()
        await this.setState({showPreloader: true})
        const data = {
            name: this.state.siteName,
            private_ip: this.state.sitePrivateIp,
            public_ip: this.state.sitePublicIp,
            description: this.state.siteDescription,
        }
        const res = await request('POST', R.storeSite, true, true, JSON.stringify(data))
        if(res.success){
            Toast.showSuccess({
                title: 'Sitio creado',
                message: 'El sitio fue creado correctamente',
                position: 'topCenter',
            })
        } else {
            Toast.showError({
                title: 'Error',
                message: 'Ocurrió un error inesperado',
                position: 'topCenter',
            })
        }
        await this.setState({
            showPreloader: false,
            newSite: false,
            siteName: '',
            sitePrivateIp: '', 
            sitePublicIp: '',
            siteDescription: '',
        })
        this.getSites()
    }

    async handlePaginationClick(url){
        await this.setState({showPreloader: true})
        const res = await request('GET', url, false, true, false)
        if(res.success){
            await this.setState({sites: res.sites})
        }
        await this.setState({showPreloader: false})
    }

    handleChange = e => {
        const id = e.target.id
        const value = e.target.value
        this.setState({
            ...this.state,
            [id]: value,
        })
    }

    render(){
        return(
            <div style={{height: '100%'}}>
                {this.state.showPreloader &&
                    <Preloader color='yellownee' />
                }
                {this.state.sites &&
                    <div className="col s12" style={{overflow :'hidden'}}>
                        {this.state.sites.data.length !== 0 ?
                            <React.Fragment>
                                <div className="col s12">
                                    <Pagination
                                        state={this.state}
                                        handlePaginationClick={this.handlePaginationClick.bind(this)}
                                    />
                                </div>
                                <div className="col s12 masonry">
                                    <List
                                        sites={this.state.sites}
                                        handleEdit={this.handleEdit.bind(this)}
                                        handleDelete={this.handleDelete.bind(this)}
                                    />
                                </div>
                            </React.Fragment>
                            :
                            <span style={{fontWeight: 'bold', fontSize: '15px', marginLeft: 'calc(50% - 88px)'}}>No hay registros todavía</span>
                        }
                    </div>
                }
                {this.state.newSite ?
                    <NewSite 
                        state={this.state}
                        handleStoreSite={this.handleStoreSite.bind(this)}
                        handleChange={this.handleChange.bind(this)}
                    />
                    :
                    <button className="waves-effect waves-light btn btn-bottom yellownee-gradient" onClick={this.handleNewSite} style={{position: 'fixed', top: 'calc(100vh - 110px)', left: 0}}>
                        <i className="material-icons left">add</i>
                        Nuevo sitio
                    </button>
                }
                {this.state.editSite &&
                    <EditSite
                        state={this.state}
                        handleUpdateSite={this.handleUpdateSite.bind(this)}
                        handleChange={this.handleChange.bind(this)}
                    />
                }
            </div>
        )
    }

}