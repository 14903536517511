import React from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
const authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV

export default class LotCard extends React.Component{

    componentDidMount(){
        const tooltips = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(tooltips);
    }

    handleShow = () => {
        
    }

    handleSend = () => {
        this.props.callbackSend(this.props.lot.id)
    }

    render(){
        return(
            <div className="col s12 m6">
                <div className="card box">
                    <div className="card-content row">
                        <div className="col s12 m8">
                            <span className="list-description">Datos</span>
                            <br/>
                            <span className="item-name deep-blue">Número de folios: {this.props.lot.number}</span>
                            <br/>
                            <span>Creado el: {this.props.lot.created_at}</span>
                        </div>
                        <div className="col s12 m6 buttons-card-box">
                            <button className="btn-floating waves-effect waves-light tooltipped" data-position="top" data-tooltip="Ver" onClick={this.handleShow}>
                                <i className="material-icons deep-blue">visibility</i>
                            </button>
                            <a href={authenticate + `/api/folio/xls/`+ this.props.lot.id} className="btn-floating waves-effect waves-light tooltipped" data-position="top" data-tooltip="Descargar xlsx" onClick={this.handleDownload}>
                                <i className="material-icons cyan-text text-darken-4">cloud_download</i>
                            </a>
                            <button className="btn-floating waves-effect waves-light tooltipped" data-position="top" data-tooltip="Enviar por email" onClick={this.handleSend}>
                                <i className="material-icons amber-text text-darken-4">email</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}