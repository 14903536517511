import React from 'react'
import Axios from 'axios'
import './Reports.css'
import M from 'materialize-css/dist/js/materialize.min.js'
import { verify } from '../../Functions/verify'
import Preloader from '../Loader/Preloader'
import Input from '../IziToast/Input'
import { getValue } from '@syncfusion/ej2-base'
import { ExcelExport, Toolbar, VirtualScroll, FilterSettingsModel, ColumnDirective, ColumnsDirective, Filter, GridComponent, Inject, Page, PageSettingsModel, Sort, Resize, PagerComponent, Pager, AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregatesDirective, Aggregate } from '@syncfusion/ej2-react-grids';
import { ListViewComponent, FieldSettingsModel, ListView } from '@syncfusion/ej2-react-lists';
import { Tab, TabComponent, AccordionComponent } from "@syncfusion/ej2-react-navigations";
import { DatePicker, DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { MultiSelectModel, MultiSelectComponent, CheckBoxSelection, InjectDropDown } from '@syncfusion/ej2-react-dropdowns';
import Graficas from './Graficas'
import chart from '../../img/chart.png'
import report from '../../img/report.png'

export default class Reports extends React.Component {

    authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV
    assistsStatus = {
        CM: 'CANCELADO AL MOMENTO',
        CP: 'CANCELADO POSTIOR',
        CON: 'CONCLUIDO',
        PRO: 'EN PROCESO',
    }
    local = {
        L: 'Local',
        F: 'Foraneo',
    }
    filterdata = null
    pageSettings: PageSettingsModel = { pageSize: 100 }
    grid: Grid | null;
    toolbar: ToolbarItems[] = ['ExcelExport'];
    gridEKUS: Grid | null;
    filterSettings: FilterSettingsModel = { mode: 'Immediate', showFilterBarStatus: false, immediateModeDelay: 1000 }
    fields: FieldSettingsModel = { text: 'Text', value: 'Id' };
    pager: Pager | null;
    ddlAcount: MultiSelectModel | null;
    ddlPlan: MultiSelectModel | null;
    ddlFamilia: MultiSelectModel | null;
    ddlTipoAsistencia: MultiSelectModel | null;
    ddlFilterGrid: MultiSelectModel | null;
    tabControl: Tab | null;
    dtpFechaIni: DatePicker | null;
    dtpFechaFin: DatePicker | null;
    lstReportes: ListView | null;
    lstReportesEKUS: ListView | null;
    ddlAcountEKUS: MultiSelectModel | null;
    ddlPlanEKUS: MultiSelectModel | null;
    ddlFamiliaEKUS: MultiSelectModel | null;
    ddlTipoAsistenciaEKUS: MultiSelectModel | null;

    constructor() {
        super()

        this.state = {
            showPreloader: false,
            showPreloaderExport: false,
            showReportsPreloader: false,
            reportData: null,
            customers: null,
            plans: null,
            accordion_index: 0,
            // statesData: null,
            // municipalities: null,
            assistanceFamilies: null,
            assistanceType: null,
            reports: [
                { "Text": 'Asistencia-Proveedor', "Id": 'asistance_provider' },
                { "Text": 'Tiempo de Asignación', "Id": 'allocation_time' },
                { "Text": 'Tiempo de Arribo', "Id": 'arrival_time' }
            ],
            reportsEKUS: [
                { "Text": 'Alertas Enviadas', "Id": 'A' },
                { "Text": 'Tipo de Grúas', "Id": 'B' },
                { "Text": 'Tiempos de Desplazamiento', "Id": 'C' }
            ],
            tipos: [
                { "Text": 'No Viales', "Id": '0' },
                { "Text": 'Viales', "Id": '1' }
            ],
            municipality: '',
            endDate: '',
            startDate: '',
            reportType: 'assistance_provider',
            metas: 1,
            isReportTypeSelected: false,
            totalRecordsCount: 0,
            pageSize: 0,
            columnVisible_allocation_time: false,
            columnVisible_arrival_time: false,
            reportDataEKUS: null,
            tabIndex: 0
        }
    }

    initSelects = () => {
        const selects = document.querySelectorAll('select:not(.e-ddl-hidden):not(.e-multi-hidden)')
        M.FormSelect.init(selects)
        this.setState({ showPreloader: false })
    }

    getAccounts = async () => {
        const res = await Axios.get(this.authenticate + '/api/soa/accounts', {
            headers: { Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') }
        })
        await this.setState({ accounts: res.data.accounts })
        //this.initSelects()
    }

    getPlans = async () => {

        await this.setState({ showPreloader: true })
        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
            data: JSON.stringify(this.state.account),
            url: this.authenticate + '/api/soa/plans'
        }
        const response = await Axios(options)

        await this.setState({ showPreloader: false, plans: response.data.plans })
        //this.initSelects()
    }

    getFamilies = async () => {
        const res = await Axios.get(this.authenticate + '/api/soa/assists/family', {
            headers: { Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') }
        })
        await this.setState({ families: res.data.assistsFamily })
        //this.initSelects()
    }

    getTypes = async () => {
        await this.setState({ showPreloader: true })

        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
            data: JSON.stringify(this.state.family),
            url: this.authenticate + '/api/soa/assists/type'
        }
        const response = await Axios(options)


        await this.setState({ types: response.data.assistsTypes, showPreloader: false })
    }
    getStates = async () => {
        const res = await Axios.get(this.authenticate + '/api/soa/states', {
            headers: { Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') }
        })
        await this.setState({ states: res.data.states })
        //this.initSelects()
    }

    getMunicipalities = async () => {
        await this.setState({ showPreloader: true })
        const filtered = this.state.states.filter(state => state.ID == this.state.state)
        const res = await Axios.get(this.authenticate + '/api/soa/municipalities/' + filtered[0].CVEENTIDAD1, {
            headers: { Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') }
        })
        await this.setState({ municipalities: res.data.municipalities })
        //this.initSelects()
    }

    async handlePaginationClick(url) {
        await this.setState({ showReportsPreloader: true })
        const filters = {
            customerName: this.state.account,
            plan: this.state.plan,
            assistanceFamily: this.state.family,
            assistanceType: this.state.type,
            // state: this.state.state,
            // city: this.state.municipality,
            reportType: this.state.reportType,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            metas: this.state.metas,
            viales: this.state.viales,
        }

        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
            data: JSON.stringify(filters),
            url: url,
        }
        const res = await Axios(options)
        await this.setState({ showReportsPreloader: false, reportData: res.data.report })
    }

    handleCloseModal = id => {
        const element = document.getElementById(id)
        const modal = M.Modal.getInstance(element)
        modal.close()

    }

    handleNewModal = () => {
        const element = document.getElementById('modalAddTask')
        const modal = M.Modal.init(element)
        modal.open()
    }

    handleSubmit = async e => {
        e.preventDefault()
        e.stopPropagation()

        await this.setState({ showReportsPreloader: true })

        window.scrollTo(0, document.body.scrollHeight);

        const filters = {
            customerNames: this.ddlAccount.value != null ? (this.ddlAccount.value.length == 0 ? null : this.ddlAccount.value) : this.ddlAccount.value,
            plans: this.ddlPlan.value != null ? (this.ddlPlan.value.length == 0 ? null : this.ddlPlan.value) : this.ddlPlan.value,
            assistanceFamily: this.ddlFamilia.value != null ? (this.ddlFamilia.value.length == 0 ? null : this.ddlFamilia.value) : this.ddlFamilia.value,
            assistanceType: this.ddlTipoAsistencia.value != null ? (this.ddlTipoAsistencia.value.length == 0 ? null : this.ddlTipoAsistencia.value) : this.ddlTipoAsistencia.value,
            // state: this.state.state,
            // city: this.state.municipality,
            reportType: this.state.reportType,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            metas: this.state.metas
        }

        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
            data: JSON.stringify(filters),
            url: this.authenticate + '/api/soa/myreport'
        }
        const response = await Axios(options)

        await this.setDataReport(response.data.report);

        await this.setState({ reportData: response.data.report, showReportsPreloader: false, totalRecordsCount: response.data.report.total, pageSize: response.data.report.per_page })
    }

    handleSubmitEKUS = async e => {
        e.preventDefault()
        e.stopPropagation()

        await this.setState({ showReportsPreloader: true })

        window.scrollTo(0, document.body.scrollHeight);

        const filters = {
            opcion: this.state.reportType,
            dateini: this.dtpFechaIni.value === null ? '' : this.dtpFechaIni.value.getFullYear() + '-' + (this.dtpFechaIni.value.getMonth() < 9 ? '0' + (this.dtpFechaIni.value.getMonth() + 1) : (this.dtpFechaIni.value.getMonth() + 1)) + '-' + (this.dtpFechaIni.value.getDate() <= 9 ? '0' + this.dtpFechaIni.value.getDate() : this.dtpFechaIni.value.getDate()),
            datefin: this.dtpFechaFin.value === null ? '' : this.dtpFechaFin.value.getFullYear() + '-' + (this.dtpFechaFin.value.getMonth() < 9 ? '0' + (this.dtpFechaFin.value.getMonth() + 1) : (this.dtpFechaFin.value.getMonth() + 1)) + '-' + (this.dtpFechaFin.value.getDate() <= 9 ? '0' + this.dtpFechaFin.value.getDate() : this.dtpFechaFin.value.getDate()),
            accounts: this.ddlAccountEKUS.value != null ? (this.ddlAccountEKUS.value.length == 0 ? '' : this.ddlAccountEKUS.value.join()) : this.ddlAccountEKUS.value,
            plans: this.ddlPlanEKUS.value != null ? (this.ddlPlanEKUS.value.length == 0 ? '' : this.ddlPlanEKUS.value.join()) : this.ddlPlanEKUS.value,
            familys: this.ddlFamiliaEKUS.value != null ? (this.ddlFamiliaEKUS.value.length == 0 ? '' : this.ddlFamiliaEKUS.value.join()) : this.ddlFamiliaEKUS.value,
            assiststypes: this.ddlTipoAsistenciaEKUS.value != null ? (this.ddlTipoAsistenciaEKUS.value.length == 0 ? '' : this.ddlTipoAsistenciaEKUS.value.join()) : this.ddlTipoAsistenciaEKUS.value
        }

        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
            data: JSON.stringify(filters),
            url: this.authenticate + '/api/soa/myreportekus'
        }
        const response = await Axios(options)

        await this.setState({ reportDataEKUS: response.data.report, showReportsPreloader: false })
    }

    setDataReport = async (dataReport) => {
        await this.setState({ showReportsPreloader: true })

        if (dataReport != null) {
            dataReport.data.map(report => {
                report.ESTATUS = this.assistsStatus[report.ESTATUS];
                report.LOCAL = this.local[report.LOCAL];
            });
        }
    }

    handleChange = async e => {
        const value = e.target.value
        const id = e.target.id

        await this.setState({
            ...this.state,
            [id]: value
        })

        switch (id) {
            case 'account':
                this.getPlans()
                break
            case 'family':
                this.getTypes()
                break
            // case 'state':
            //     this.getMunicipalities()
            //     break
            case 'reportType':
                this.initSelects()
                break
        }
    }

    handleExport = () => {

        Input('Password', 'Ingrese la contraseña que tendrá el documento', 'center',
            () => {
                this.handleDonwloadFile()
            }
        )
    }

    handleDonwloadFile = async () => {

        await this.setState({ showPreloaderExport: true })

        const filters = {
            customerNames: this.ddlAccount.value,
            plans: this.ddlPlan.value,
            assistanceFamilys: this.ddlFamilia.value,
            assistanceTypes: this.ddlTipoAsistencia.value,
            // state: this.state.state,
            // city: this.state.municipality,
            reportType: this.state.reportType,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            metas: this.state.metas,
            password: localStorage.getItem('textCallBack')
        }

        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
            data: JSON.stringify(filters),
            url: this.authenticate + '/api/soa/export'
        }
        const res = await Axios(options)
        localStorage.removeItem('textCallBack')
        await this.setState({ showPreloaderExport: false })
        window.open(`${this.authenticate}/api/soa/download/${res.data.name}`, '_blank')
    }

    dataBound = () => {
        if (this.grid) {
            this.grid.autoFitColumns()
            window.scrollTo(0, document.body.scrollHeight);
        }
    }

    customizeCell = (args) => {
        if (args.data && args.cell) {
            if (getValue('CUMPLIMIENTO', args.data) == "INCUMPLIMIENTO") {
                args.cell.classList.add('cell-color-grid');
            }
        }
    }

    clickPage = async (args) => {

        this.grid.showSpinner();
        await this.setState({ showReportsPreloader: true })
        const filters = {
            customerNames: this.ddlAccount.value,
            plans: this.ddlPlan.value,
            assistanceFamilys: this.ddlFamilia.value,
            assistanceTypes: this.ddlTipoAsistencia.value,
            state: this.state.state,
            city: this.state.municipality,
            reportType: this.state.reportType,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            metas: this.state.metas
        }

        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
            data: JSON.stringify(filters),
            url: this.authenticate + '/api/soa/myreport?page=' + args.currentPage,
        }
        const res = await Axios(options)
        await this.setDataReport(res.data.report);

        await this.setState({ showReportsPreloader: false, reportData: res.data.report })
    }

    headerTextFiltros = () => {
        return (
            <h5>{this.state.headerTextFiltros}</h5>
        )
    }

    valueAccess = (field, data, column) => {
        var value = '';
        if (field == "ESTATUS") {
            value = this.assistsStatus[getValue(field, data)];
            data.ESTATUS = value;
        }
        else {
            value = this.local[getValue(field, data)];
            data.LOCAL = value;
        }

        return value;
    }

    selectedItemReportType = async (e) => {
        await this.setState({ showPreloader: true, isReportTypeSelected: true, reportType: e.data.Id, headerTextFiltros: e.data.Text, columnVisible_allocation_time: e.data.Id == "asistance_provider" ? false : true, columnVisible_arrival_time: e.data.Id == "arrival_time" ? true : false, reportData: null });

        const auth = await verify()
        if (!auth) {
            window.location.href = '/'
        }

        M.updateTextFields()
        const startDatePicker = document.querySelectorAll('#startDate.datepicker');
        M.Datepicker.init(startDatePicker, {
            autoClose: true,
            format: 'yyyy-mm-dd',
            onSelect: async val => {
                const date = new Date(val)
                const efd = date.getFullYear() + '-' + (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate());
                await this.setState({ startDate: efd })
                M.updateTextFields()
            }
        })
        const endDatePicker = document.querySelectorAll('#endDate.datepicker');
        M.Datepicker.init(endDatePicker, {
            autoClose: true,
            format: 'yyyy-mm-dd',
            onSelect: async val => {
                const date = new Date(val)
                const efd = date.getFullYear() + '-' + (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate());
                await this.setState({ endDate: efd })
                M.updateTextFields()
            }
        })

        await this.getAccounts()
        await this.getFamilies()
        // await this.getStates()

        await this.setState({ showPreloader: false })
    }

    onChangefilterTemplate = (args) => {
        if (this.grid) {
            const fields = [args.element.id]
            if (args.value.length == 0) {
                this.grid.clearFiltering(fields);
            } else {
                this.grid.filterByColumn(args.element.id, 'equal', args.value);
            }
        }
    }

    filterTemplate = (props) => {
        this.filterdata = null
        if (props.column.field == "ESTATUS") {
            this.filterdata = ["CANCELADO AL MOMENTO", "CANCELADO POSTIOR", "CONCLUIDO", "EN PROCESO"]
        }
        else if (props.column.field == "LOCAL") {
            this.filterdata = ["Local", "Foraneo"]
        }
        else if (props.column.field == "CUMPLIMIENTO" || props.column.field == "CUMPLIMIENTO_ASIGNACION") {
            this.filterdata = ["CUMPLIMIENTO", "INCUMPLIMIENTO", "NO APLICA", "TIEMPO NEGATIVO"]
        }

        return (<MultiSelectComponent popupHeight="350px" popupWidth="250px" placeholder="Selecciona una opción" id={props.column.field} changeOnBlur={false} mode="CheckBox" showSelectAll={true}
            showDropDownIcon={true} filterBarPlaceholder="Buscar" dataSource={this.filterdata} change={this.onChangefilterTemplate} >
            <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>)
    }

    ddlAccount_change = async (args) => {
        await this.setState({ account: args.value })

        await this.getPlans()
    }

    ddlAccount_removed = async (args) => {
        if (this.state.tabIndex === 0) {
            if (this.ddlAccount.value === null) {
                await this.setState({ account: '' })
                await this.getPlans()
            }
        }

        if (this.state.tabIndex === 1) {
            if (this.ddlAccountEKUS.value === null) {
                await this.setState({ account: '' })
                await this.getPlans()
            }
        }
    }

    ddlFamilia_change = async (args) => {
        await this.setState({ family: args.value })
        await this.getTypes()
    }

    ddlFamilia_removed = async (args) => {
        if (this.state.tabIndex == 0) {
            if (this.ddlFamilia.value === null) {
                await this.setState({ family: '', assistanceType: '' })
                await this.getTypes();
            }
        }

        if (this.state.tabIndex == 1) {
            if (this.ddlFamiliaEKUS.value === null) {
                await this.setState({ family: '', assistanceType: '' })
                await this.getTypes();
            }
        }
    }

    countExpedientesFn = (props) => {
        return (<span style={{ color: '#26a69a' }}><strong>Total Asistencias: {this.state.totalRecordsCount}</strong></span>)
    }

    dataBoundEKUS = async () => {
        if (this.gridEKUS) {
            this.gridEKUS.height = window.innerHeight - 400;
            this.gridEKUS.autoFitColumns()
            window.scrollTo(0, document.body.scrollHeight);
            //this.grgridEKUSid.hideColumns(['idfamilia','idasistencia','id','content'])
        }
    }

    selectedItemReportEKUSType = async (e) => {
        await this.setState({ showPreloader: true, isReportTypeSelected: true, reportType: e.data.Id, reportDataEKUS: null });

        const auth = await verify()
        if (!auth) {
            window.location.href = '/'
        }

        await this.getAccounts()
        await this.getFamilies()

        await this.setState({ showPreloader: false })
    }

    tabControl_selected = async (args) => {
        await this.setState({ showPreloader: true, isReportTypeSelected: false, reportData: null, reportDataEKUS: null, tabIndex: args.selectedIndex, dataReport: null, dataBoundEKUS: null, reportType: '', account: '', plan: '', family: '', type: '', accounts: null, plans: null, assistsTypes: null, assistsFamily: null, columnVisible_allocation_time: false, columnVisible_arrival_time: false })

        this.lstReportes.selectItem(null);
        this.lstReportesEKUS.selectItem(null);

        await this.setState({ showPreloader: false })
    }
    toolbarClick = (args) => {
        if (this.gridEKUS && args.item.id.includes('excelexport')) {
            this.gridEKUS.excelExport();
        }
    }

    accordionControl_expanding = async (args) => {
        await this.setState({ showPreloader: true, accordion_index: args.index })

        // if(args.index === 1){
        //     await this.getAccounts();
        //     await this.getStates();
        // }

        await this.setState({ showPreloader: false })
    };

    render() {
        return (
            <div className="row">
                <div className="col s12" style={{ height: '100%' }}>
                    < br />
                    <TabComponent tabIndex={0} ref={b => this.tabControl = b} id='tabReportes' heightAdjustMode='Auto' selected={this.tabControl_selected}>
                        <div className="e-tab-header">
                            <div className="e-background"> Información Gerencial </div>
                            <div> EKUS  </div>
                        </div>
                        <div className="Line-header-tabs"></div>
                        <div className="e-content">
                            <div>
                                <br />
                                <AccordionComponent ref={b => this.accordionControl = b} expanding={this.accordionControl_expanding}>
                                    <div>
                                        <div>
                                            <div><img src={report} alt="" className="img_accordion" />&nbsp;&nbsp;Reportes</div>
                                        </div>
                                        <div>
                                            <div>
                                                <div className='row'>
                                                    <div className='col s12'>
                                                        <ListViewComponent ref={(ms) => { this.lstReportes = ms; }} select={this.selectedItemReportType} dataSource={this.state.reports} fields={this.fields} showHeader={true} headerTitle="Seleccione un reporte" />
                                                    </div>
                                                </div>
                                                <div className="content row" style={{ height: '100%', marginRight: 'unset' }}>
                                                    {this.state.showPreloader &&
                                                        <Preloader color="yellownee" />
                                                    }
                                                    <div className="content--inner col s12" style={{ height: '100%' }}>
                                                        {this.state.isReportTypeSelected &&
                                                            <div className="row" style={{ paddingLeft: 15 }}>
                                                                <form onSubmit={this.handleSubmit} className="col s12">
                                                                    <div className="row">
                                                                        <div className="input-field col s6 m4">
                                                                            <input id="startDate" type="text" className="validate datepicker deep-blue" value={this.state.startDate} onChange={this.handleChange} ></input>
                                                                            <label htmlFor="startDate">Desde</label>
                                                                        </div>
                                                                        <div className="input-field col s6 m4">
                                                                            <input id="endDate" type="text" className="validate datepicker deep-blue" value={this.state.endDate} onChange={this.handleChange} ></input>
                                                                            <label htmlFor="endDate">Hasta</label>
                                                                        </div>
                                                                        <div className="col s-12 m6">
                                                                            <label>Cuenta</label>
                                                                            <MultiSelectComponent ref={(ms) => { this.ddlAccount = ms; }} changeOnBlur={false} dataSource={this.state.tabIndex === 0 ? this.state.accounts : null}
                                                                                fields={{ text: 'NOMBRE', value: 'IDCUENTA' }} placeholder="Selecciona una opción" mode="CheckBox" showSelectAll={true}
                                                                                showDropDownIcon={true} filterBarPlaceholder="Buscar cuenta" popupHeight="350px" change={this.ddlAccount_change} removed={this.ddlAccount_removed}>
                                                                                <Inject services={[CheckBoxSelection]} />
                                                                            </MultiSelectComponent>
                                                                        </div>
                                                                        <div className="col s-12 m6">
                                                                            <label>Plan</label>
                                                                            <MultiSelectComponent ref={(ms) => { this.ddlPlan = ms; }} changeOnBlur={false} dataSource={this.state.tabIndex === 0 ? this.state.plans : null}
                                                                                fields={{ text: 'NOMBRE', value: 'IDPROGRAMA' }} placeholder="Selecciona una opción" mode="CheckBox" showSelectAll={true}
                                                                                showDropDownIcon={true} filterBarPlaceholder="Buscar plan" popupHeight="350px">
                                                                                <Inject services={[CheckBoxSelection]} />
                                                                            </MultiSelectComponent>
                                                                        </div>
                                                                        <div className="col s12 m6">
                                                                            <br />
                                                                            <label>Familia de asistencias</label>
                                                                            <MultiSelectComponent ref={(ms) => { this.ddlFamilia = ms; }} changeOnBlur={false} dataSource={this.state.tabIndex === 0 ? this.state.families : null}
                                                                                fields={{ text: 'DESCRIPCION', value: 'IDFAMILIA' }} placeholder="Selecciona una familia" mode="CheckBox" showSelectAll={true}
                                                                                showDropDownIcon={true} filterBarPlaceholder="Buscar familia" popupHeight="350px" change={this.ddlFamilia_change} removed={this.ddlFamilia_removed}>
                                                                                <Inject services={[CheckBoxSelection]} />
                                                                            </MultiSelectComponent>
                                                                        </div>
                                                                        <div className="col s12 m6">
                                                                            <br />
                                                                            <label>Tipo de asistencia</label>
                                                                            <MultiSelectComponent ref={(ms) => { this.ddlTipoAsistencia = ms; }} changeOnBlur={false} dataSource={this.state.tabIndex === 0 ? this.state.types : null}
                                                                                fields={{ text: 'DESCRIPCION', value: 'IDSERVICIO' }} placeholder="Selecciona un tipo de asistencia" mode="CheckBox" showSelectAll={true}
                                                                                showDropDownIcon={true} filterBarPlaceholder="Buscar tipo de asistencia" popupHeight="350px">
                                                                                <Inject services={[CheckBoxSelection]} />
                                                                            </MultiSelectComponent>
                                                                        </div>
                                                                        <div className="col s12 m6 push-m3">
                                                                            <br />
                                                                            <button class="waves-effect waves-light btn" style={{ width: '100%' }}><i class="material-icons right">search</i>Buscar</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        }
                                                        {this.state.showReportsPreloader &&
                                                            <div className="row">
                                                                <div className="col s12">
                                                                    <Preloader color="blue" position="relative" />
                                                                </div>
                                                            </div>
                                                        }
                                                        {(this.state.columnVisible_allocation_time && !this.state.columnVisible_arrival_time) &&
                                                            <div className="row" style={{ paddingLeft: 15 }}>
                                                                <div className="col s6 deep-blue">
                                                                    <strong>CUMPLIMIENTO</strong>
                                                                    <ul>
                                                                        <li>
                                                                            LOCAL: Tiempo de {this.state.reportType === 'arrival_time' ? 'arribo' : 'asignación'} menor a 00:10:00
                                                                        </li>
                                                                        <li>
                                                                            FORÁNEO: Tiempo de {this.state.reportType === 'arrival_time' ? 'arribo' : 'asignación'} menor a 00:15:00
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col s6 deep-blue">
                                                                    <strong>INCUMPLIMIENTO</strong>
                                                                    <ul>
                                                                        <li>
                                                                            LOCAL: Tiempo de {this.state.reportType === 'arrival_time' ? 'arribo' : 'asignación'} mayor a 00:10:01
                                                                        </li>
                                                                        <li>
                                                                            FORÁNEO: Tiempo de {this.state.reportType === 'arrival_time' ? 'arribo' : 'asignación'} mayor a 00:15:01
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        }
                                                        {(this.state.columnVisible_allocation_time && this.state.columnVisible_arrival_time) &&
                                                        <>
                                                              <div className="row" style={{ paddingLeft: 15 }}>
                                                              <div className="col s6 deep-blue">
                                                                  <strong>CUMPLIMIENTO ASIGNACIÓN</strong>
                                                                  <ul>
                                                                      <li>
                                                                          LOCAL: Tiempo de asignación menor a 00:10:00
                                                                      </li>
                                                                      <li>
                                                                          FORÁNEO: Tiempo de asignación menor a 00:15:00
                                                                      </li>
                                                                  </ul>
                                                              </div>
                                                              <div className="col s6 deep-blue">
                                                                  <strong>INCUMPLIMIENTO ASIGNACIÓN</strong>
                                                                  <ul>
                                                                      <li>
                                                                          LOCAL: Tiempo de asignación mayor a 00:10:01
                                                                      </li>
                                                                      <li>
                                                                          FORÁNEO: Tiempo de asignación mayor a 00:15:01
                                                                      </li>
                                                                  </ul>
                                                              </div>
                                                          </div>
                                                            <div className="row" style={{ paddingLeft: 15 }}>
                                                                <div className="col s6 deep-blue">
                                                                    <strong>CUMPLIMIENTO ARRIBO</strong>
                                                                    <ul>
                                                                        <li>
                                                                            LOCAL: Tiempo de arribo menor a 01:00:00
                                                                        </li>
                                                                        <li>
                                                                            FORÁNEO: Tiempo de arribo menor a 01:30:00
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col s6 deep-blue">
                                                                    <strong>INCUMPLIMIENTO ARRIBO</strong>
                                                                    <ul>
                                                                        <li>
                                                                            LOCAL: Tiempo de arribo mayor a 01:00:01
                                                                        </li>
                                                                        <li>
                                                                            FORÁNEO: Tiempo de arribo mayor a 01:30:01
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </>
                                                        }
                                                        {this.state.reportData !== null &&
                                                            <div className="row" style={{ paddingLeft: 15 }}>
                                                                <div className="col s12">
                                                                    <GridComponent locale='es-MX' gridLines='Horizontal' dataSource={this.state.reportData.data} allowFiltering={true} allowPaging={false} pageSettings={this.pageSettings} height='450' width='100%' dataBound={this.dataBound} ref={g => this.grid = g} allowSelection={false} filterSettings={this.filterSettings} queryCellInfo={this.customizeCell} created={this.onGridCreated}>
                                                                        <ColumnsDirective>
                                                                            <ColumnDirective field='IDEXPEDIENTE' headerText='Expediente' textAlign="Left" />
                                                                            <ColumnDirective field='IDASISTENCIA' headerText='Asistencia' textAlign="Left" />
                                                                            {/* <ColumnDirective field='ESTADO' headerText='Estado' textAlign="Left" />
                                            <ColumnDirective field='MUNICIPIO' headerText='Municipio' textAlign="Left" /> */}
                                                                            <ColumnDirective field='FECHAREGISTRO' headerText='Fecha Registro' textAlign="Left" />
                                                                            <ColumnDirective field='HORAREGISTRO' headerText='Hora Registro' textAlign="Left" />
                                                                            <ColumnDirective field='FECHAASIGNACION' headerText='Fecha Asignación' textAlign="Left" visible={this.state.columnVisible_allocation_time} />
                                                                            <ColumnDirective field='HORAASIGNACION' headerText='Hora Asignación' textAlign="Left" visible={this.state.columnVisible_allocation_time} />
                                                                            <ColumnDirective field='TIEMPOASIGNACION' headerText='Tiempo Asignación' textAlign="Left" visible={this.state.columnVisible_allocation_time} />
                                                                            <ColumnDirective field='FECHAARRIBO' headerText='Fecha Arribo' textAlign="Left" visible={this.state.columnVisible_arrival_time} />
                                                                            <ColumnDirective field='HORAARRIBO' headerText='Hora Arribo' textAlign="Left" visible={this.state.columnVisible_arrival_time} />
                                                                            <ColumnDirective field='TIEMPOARRIBO' headerText='Tiempo Arribo' textAlign="Left" visible={this.state.columnVisible_arrival_time} />
                                                                            <ColumnDirective field='PRIORIDAD_ATENCION' headerText='Prioridad Atención' textAlign="Left" />
                                                                            <ColumnDirective field='CUENTA' headerText='Cuenta' textAlign="Left" />
                                                                            <ColumnDirective field='PLAN' headerText='Plan' textAlign="Left" />
                                                                            <ColumnDirective field='AFILIADO' headerText='Titular' textAlign="Left" />
                                                                            <ColumnDirective field='SERVICIO' headerText='Servicio' textAlign="Left" />
                                                                            <ColumnDirective field='ETIQUETA' headerText='Nombre Comercial' textAlign="Left" />
                                                                            <ColumnDirective field='PROVEEDOR' headerText='Proveedor' textAlign="Left" />
                                                                            <ColumnDirective field='ESTATUS' headerText='Estatus' textAlign="Left" filterTemplate={this.filterTemplate} />
                                                                            <ColumnDirective field='LOCAL' headerText='Local / Foraneo' textAlign="Left" filterTemplate={this.filterTemplate} />
                                                                            <ColumnDirective field='CUMPLIMIENTO_ASIGNACION' headerText='CUMPLIMIENTO ASIGNACIÓN' textAlign="Left" visible={this.state.columnVisible_arrival_time} filterTemplate={this.filterTemplate} />
                                                                            <ColumnDirective field='CUMPLIMIENTO' headerText={this.state.reportType === 'arrival_time' ? 'CUMPLIMIENTO ARRIBO' : 'CUMPLIMIENTO'} textAlign="Left" visible={this.state.columnVisible_allocation_time} filterTemplate={this.filterTemplate} />
                                                                        </ColumnsDirective>
                                                                        <AggregatesDirective>
                                                                            <AggregateDirective>
                                                                                <AggregateColumnsDirective>
                                                                                    <AggregateColumnDirective field='IDEXPEDIENTE' type='Count' footerTemplate={this.countExpedientesFn} />
                                                                                </AggregateColumnsDirective>
                                                                            </AggregateDirective>
                                                                        </AggregatesDirective>
                                                                        <Inject services={[Page, Sort, Filter, Resize, Aggregate]} />
                                                                    </GridComponent>
                                                                    <PagerComponent ref={p => this.pager = p} totalRecordsCount={this.state.totalRecordsCount} pageSize={this.state.pageSize} click={this.clickPage} pageCount={10}>
                                                                    </PagerComponent>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    {this.state.showPreloaderExport &&
                                                        <Preloader color="yellownee" />
                                                    }
                                                    {this.state.reportData &&
                                                        <React.Fragment>
                                                            <div className="col s12 m6" style={{ height: '100%' }}>
                                                                <button className="waves-effect waves-light btn" onClick={this.handleExport}>
                                                                    <i class="material-icons right">file_download</i>
                                                                    Exportar
                                                                </button>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>

                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div><img src={chart} alt="" className="img_accordion" />&nbsp;&nbsp;Gráficas</div>
                                        </div>
                                        <div>
                                            {this.state.accordion_index === 1 &&
                                                <Graficas></Graficas>
                                            }

                                        </div>
                                    </div>
                                </AccordionComponent>
                            </div>
                            <div>
                                <br />
                                <div className='row'>
                                    <div className='col s12'>
                                        <ListViewComponent ref={(ms) => { this.lstReportesEKUS = ms; }} select={this.selectedItemReportEKUSType} dataSource={this.state.reportsEKUS} fields={this.fields} showHeader={true} headerTitle="Seleccione un reporte" />
                                    </div>
                                </div>
                                <div className="row" style={{ paddingLeft: 15 }}>
                                    {this.state.reportType === 'A' &&
                                        <div className="col s12 deep-blue">
                                            Esta consulta muestra el detalle los servicios que tuvieron envío de solicitud por Eküs, sin embargo fueron ignoradas o rechazadas por el/los proveedores y se realizó asignación manual por falta de respuesta en App. ( únicamente asignaciones manuales)
                                        </div>
                                    }
                                    {this.state.reportType === 'B' &&
                                        <div className="col s12 deep-blue">
                                            Esta consulta muestra el  detalle del TIPO DE GRUA  que seleccionó el ejecutivo al momento de la toma de datos, muestra si seleccionó más de una combinación  o si no selecciono ninguno , muestra la sigla SR.
                                        </div>
                                    }
                                    {this.state.reportType === 'C' &&
                                        <div className="col s12 deep-blue">
                                            Esta consulta muestra la trazabilidad de los tiempos de los servicios que tuvieron envío de alerta o solicitud de servicio a proveedores via ekus.
                                        </div>
                                    }
                                </div>
                                <div className="content row" style={{ height: '100%', marginRight: 'unset' }}>

                                    {this.state.showPreloader &&
                                        <Preloader color="yellownee" />
                                    }
                                    <div className="content--inner col s12" style={{ height: '100%' }}>
                                        {this.state.isReportTypeSelected &&
                                            <div className="row" style={{ paddingLeft: 15 }}>
                                                <form onSubmit={this.handleSubmitEKUS} className="col s12">
                                                    <div className="row">
                                                        <div className="input-field col s6 m4">
                                                            <DatePickerComponent id="dtpFechaIni" name="dtpFechaIni" ref={p => this.dtpFechaIni = p} allowEdit={false} openOnFocus={true} placeholder="Desde" format="yyyy-MM-dd"></DatePickerComponent>
                                                        </div>
                                                        <div className="input-field col s6 m4">
                                                            <DatePickerComponent id="dtpFechaFin" name="dtpFechaFin" ref={p => this.dtpFechaFin = p} allowEdit={false} openOnFocus={true} placeholder="Hasta" format="yyyy-MM-dd"></DatePickerComponent>
                                                        </div>
                                                        <div className="col s-12 m6">
                                                            <label>Cuenta</label>
                                                            <MultiSelectComponent ref={(ms) => { this.ddlAccountEKUS = ms; }} changeOnBlur={false} dataSource={this.state.tabIndex === 1 ? this.state.accounts : null}
                                                                fields={{ text: 'NOMBRE', value: 'IDCUENTA' }} placeholder="Selecciona una opción" mode="CheckBox" showSelectAll={true}
                                                                showDropDownIcon={true} filterBarPlaceholder="Buscar cuenta" popupHeight="350px" change={this.ddlAccount_change} removed={this.ddlAccount_removed}>
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </MultiSelectComponent>
                                                        </div>
                                                        <div className="col s-12 m6">
                                                            <label>Plan</label>
                                                            <MultiSelectComponent ref={(ms) => { this.ddlPlanEKUS = ms; }} changeOnBlur={false} dataSource={this.state.plans}
                                                                fields={{ text: 'NOMBRE', value: 'IDPROGRAMA' }} placeholder="Selecciona una opción" mode="CheckBox" showSelectAll={true}
                                                                showDropDownIcon={true} filterBarPlaceholder="Buscar plan" popupHeight="350px">
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </MultiSelectComponent>
                                                        </div>
                                                        <div className="col s12 m6">
                                                            <br />
                                                            <label>Familia de asistencias</label>
                                                            <MultiSelectComponent ref={(ms) => { this.ddlFamiliaEKUS = ms; }} changeOnBlur={false} dataSource={this.state.tabIndex === 1 ? this.state.families : null}
                                                                fields={{ text: 'DESCRIPCION', value: 'IDFAMILIA' }} placeholder="Selecciona una familia" mode="CheckBox" showSelectAll={true}
                                                                showDropDownIcon={true} filterBarPlaceholder="Buscar familia" popupHeight="350px" change={this.ddlFamilia_change} removed={this.ddlFamilia_removed}>
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </MultiSelectComponent>
                                                        </div>
                                                        <div className="col s12 m6">
                                                            <br />
                                                            <label>Tipo de asistencia</label>
                                                            <MultiSelectComponent ref={(ms) => { this.ddlTipoAsistenciaEKUS = ms; }} changeOnBlur={false} dataSource={this.state.tabIndex === 1 ? this.state.types : null}
                                                                fields={{ text: 'DESCRIPCION', value: 'IDSERVICIO' }} placeholder="Selecciona un tipo de asistencia" mode="CheckBox" showSelectAll={true}
                                                                showDropDownIcon={true} filterBarPlaceholder="Buscar tipo de asistencia" popupHeight="350px">
                                                                <Inject services={[CheckBoxSelection]} />
                                                            </MultiSelectComponent>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col s12 m6 push-m3">
                                                            <br />
                                                            <button class="waves-effect waves-light btn" style={{ width: '100%' }}><i class="material-icons right">search</i>Buscar</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        }
                                        {this.state.showReportsPreloader &&
                                            <div className="row">
                                                <div className="col s12">
                                                    <Preloader color="blue" position="relative" />
                                                </div>
                                            </div>
                                        }
                                        {this.state.reportDataEKUS !== null &&
                                            <div className="row" style={{ paddingLeft: 15 }}>
                                                <div className="col s12">
                                                    <GridComponent locale='es-MX' gridLines='Horizontal' allowExcelExport={true} allowFiltering={true} allowPaging={true} enableColumnVirtualization={false} toolbar={this.toolbar} pageSettings={this.pageSettings} width='100%' allowSelection={false} filterSettings={this.filterSettings} dataSource={this.state.reportDataEKUS} ref={g => this.gridEKUS = g} dataBound={this.dataBoundEKUS} toolbarClick={this.toolbarClick}>
                                                        <Inject services={[Page, Sort, Filter, Resize, Aggregate, ExcelExport, Toolbar, VirtualScroll]} />
                                                    </GridComponent>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </TabComponent>
                </div>
            </div>
        )
    }

    contentFiltrosReporte = () => {
        return (
            <div className="row">
                <form onSubmit={this.handleSubmit} className="col s12">
                    <div className="row">
                        <div className="input-field col s6 m4">
                            <input id="startDate" type="text" className="validate datepicker deep-blue" value={this.state.startDate} onChange={this.handleChange} ></input>
                            <label htmlFor="startDate">Desde</label>
                        </div>
                        <div className="input-field col s6 m4">
                            <input id="endDate" type="text" className="validate datepicker deep-blue" value={this.state.endDate} onChange={this.handleChange} ></input>
                            <label htmlFor="endDate">Hasta</label>
                        </div>
                        <div className="col s-12 m6">
                            <label>Cuenta</label>
                            <MultiSelectComponent ref={(ms) => { this.ddlAccount = ms; }} changeOnBlur={false} dataSource={this.state.accounts}
                                fields={{ text: 'NOMBRE', value: 'IDCUENTA' }} placeholder="Selecciona una opción" mode="CheckBox" showSelectAll={true}
                                showDropDownIcon={true} filterBarPlaceholder="Buscar cuenta" popupHeight="350px" change={this.ddlAccount_change}>
                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                        </div>
                        <div className="col s-12 m6">
                            <label>Plan</label>
                            <MultiSelectComponent ref={(ms) => { this.ddlPlan = ms; }} changeOnBlur={false} dataSource={this.state.plans}
                                fields={{ text: 'NOMBRE', value: 'IDPROGRAMA' }} placeholder="Selecciona una opción" mode="CheckBox" showSelectAll={true}
                                showDropDownIcon={true} filterBarPlaceholder="Buscar plan" popupHeight="350px">
                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                        </div>
                        {this.state.reportType === 'asistance_provider' &&
                            <React.Fragment>
                                <div className="col s12 m6">
                                    <br />
                                    <label>Familia de asistencias</label>
                                    <MultiSelectComponent ref={(ms) => { this.ddlFamilia = ms; }} changeOnBlur={false} dataSource={this.state.families}
                                        fields={{ text: 'DESCRIPCION', value: 'IDFAMILIA' }} placeholder="Selecciona una familia" mode="CheckBox" showSelectAll={true}
                                        showDropDownIcon={true} filterBarPlaceholder="Buscar familia" popupHeight="350px" change={this.ddlFamilia_change}>
                                        <Inject services={[CheckBoxSelection]} />
                                    </MultiSelectComponent>
                                </div>
                                <div className="col s12 m6">
                                    <br />
                                    <label>Tipo de asistencia</label>
                                    <MultiSelectComponent ref={(ms) => { this.ddlFamilia = ms; }} changeOnBlur={false} dataSource={this.state.types}
                                        fields={{ text: 'DESCRIPCION', value: 'IDSERVICIO' }} placeholder="Selecciona un tipo de asistencia" mode="CheckBox" showSelectAll={true}
                                        showDropDownIcon={true} filterBarPlaceholder="Buscar familia" popupHeight="350px">
                                        <Inject services={[CheckBoxSelection]} />
                                    </MultiSelectComponent>
                                </div>
                            </React.Fragment>
                        }
                        {/* <div className="input-field deep-blue col s12 m6">
                 <select id="state" defaultValue="" value={this.state.state} className="validate" onChange={this.handleChange} >
                     <option value="" selected>Selecciona una opción</option>
                     {this.state.states &&
                         this.state.states.map(state => {
                             return (
                                 <option key={`state-${state.ID}`} value={state.ID}>{state.DESCRIPCION}</option>
                             )
                         })
                     }
                 </select>
                 <label>Estado</label>
             </div>
             <div className="input-field deep-blue col s12 m6">
                 <select id="municipality" defaultValue="" value={this.state.municipality} className="validate" onChange={this.handleChange} >
                     <option value="" selected>Selecciona una opción</option>
                     {this.state.municipalities &&
                         this.state.municipalities.map(municipality => {
                             return (
                                 <option key={`municipality-${municipality.ID}`} value={municipality.ID}>{municipality.DESCRIPCION}</option>
                             )
                         })
                     }
                 </select>
                 <label>Municipio</label>
             </div> */}
                        {this.state.reportType !== 'asistance_provider' &&
                            <div className="col s-12 s12">
                                <br />
                                <label>Tipo</label>
                                <MultiSelectComponent changeOnBlur={false} dataSource={this.state.tipos}
                                    fields={{ text: 'Text', value: 'Id' }} placeholder="Selecciona una opción" mode="CheckBox" showSelectAll={true}
                                    showDropDownIcon={true} filterBarPlaceholder="Buscar tipo" popupHeight="350px">
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                            </div>
                        }
                        <div className="col s12 m6 push-m3">
                            <br />
                            <button class="waves-effect waves-light btn" style={{ width: '100%' }}><i class="material-icons right">search</i>Buscar</button>
                        </div>
                    </div>
                </form>
            </div>

        )
    }
}