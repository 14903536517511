import React, {Component, Fragment} from 'react';
import M from 'materialize-css/dist/js/materialize.min.js'
import DataTable from 'react-data-table-component';
import swal from 'sweetalert2';
import axios from 'axios';

import moment from 'moment';
// import './Admin.scss'

export default class BlueDiamondAdmin extends Component {

    constructor(props) {
        super(props);
        const authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV
        /**
         *     url: fullRoute ? `${authenticate}${route}` : route,
         */

        this.moduleName = 'Herramienta de Administración de Huéspedes';
        this.apiRoute = `${authenticate}/api/blue_diamond`;
        this.handleSearch = this.handleSearch.bind(this);

        this.clearData = this.clearData.bind(this);
        this.loadData = this.loadData.bind(this);

        this.handleViewDetail = this.handleViewDetail.bind(this);
        this.handleDestroy = this.handleDestroy.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeLoginPass = this.handleChangeLoginPass.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleDisplayErrors = this.handleDisplayErrors.bind(this);
        this.renderDetailForm = this.renderDetailForm.bind(this);
        this.renderGuestsTable = this.renderGuestsTable.bind(this);
        this.renderLogin = this.renderLogin.bind(this);
        this.internalCatalogs = {
            countries: [
                {name: 'Afganistán'},
                {name: 'Albania'},
                {name: 'Alemania'},
                {name: 'Andorra'},
                {name: 'Angola'},
                {name: 'Anguila'},
                {name: 'Antártida'},
                {name: 'Antigua y Barbuda'},
                {name: 'Arabia Saudita'},
                {name: 'Argelia'},
                {name: 'Argentina'},
                {name: 'Armenia'},
                {name: 'Aruba'},
                {name: 'Australia'},
                {name: 'Austria'},
                {name: 'Azerbaiyán'},
                {name: 'Bélgica'},
                {name: 'Bahamas'},
                {name: 'Bahrein'},
                {name: 'Bangladesh'},
                {name: 'Barbados'},
                {name: 'Belice'},
                {name: 'Benín'},
                {name: 'Bhután'},
                {name: 'Bielorrusia'},
                {name: 'Birmania'},
                {name: 'Bolivia'},
                {name: 'Bosnia y Herzegovina'},
                {name: 'Botsuana'},
                {name: 'Brasil'},
                {name: 'Brunéi'},
                {name: 'Bulgaria'},
                {name: 'Burkina Faso'},
                {name: 'Burundi'},
                {name: 'Cabo Verde'},
                {name: 'Camboya'},
                {name: 'Camerún'},
                {name: 'Canadá'},
                {name: 'Chad'},
                {name: 'Chile'},
                {name: 'China'},
                {name: 'Chipre'},
                {name: 'Ciudad del Vaticano'},
                {name: 'Colombia'},
                {name: 'Comoras'},
                {name: 'República del Congo'},
                {name: 'República Democrática del Congo'},
                {name: 'Corea del Norte'},
                {name: 'Corea del Sur'},
                {name: 'Costa de Marfil'},
                {name: 'Costa Rica'},
                {name: 'Croacia'},
                {name: 'Cuba'},
                {name: 'Curazao'},
                {name: 'Dinamarca'},
                {name: 'Dominica'},
                {name: 'Ecuador'},
                {name: 'Egipto'},
                {name: 'El Salvador'},
                {name: 'Emiratos Árabes Unidos'},
                {name: 'Eritrea'},
                {name: 'Eslovaquia'},
                {name: 'Eslovenia'},
                {name: 'España'},
                {name: 'Estados Unidos de América'},
                {name: 'Estonia'},
                {name: 'Etiopía'},
                {name: 'Filipinas'},
                {name: 'Finlandia'},
                {name: 'Fiyi'},
                {name: 'Francia'},
                {name: 'Gabón'},
                {name: 'Gambia'},
                {name: 'Georgia'},
                {name: 'Ghana'},
                {name: 'Gibraltar'},
                {name: 'Granada'},
                {name: 'Grecia'},
                {name: 'Groenlandia'},
                {name: 'Guadalupe'},
                {name: 'Guam'},
                {name: 'Guatemala'},
                {name: 'Guayana Francesa'},
                {name: 'Guernsey'},
                {name: 'Guinea'},
                {name: 'Guinea Ecuatorial'},
                {name: 'Guinea-Bissau'},
                {name: 'Guyana'},
                {name: 'Haití'},
                {name: 'Honduras'},
                {name: 'Hong kong'},
                {name: 'Hungría'},
                {name: 'India'},
                {name: 'Indonesia'},
                {name: 'Irán'},
                {name: 'Irak'},
                {name: 'Irlanda'},
                {name: 'Isla Bouvet'},
                {name: 'Isla de Man'},
                {name: 'Isla de Navidad'},
                {name: 'Isla Norfolk'},
                {name: 'Islandia'},
                {name: 'Islas Bermudas'},
                {name: 'Islas Caimán'},
                {name: 'Islas Cocos (Keeling)'},
                {name: 'Islas Cook'},
                {name: 'Islas de Åland'},
                {name: 'Islas Feroe'},
                {name: 'Islas Georgias del Sur y Sandwich del Sur'},
                {name: 'Islas Heard y McDonald'},
                {name: 'Islas Maldivas'},
                {name: 'Islas Malvinas'},
                {name: 'Islas Marianas del Norte'},
                {name: 'Islas Marshall'},
                {name: 'Islas Pitcairn'},
                {name: 'Islas Salomón'},
                {name: 'Islas Turcas y Caicos'},
                {name: 'Islas Ultramarinas Menores de Estados Unidos'},
                {name: 'Islas Vírgenes Británicas'},
                {name: 'Islas Vírgenes de los Estados Unidos'},
                {name: 'Israel'},
                {name: 'Italia'},
                {name: 'Jamaica'},
                {name: 'Japón'},
                {name: 'Jersey'},
                {name: 'Jordania'},
                {name: 'Kazajistán'},
                {name: 'Kenia'},
                {name: 'Kirguistán'},
                {name: 'Kiribati'},
                {name: 'Kuwait'},
                {name: 'Líbano'},
                {name: 'Laos'},
                {name: 'Lesoto'},
                {name: 'Letonia'},
                {name: 'Liberia'},
                {name: 'Libia'},
                {name: 'Liechtenstein'},
                {name: 'Lituania'},
                {name: 'Luxemburgo'},
                {name: 'México'},
                {name: 'Mónaco'},
                {name: 'Macao'},
                {name: 'Macedônia'},
                {name: 'Madagascar'},
                {name: 'Malasia'},
                {name: 'Malawi'},
                {name: 'Mali'},
                {name: 'Malta'},
                {name: 'Marruecos'},
                {name: 'Martinica'},
                {name: 'Mauricio'},
                {name: 'Mauritania'},
                {name: 'Mayotte'},
                {name: 'Micronesia'},
                {name: 'Moldavia'},
                {name: 'Mongolia'},
                {name: 'Montenegro'},
                {name: 'Montserrat'},
                {name: 'Mozambique'},
                {name: 'Namibia'},
                {name: 'Nauru'},
                {name: 'Nepal'},
                {name: 'Nicaragua'},
                {name: 'Niger'},
                {name: 'Nigeria'},
                {name: 'Niue'},
                {name: 'Noruega'},
                {name: 'Nueva Caledonia'},
                {name: 'Nueva Zelanda'},
                {name: 'Omán'},
                {name: 'Países Bajos'},
                {name: 'Pakistán'},
                {name: 'Palau'},
                {name: 'Palestina'},
                {name: 'Panamá'},
                {name: 'Papúa Nueva Guinea'},
                {name: 'Paraguay'},
                {name: 'Perú'},
                {name: 'Polinesia Francesa'},
                {name: 'Polonia'},
                {name: 'Portugal'},
                {name: 'Puerto Rico'},
                {name: 'Qatar'},
                {name: 'Reino Unido'},
                {name: 'República Centroafricana'},
                {name: 'República Checa'},
                {name: 'República Dominicana'},
                {name: 'República de Sudán del Sur'},
                {name: 'Reunión'},
                {name: 'Ruanda'},
                {name: 'Rumanía'},
                {name: 'Rusia'},
                {name: 'Sahara Occidental'},
                {name: 'Samoa'},
                {name: 'Samoa Americana'},
                {name: 'San Bartolomé'},
                {name: 'San Cristóbal y Nieves'},
                {name: 'San Marino'},
                {name: 'San Martín (Francia)'},
                {name: 'San Pedro y Miquelón'},
                {name: 'San Vicente y las Granadinas'},
                {name: 'Santa Elena'},
                {name: 'Santa Lucía'},
                {name: 'Santo Tomé y Príncipe'},
                {name: 'Senegal'},
                {name: 'Serbia'},
                {name: 'Seychelles'},
                {name: 'Sierra Leona'},
                {name: 'Singapur'},
                {name: 'Sint Maarten'},
                {name: 'Siria'},
                {name: 'Somalia'},
                {name: 'Sri lanka'},
                {name: 'Sudáfrica'},
                {name: 'Sudán'},
                {name: 'Suecia'},
                {name: 'Suiza'},
                {name: 'Surinám'},
                {name: 'Svalbard y Jan Mayen'},
                {name: 'Swazilandia'},
                {name: 'Tayikistán'},
                {name: 'Tailandia'},
                {name: 'Taiwán'},
                {name: 'Tanzania'},
                {name: 'Territorio Británico del Océano Índico'},
                {name: 'Territorios Australes y Antárticas Franceses'},
                {name: 'Timor Oriental'},
                {name: 'Togo'},
                {name: 'Tokelau'},
                {name: 'Tonga'},
                {name: 'Trinidad y Tobago'},
                {name: 'Tunez'},
                {name: 'Turkmenistán'},
                {name: 'Turquía'},
                {name: 'Tuvalu'},
                {name: 'Ucrania'},
                {name: 'Uganda'},
                {name: 'Uruguay'},
                {name: 'Uzbekistán'},
                {name: 'Vanuatu'},
                {name: 'Venezuela'},
                {name: 'Vietnam'},
                {name: 'Wallis y Futuna'},
                {name: 'Yemen'},
                {name: 'Yibuti'},
                {name: 'Zambia'},
                {name: 'Zimbabue'}
            ],
            hotels: [
                "Royalton White Sands",
                "Royalton Blue Waters",
                "Royalton Negril",
                "Hideway Royalton Negril",
                "Grand Lido Negril",
                "Hideway Royalton Riviera Cancun",
                "Royalton Riviera Cancun",
                "Royalton Chic Suits Cancun",
                "Mistique Holbox",
                "PH Beach Resort Cancun",
                "PH Adult Scene Cancun",
                "Royalton Punta Cana",
                "Hideway Royalton Punta Cana",
                "Royalton Bavaro",
                "Royalton Chic Punta Cana",
                "Royalton Splash Punta Cana",
                "Hideway Royalton Saint Lucia",
                "Royalton Saint Lucia",
                "Mistique St. Lucia",
                "Starfish St. Lucia",
                "Royalton Antigua",
                "Starfish Jolly Beach Resort",
                "Starfish Halcyon Cove",
                "PH Beach Resort Costa Rica",
                "Royalton Grenada"
            ],
            registryEntities: [
                'EXPEDIA',
                'PRICELINE',
                'FUNJET VACATIONS',
                'AMERICAN AIRLINES',
                'PLEASANT HOLIDAYS',
                'VACATION EXPRESS',
                'BOOKING.COM',
                'DELTA VACATIONS',
                'FLIGHT CENTER',
                'SOUTHWEST VACATIONS',
                'TRAVEL IMPRESSIONS',
                'CHEAP CARIBBEAN',
                'APPLE VACATIONS',
                'HOTELBEDS',
                'UNITED VACATIONS',
                'COSTCO TRAVEL',
                'CLASSIC VACATIONS',
                'HOTEL WEBSITE',
                'OTHER'
            ], 
        }

        this.state = {
            columns:
            [
                {selector: 'id', omit: false, name: 'ID'},
                {selector:'reservation', name:'Reservación', sortable: true},
                {cell: row => <Fragment>
                    {row.first_name} {row.last_name}
                </Fragment>,
                name:'Nombre completo',
                ignoreRowClick: true, 
                allowOverflow: true, 
                },
                {selector:'phone', name:'Teléfono', sortable: true},
                {selector:'email', name:'Email', sortable: true},
                {cell: row => <Fragment>
                    {moment(row.created_at).format('DD/MM/YYYY')}
                </Fragment>,
                name:'Fecha Registro',
                ignoreRowClick: true, 
                allowOverflow: true, 
                },

                {
                    cell: row => <Fragment>
                        <button type="button" className="btn btn-sm btn-warning" onClick={this.handleViewDetail.bind(this, row)}><i className="fa fa-edit"></i></button>
                        &nbsp;
                        {/* <button type="button" className="btn btn-sm btn-danger" onClick={this.handleDestroy.bind(this, row)}><i className="fa fa-trash"></i></button> */}
                        </Fragment>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                },
            ],
            items: [],
            search: {
                q: ''
            },
            isLoggedIn: false,
            dataLogin: {
                email: '',
                password: '',
            },
            data: {
                'reservation': '',
                'email': '',
                'password': '',
                'session_started_at': '',
                'status': '',
                'first_name': '',
                'last_name': '',
                'birth_date': '',
                'rfc': '',
                'genre': '',
                'hotel': '',
                'country': '',
                'country_birth': '',
                'registry_entity': '',
                'other_registry_entity': '',
                'address': '',
                'phone': '',
                'check_in': '',
                'check_out': ''
            },

            pages: [],
            totalRows: 0,
            currentPage: 1,
            loading: true,
            showCreateForm: false
        };
    }

    componentDidMount() {
        this.setState({loading: true});
        let self = this;
        self.loadData(1);
    }

    componentDidCatch(error, errorInfo) {
        // También puedes registrar el error en un servicio de reporte de errores
        console.error('error: ', error);
        console.error('errorInfo: ', errorInfo);
    }

    handleSearch({target}) {
        let search = this.state.search;
        search[target.name] = target.value;
        this.setState({search: search}, () => {
            this.loadData(1);
        });
    }

    clearData(tmpShow) {
        this.setState({
            data: {
                'reservation': '',
                'email': '',
                'password': '',
                'session_started_at': '',
                'status': '',
                'first_name': '',
                'last_name': '',
                'birth_date': '',
                'rfc': '',
                'genre': '',
                'hotel': '',
                'country': '',
                'country_birth': '',
                'registry_entity': '',
                'other_registry_entity': '',
                'address': '',
                'phone': '',
                'check_in': '',
                'check_out': ''
            },
            showCreateForm: tmpShow
        });
    }

    loadData(page) {
        this.setState({loading: true});
        let self = this;

        axios.get(self.apiRoute + '/back-search', {
            params: {
                q: this.state.search.q,
                page: page
            }
        }).then(({data}) => {
            self.setState({
                items: data.data,
                currentPage: data.page,
                totalRows: data.count,
                loading: false
            });
        });
    }

    handleViewDetail(row){
        this.setState({loading: true});
        let self = this;

        axios
        .get(self.apiRoute + '/' + row.id)
        .then(({data}) => {
            let response = data.record;
            if (response.genre == 0) {
                response.genre = '0';
            }
            self.setState({
                data: response,
                showCreateForm: true,
                loading: false
            }, () => {
                M.updateTextFields();
                M.FormSelect.init(document.querySelectorAll('select'));
            });
        });

    }

    handleDestroy(row){
        let self = this;

        swal.fire({
        title: '¿Deseas eliminar este registro?',
        text: row.first_name + ' ' + row.last_name,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Sí`,
        denyButtonText: `No`,
        }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            self.setState({loading:true});
            axios
            .put(self.apiRoute + '/removeGuestById/' + row.id)
            .then(() => {
                self.loadData(1);
            });
        } else if (result.isDenied) {
            return false;
        }
        })
    }

    handleChange({target}) {
        let self = this;
        let currState = self.state.data;
        currState[target.name] = target.value;
        self.setState({
            data: currState
        });
    }

    handleChangeLoginPass({target}) {
        let self = this;
        let currState = self.state.dataLogin;
        currState[target.name] = target.value;
        self.setState({
            dataLogin: currState
        });
    }

    handleSubmit() {
        let self = this;
        self.setState({loading:true});
        if (!self.state.data.id) {
            swal.fire("No se puede procesar esta solicitud");
            return false;
        } else {
            axios
            .put(self.apiRoute + '/' + self.state.data.id, self.state.data)
            .then(({data}) => {
                self.loadData(1);
                self.clearData(false);
            })
            .catch(self.handleDisplayErrors);
        }
    }

    handleLogin() {
        let self = this;
        axios
        .post(self.apiRoute + '/admin/login', self.state.dataLogin)
        .then(({data}) => {
        let response = data.data;
            console.log('response: ', response);
            if (response == true) {
                self.setState({
                    isLoggedIn: true,
                    dataLogin: {
                        email: '',
                        password: ''
                    }
                });
            }
        })
        .catch(self.handleDisplayErrors);

    }

    handleDisplayErrors(errors) {
        let err = [];
        for(let field of Object.keys(errors.response.data.errors)) { err.push(errors.response.data. errors[field]);}
        swal.fire({
            title: 'Corrige los siguientes errores: ',
            html: err.join('<br/>'),
            icon: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok',
        });
    }

    renderDetailForm(){
        let self = this;

        return <Fragment>

            <div className="card">
                <div className="card-header">
                    <div className="card-title">{self.moduleName}</div>
                    
                </div>
                <div className="card-body">
                    <form autoComplete="off">
                        <div className="row">
                            <div className={self.state.showCreateForm ? "col-md-12 p5" : "d-none"}>
                                <div className="card">
                                    <div className="card-header">
                                        Detalle del registro
                                    </div>
                                    <div className="card-body">
                                        <div className="row pb-3">
                                            <div className="input-field deep-blue col s2">
                                                <label htmlFor="reservation">Reservación</label>
                                                <input type="text" name="reservation" id="reservation" value={self.state.data.reservation} onChange={self.handleChange} className="deep-blue text-dark" required autoComplete="off"/>
                                            </div>
                                        </div>
                                        <div className="row pb-3">

                                            <div className="input-field deep-blue col s4">
                                                <label htmlFor="email">Correo electrónico</label>
                                                <input type="email" name="email" id="email" value={self.state.data.email} onChange={self.handleChange} className="deep-blue text-dark" required autoComplete="off"/>
                                            </div>
                                            <div className="input-field deep-blue col s4">
                                                <label htmlFor="password">Contraseña</label>
                                                <input type="text" name="password" id="password" value={self.state.data.password} onChange={self.handleChange} className="deep-blue text-dark" required autoComplete="off"/>
                                            </div>
                                            <div className="input-field deep-blue col s4">
                                                <label htmlFor="phone">Teléfono</label>
                                                <input type="tel" name="phone" id="phone" value={self.state.data.phone} onChange={self.handleChange} className="deep-blue text-dark" required autoComplete="off"/>
                                            </div>

                                        </div>
                                        <div className="row pb-3">
                                            <div className="input-field deep-blue col s4">
                                                <label htmlFor="first_name">Nombre (s)</label>
                                                <input type="text" name="first_name" id="first_name" value={self.state.data.first_name} onChange={self.handleChange} className="deep-blue text-dark" required autoComplete="off"/>
                                            </div>
                                            <div className="input-field deep-blue col s4">
                                                <label htmlFor="last_name">Apellido (s)</label>
                                                <input type="text" name="last_name" id="last_name" value={self.state.data.last_name} onChange={self.handleChange} className="deep-blue text-dark" required autoComplete="off"/>
                                            </div>
                                            <div className="input-field deep-blue col s2">
                                                <label htmlFor="birth_date">Fecha de Nacimiento</label>
                                                <input type="date" name="birth_date" id="birth_date" value={moment(self.state.data.birth_date).format('YYYY-MM-DD')} onChange={self.handleChange} className="deep-blue text-dark" required autoComplete="off"/>
                                            </div>
                                            <div className="input-field col s2">
                                                <select name="genre" id="genre" value={self.state.data.genre} onChange={self.handleChange}>
                                                    <option disabled>- - -</option>
                                                    <option value="0">Hombre</option>
                                                    <option value="1">Mujer</option>
                                                </select>
                                                <label htmlFor="genre">Género  <strong>{self.state.data.genre}</strong></label>
                                            </div>
                                            <div className="input-field col s4">
                                                <select name="country" id="country" value={self.state.data.country} onChange={self.handleChange} required autoComplete="off">
                                                    {self.internalCatalogs.countries.map((item, key) => {
                                                        return <option key={key} value={item.name}>{item.name}</option>
                                                    })}
                                                </select>
                                                <label htmlFor="country">País de residencia  <strong>{self.state.data.country}</strong></label>
                                            </div>
                                            <div className="input-field col s4">
                                                <select name="country_birth" id="country_birth" value={self.state.data.country_birth} onChange={self.handleChange} required autoComplete="off">
                                                    {self.internalCatalogs.countries.map((item, key) => {
                                                        return <option key={key} value={item.name}>{item.name}</option>
                                                    })}
                                                </select>
                                                <label htmlFor="country_birth">País de nacimiento  <strong>{self.state.data.country_birth}</strong></label>
                                            </div>
                                            <div className="input-field col s4">
                                                <label htmlFor="address">Domicilio de residencia</label>
                                                <input type="text" name="address" id="address" value={self.state.data.address} onChange={self.handleChange} className="deep-blue text-dark" required autoComplete="off"/>
                                            </div>

                                        </div>

                                        <div className="row pb-3">
                                            <div className="input-field col s4">
                                                <select name="hotel" id="hotel" value={self.state.data.hotel} onChange={self.handleChange}>
                                                    {self.internalCatalogs.hotels.map((item, key) => {
                                                        if (key === 0) {
                                                            return <option key={key} value="" disabled>- - - -</option>
                                                        } else {
                                                            return <option key={key} value={item}>{item}</option>
                                                        }
                                                    })}
                                                </select>
                                                <label htmlFor="hotel">Hotel de estadía  <strong>{self.state.data.hotel}</strong></label>
                                            </div>
                                            <div className="input-field col s4">
                                                <select name="registry_entity" id="registry_entity" value={self.state.data.registry_entity} className="deep-blue text-dark" onChange={self.handleChange}>
                                                    {self.internalCatalogs.registryEntities.map((item, key) => {
                                                        if (key === 0) {
                                                            return <option key={key} value="" disabled>- - - -</option>
                                                        } else {
                                                            return <option key={key} value={item}>{item}</option>
                                                        }
                                                    })}
                                                </select>
                                                <label htmlFor="registry_entity">Entidad de registro  <strong>{self.state.data.registry_entity}</strong></label>
                                            </div>
                                            <div className="input-field col s4">
                                                <label htmlFor="other_registry_entity">Otra entidad de registro</label>
                                                <input type="text" name="other_registry_entity" id="other_registry_entity" value={self.state.data.other_registry_entity} onChange={self.handleChange} className="deep-blue text-dark" required autoComplete="off"/>
                                            </div>
                                            </div>
                                        <div className="row pb-3">

                                            <div className="input-field deep-blue col s4">
                                                <label htmlFor="check_in">Check in</label>
                                                <input type="date" name="check_in" id="check_in" value={moment(self.state.data.check_in).format('YYYY-MM-DD')} onChange={self.handleChange} className="deep-blue text-dark" required autoComplete="off"/>
                                            </div>
                                            <div className="input-field deep-blue col s4">
                                                <label htmlFor="check_out">Check out</label>
                                                <input type="date" name="check_out" id="check_out" value={moment(self.state.data.check_out).format('YYYY-MM-DD')} onChange={self.handleChange} className="deep-blue text-dark" required autoComplete="off"/>
                                            </div>

                                        </div>

                                        <div className="row justify-content-center">
                                            <div className="col-md-6 text-center">
                                            <a href="#" className="btn btn-primary" onClick={self.handleSubmit}>Guardar</a>
                                            &nbsp;
                                            <a href="#" className="btn btn-secondary" onClick={self.clearData.bind(self, false)}>Cancelar</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    }

    renderGuestsTable() {
        let self = this;
        let detailForm = self.state.showCreateForm ? self.renderDetailForm () : '';

        return <Fragment>
            <div className={detailForm === '' ? "card" : 'd-none'}>
                <div className="card-header">
                    <div className="card-title">
                        {self.moduleName}
                    </div>
                    <div className="right">
                        <a href="#logout" onClick={() => {
                            self.setState({
                                isLoggedIn: false
                            });
                        }}><i className="material-icons left">power_settings_new</i></a>
                    </div>
                </div>
                <div className="card-body">
                    <div className={self.state.showCreateForm ? "d-none" : "col-md-8 p5"}>
                        <input type="text" className="deep-blue text-dark" placeholder="Buscar información por número de reservación" name="q" value={self.state.search.q} onChange={self.handleSearch}/>
                    </div>

                    <DataTable
                        title={self.moduleName}
                        noHeader={true}
                        noDataComponent={self.state.loading ? "..." : "No hay registros disponibles..."}
                        columns={self.state.columns}
                        data={self.state.items}
                        striped={true}
                        highlightOnHover={true}
                        pagination={true}
                        paginationServer={true}
                        paginationTotalRows={self.state.totalRows}
                        paginationPerPage={15}
                        onChangePage={page => self.loadData(page)}
                        paginationComponentOptions={{ rowsPerPageText: 'Registros por página:', rangeSeparatorText: 'de', noRowsPerPage: true, selectAllRowsItem: false, selectAllRowsItemText: 'todos' }}
                    />
                </div>
            </div>
        </Fragment>
    }

    renderLogin(){
        let self = this;
        return <Fragment>
            <div className="container center">
                <div className="card  center">
                    <div className="card-header">
                        <div className="card-title">{self.moduleName}</div>
                    </div>
                    <div className="card-body center">

                        <div className="container center">

                            <div className="input-field deep-blue col s12">
                                <label htmlFor="emailLogin">Correo electrónico</label>
                                <input type="email" name="email" id="emailLogin" value={self.state.dataLogin.email} onChange={self.handleChangeLoginPass} className="deep-blue text-dark" required autoComplete="off"/>
                            </div>
                            <div className="input-field deep-blue col s12">
                                <label htmlFor="passwordLogin">Contraseña</label>
                                <input type="password" name="password" id="passwordLogin" value={self.state.dataLogin.password} onChange={self.handleChangeLoginPass} className="deep-blue text-dark" required autoComplete="off"/>
                            </div>

                            <button className="btn blue-sky-bg" style={{width: '100%'}} type="button" onClick={self.handleLogin}>
                            Acceder
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    }

    render() {
        let self = this;
        let loginForm = !self.state.isLoggedIn ? self.renderLogin() : '';
        let detailForm = self.state.showCreateForm ? self.renderDetailForm () : '';
        let guestsTable = self.state.isLoggedIn ? self.renderGuestsTable() : '';
        return <Fragment>
            <div className={self.state.isLoggedIn ? "container-fluid" : 's6'}>
                {loginForm}
                {detailForm}
                {guestsTable}

            </div>
        </Fragment>
    }
}
