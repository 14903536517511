import React from 'react'

export default function Card(props){
    return(
        <div key={props.id} className="col s12">
            <div className="card box">
                <div className="card-content row">
                    <div className="col s12">
                        <span className="list-description">Datos</span>
                        <br/>
                        <span className="item-name deep-blue">{props.name}</span>
                        <br/>
                        <span>{props.description}</span>
                    </div>
                    <div className="col s12 m6 buttons-card-box">
                        <button className="btn-floating waves-effect waves-light tooltipped" data-position="top" data-tooltip="Editar" onClick={() => {props.handleEdit(props.id)}}>
                            <i className="material-icons amber-text text-darken-4">edit</i>
                        </button>
                        <button className="btn-floating waves-effect waves-light tooltipped" data-position="top" data-tooltip="Eliminar" onClick={() => {props.handleDelete(props.id)}}>
                            <i className="material-icons red-text">delete</i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}