const iziToast = require('izitoast')

/**
 * Toast.
 * @description Toast function to show messages
*/
class Toast {
    constructor() {
    }
    /**
     * @param {Json} config Configuration object
     * @description Show the success message
    */
    showSuccess(config) {

        iziToast.settings({
            timeout: 2000,
            resetOnHover: true,
            theme: 'light',
            icon: 'fas fa-check-circle',
            overlay: false,
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            iconColor: '#47d764',
            titleColor: '#282c36',
            messageColor: '#7e8086',
            progressBarColor: '#47d764',
            backgroundColor: '#ffffff'
        })

        let closing = function () { console.log('success') }
        if (config.closingFn) {
            closing = config.closingFn
        }
        iziToast.show({
            title: config.title,
            message: config.message,
            position: config.position,
            onClosing: closing
        })
    }
    /**
     * @param {Json} config Configuration object
     * @description Show the error message
    */
    showError(config) {

        iziToast.settings({
            timeout: 4000,
            theme: 'light',
            icon: 'fas fa-times-circle',
            overlay: false,
            resetOnHover: true,
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            iconColor: '#ff5555',
            titleColor: '#282c36',
            messageColor: '#7e8086',
            progressBarColor: '#ff5555',
            backgroundColor: '#ffffff'
        })

        let closing = function () { console.log('error') }
        if (config.closingFn) {
            closing = config.closingFn
        }

        iziToast.show({
            title: config.title,
            message: config.message,
            position: config.position,
            onClosing: closing
        })
    }
    /**
     * @param {Json} config Configuration object
     * @description Show the warning message
    */
    showWarning(config) {

        iziToast.settings({
            timeout: config.timeout !== false ? config.timeout:false,
            theme: 'light',
            icon: 'fas fa-exclamation-circle',
            overlay: false,
            resetOnHover: true,
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            iconColor: '#ffc021',
            titleColor: '#282c36',
            messageColor: '#7e8086',
            progressBarColor: '#ffc021',
            backgroundColor: '#ffffff'
        })

        let closing = function () { console.log('warning') }
        if (config.closingFn) {
            closing = config.closingFn
        }

        iziToast.show({
            title: config.title,
            message: config.message,
            position: config.position,
            onClosing: closing
        })
    }
    /**
     * @param {Json} config Configuration object
     * @description Show the question message
    */
    showCallBack(config) {

        iziToast.settings({
            timeout: 4000,
            theme: 'light',
            icon: 'fa fa-question',
            overlay: true,
            resetOnHover: true,
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            iconColor: '#ff5555',
            titleColor: '#282c36',
            messageColor: '#7e8086',
            progressBarColor: '#ff5555',
            backgroundColor: '#ffffff'
        })

        let call = function () { console.log('callback') }
        if (config.callBack) {
            call = config.callBack
        }

        iziToast.show({
            title: config.title,
            message: config.message,
            position: config.position,
            buttons: [
                ['<button>Sí</button>', async function (instance, toast) {
                    await call()
                    instance.hide({
                        transitionOut: 'fadeOutUp',
                    }, toast, 'buttonName')
                }, true],
                ['<button>No</button>', function (instance, toast) {
                    instance.hide({
                        transitionOut: 'fadeOutUp',
                    }, toast, 'buttonName')
                } ]
            ],
        })
    }
    /**
     * @param {Json} config Configuration object
     * @description Show the question message
    */
    showCallBackInput(config) {

        iziToast.settings({
            timeout: 0,
            theme: 'light',
            icon: 'fa fa-question',
            overlay: true,
            resetOnHover: true,
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            iconColor: '#ff5555',
            titleColor: '#282c36',
            messageColor: '#7e8086',
            progressBarColor: '#ff5555',
            backgroundColor: '#ffffff'
        })

        let call = function () { console.log('callback') }
        if (config.callBack) {
            call = config.callBack
        }

        iziToast.question({
            title: config.title,
            message: config.message,
            position: config.position,
            inputs: [
                ['<input type="text">', 'keyup', function (instance, toast, input, e) {
                }, true],
            ],
            buttons: [
                ['<button style="margin-left:15px;">Aceptar</button>', async function (instance, toast, button, e, inputs) {
                    localStorage.setItem('textCallBack', inputs[0].value)
                    await call()
                    instance.hide({
                        transitionOut: 'fadeOutUp',
                    }, toast, 'buttonName')
                } ],
                ['<button>Cancelar</button>', function (instance, toast) {
                    instance.hide({
                        transitionOut: 'fadeOutUp',
                    }, toast, 'buttonName')
                } ]
            ],
        })
    }
    /**
     * @param {Json} config Configuration object
     * @description Show the question message
    */
    showCustom(config) {

        const {
            timeout,
            icon,
            iconColor,
            title,
            titleColor,
            message,
            messageColor,
            progressBarColor,
            overlay,
            resetOnHover,
            callBack,
            closingFn,
            position,
            input,
            buttons,
        } = config

        let closing = function () { console.log('custom') }
        if (closingFn) {
            closing = closingFn
        }

        iziToast.settings({
            timeout: timeout,
            theme: 'light',
            icon: icon,
            overlay: overlay,
            resetOnHover: resetOnHover,
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            iconColor: iconColor,
            titleColor: titleColor,
            messageColor: messageColor,
            progressBarColor: progressBarColor,
            backgroundColor: '#ffffff'
        })

        let call = function () { console.log('callback') }
        if(callBack)
            call = callBack

        iziToast.question({
            title: title,
            message: message,
            position: position,
            onClosing: closing,
            inputs: input ? [[
                    '<input type="text">',
                    'keyup',
                    function (instance, toast, input, e) {},
                    true,
                ],]
                :
                false,
            buttons: buttons ?[
                    [
                        `<button style="margin-left:15px;">${buttons.ok.name}</button>`,
                        async function (instance, toast, button, e, inputs) {
                            if(input)
                                localStorage.setItem('textCallBack', inputs[0].value)
                            await call()
                            instance.hide({transitionOut: 'fadeOutUp',}, toast, 'buttonName')
                        }
                    ],
                    [
                        `<button>${buttons.cancel.name}</button>`,
                        function (instance, toast) {
                            instance.hide({transitionOut: 'fadeOutUp',}, toast, 'buttonName')
                        }
                    ]
                ]
                :
                false,
        })
    }
}

export default new Toast()