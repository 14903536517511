import React from 'react'
import Card from '../../Cards/Card'
import Form from '../../Forms/Form'
import Input from '../../Inputs/Input'

const Login = props => {
    return(
        <Card
            columns="s12 m6 l4 push-m3 push-l4"
            color="white"
            white={false}
            style={{marginTop: 'calc(50vh - 274px)', borderRadius: '0px 0px 10px 10px', borderTop: '5px solid rgb(60 152 204)', boxShadow: '0px 0px 32px rgba(0,0,0,.8)'}}
        >
            {props.displayWelcomeAlert()}

            <div className="row">
                <div className="col s8 push-s2" align="center" style={{marginTop: '30px'}}>
                    <img src={props.logoMAS} alt="logo" style={{width: '100%'}}/>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <p className="subTitle" style={{marginBottom: '15px'}}>{props.state.language ? 'Iniciar sesión':'Login'}</p>
                    <Form
                        handleSubmit={props.handleSubmit}
                        column="s12"
                    >
                        {props.state.language ?
                            <React.Fragment>
                                <Input
                                    state={props.state}
                                    column="s12"
                                    placeholder="Correo electrónico"
                                    label="Correo electrónico"
                                    id="login_email"
                                    handleChange={props.handleChange}
                                    type="email"
                                    onBlur={e=> {
                                        var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
                                        var email = e.target.value;
                                        if (!regexEmail.test(email)) {
                                            alert("Verifica la dirección de correo electrónico");
                                            e.target.value = '';
                                        }
                                    }}
                                />
                                <Input
                                    state={props.state}
                                    column="s12"
                                    placeholder="Reservación"
                                    label="Reservación"
                                    id="login_reservation"
                                    maxLength="12"
                                    handleChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    type="text"
                                />
                                <Input
                                    state={props.state}
                                    column="s12"
                                    placeholder="Contraseña"
                                    label="Contraseña"
                                    id="password"
                                    handleChange={props.handleChange}
                                    type="password"
                                />
                                <button className="btn blue-sky-bg" style={{width: '100%'}} type="submit">
                                    Iniciar sesión
                                </button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Input
                                    state={props.state}
                                    column="s12"
                                    placeholder="Email"
                                    label="Email"
                                    id="login_email"
                                    handleChange={props.handleChange}
                                    type="email"
                                    onBlur={e=> {
                                        var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
                                        var email = e.target.value;
                                        if (!regexEmail.test(email)) {
                                            alert("Verify your email address");
                                            e.target.value = '';
                                        }
                                    }}
                                />
                                <Input
                                    state={props.state}
                                    column="s12"
                                    placeholder="Reservation / Voucher"
                                    label="Reservation / Voucher"
                                    id="login_reservation"
                                    maxLength="12"
                                    handleChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    type="text"
                                />
                                <Input
                                    state={props.state}
                                    column="s12"
                                    placeholder="Password"
                                    label="Password"
                                    id="password"
                                    handleChange={props.handleChange}
                                    type="password"
                                />
                                <button className="btn blue-sky-bg" style={{width: '100%'}} type="submit">
                                    Sign in
                                </button>
                            </React.Fragment>
                        }
                    </Form>
                </div>
            </div>
        </Card>
    )
}

export default Login