import React from 'react'
import ActionButton from '../../Buttons/ActionButton'
import Card from '../../Cards/Card'
import Info from './Info'
import moment from 'moment'
import Select from '../../Inputs/Select';
import Input from '../../Inputs/Input';

const Information = props => {

    const langLabel = {
        es: {
            defaultOption: 'Seleccione una opción', required: '(*) obligatorio', guest: 'Huésped', subTitle: 'Llenado de información', commonData: 'Información en común', hotel: 'Hotel de estadía', registryEntity: 'Entidad de Registro', otherRegistryEntity: 'Otra entidad de registro', address: 'Domicilio de residencia', country: 'País de Residencia', countryBirth: 'País de Nacimiento', phone: 'Teléfono', check_in: 'Fecha de Check In', check_out: 'Fecha de Check Out', firstName: 'Nombre', lastName: 'Apellidos', birthDate: 'Fecha de Nacimiento', male: 'Masculino',  female: 'Femenino', addGuest: 'AGREGAR HUÉSPED', saveInfo: 'GUARDAR INFORMACIÓN', removeGuest: 'Eliminar huésped', updateInfo: 'ACTUALIZAR INFORMACIÓN'
        },
        en: {
            defaultOption: 'Select an option', required: '(*) required', guest: 'Guest', subTitle: 'Data filling', commonData: 'Common data', hotel: 'Staying hotel', registryEntity: 'Registry Entity', otherRegistryEntity: 'Other registry entity', address: 'Residence address', country: 'Residence Country', countryBirth: 'Birth Country', phone: 'Phone', check_in: 'Check In Date', check_out: 'Check Out Date', firstName: 'Name', lastName: 'Lastname', birthDate: 'Birth Date', male: 'Male',  female: 'Female', addGuest: 'ADD GUEST', saveInfo: 'SAVE DATA', removeGuest: 'Remove guest', updateInfo: 'UPDATE DATA'
        }
    }

    const setLangLabel = (id) => {
        let choosenLang = props.selectedLang ? 'es' : 'en';
        return langLabel[choosenLang][id];
    }

    const isEditable = () => {
        let today = moment();
        let yesterday = today.subtract(1, 'days');

        let checkInDate = moment(props.state.data[0].check_in);
        let dateDiff = checkInDate.diff(yesterday);

        if (dateDiff <= 0) {
            return false;
        } else {
            return true;
        }
    }

    const renderEditableComponents = () => {

        return <React.Fragment>
            
            <Select
                state={props.state}
                columns="s12 deep-blue"
                id="hotel"
                label={setLangLabel('hotel')}
                requiredMessage={setLangLabel('required')}
                value={props.state.data[0].hotel}
                handleChange={props.handleChange}
                >   
                <option value="" disabled>- - - -</option>
                <option value="Royalton White Sands">Royalton White Sands</option>
                <option value="Royalton Blue Waters">Royalton Blue Waters</option>
                <option value="Royalton Negril">Royalton Negril</option>
                <option value="Hideway Royalton Negril">Hideway Royalton Negril</option>
                <option value="Grand Lido Negril">Grand Lido Negril</option>
                <option value="Hideway Royalton Riviera Cancun">Hideway Royalton Riviera Cancun</option>
                <option value="Royalton Riviera Cancun">Royalton Riviera Cancun</option>
                <option value="Royalton Chic Suits Cancun">Royalton Chic Suits Cancun</option>
                <option value="Mistique Holbox">Mistique Holbox</option>
                <option value="PH Beach Resort Cancun">PH Beach Resort Cancun</option>
                <option value="PH Adult Scene Cancun">PH Adult Scene Cancun</option>
                <option value="Royalton Punta Cana">Royalton Punta Cana</option>
                <option value="Hideway Royalton Punta Cana">Hideway Royalton Punta Cana</option>
                <option value="Royalton Bavaro">Royalton Bavaro</option>
                <option value="Royalton Chic Punta Cana">Royalton Chic Punta Cana</option>
                <option value="Royalton Splash Punta Cana">Royalton Splash Punta Cana</option>
                <option value="Hideway Royalton Saint Lucia">Hideway Royalton Saint Lucia</option>
                <option value="Royalton Saint Lucia">Royalton Saint Lucia</option>
                <option value="Mistique St. Lucia">Mistique St. Lucia</option>
                <option value="Starfish St. Lucia">Starfish St. Lucia</option>
                <option value="Royalton Antigua">Royalton Antigua</option>
                <option value="Starfish Jolly Beach Resort">Starfish Jolly Beach Resort</option>
                <option value="Starfish Halcyon Cove">Starfish Halcyon Cove</option>
                <option value="PH Beach Resort Costa Rica">PH Beach Resort Costa Rica</option>
                <option value="Royalton Grenada">Royalton Grenada</option>
            </Select>
            
            <Select
                state={props.state}
                columns="s12 deep-blue"
                id="registry_entity"
                label={setLangLabel('registryEntity')}
                requiredMessage={setLangLabel('required')}
                value={props.state.data[0].registry_entity}
                handleChange={props.handleChange}
            >
                {props.state.registryEntities.map((item, key) => {
                    if (key === 0) {
                        return <option key={key} value="" disabled>- - - -</option>
                    } else {
                        return <option key={key} value={item}>{item}</option>
                    }
                })}
            </Select>
            {props.state.showOtherRegistryEntity === true ? 
            
                <Input
                    state={props.state}
                    column="s12"
                    id="other_registry_entity"
                    value={props.state.data[0].other_registry_entity}
                    handleChange={props.handleChange}
                    type="text"
                    label={setLangLabel('otherRegistryEntity')}
                    requiredMessage={setLangLabel('required')}
                />

            :
                ''
            }
            <ActionButton color="green darken-4 white-text" icon="edit" text={setLangLabel('saveInfo')} fullWidth={true} action={props.handleSubmitRegistryInfo}/>

        </React.Fragment>
    }

    const renderReadOnlyComponents = () => {
        if (!isEditable()) {
        return <React.Fragment>
            <Info name="Hotel de estadía" value={props.state.data[0].hotel} />
            <Info name="Entidad de Registro" value={props.state.data[0].registry_entity} />
            <Info name="Otra Entidad de Registro" value={props.state.data[0].other_registry_entity} /> 
        </React.Fragment>
        } else {
            return '';
        }
    }


    return(
        <Card
            columns="s12 m8 l6 push-m2 push-l3"
            color="white"
            white={false}
            style={{marginTop: 'calc(50vh - 288px)', borderRadius: '0px 0px 10px 10px', borderTop: '5px solid rgb(60 152 204)', boxShadow: '0px 0px 32px rgba(0,0,0,.8)'}}
        >
            <p className="subTitle" style={{marginBottom: '15px'}}>Information</p>
            {props.state.data &&
                <React.Fragment>
                    {props.state.language?
                        <React.Fragment>
                            <Info name="Reservación" value={props.state.data[0].reservation} />
                            <Info name="Correo electrónico" value={props.state.data[0].email} />
                            {isEditable() ? renderEditableComponents() : renderReadOnlyComponents()}

                            <Info name="Domicilio de Residencia" value={props.state.data[0].address} />
                            <Info name="País de residencia" value={props.state.data[0].country} />
                            <Info name="País de nacimiento" value={props.state.data[0].country_birth} />
                            <Info name="Teléfono" value={props.state.data[0].phone} />
                            <Info name="Fecha de Check In" value={props.state.data[0].check_in} />
                            <Info name="Fecha de Check Out" value={props.state.data[0].check_out} />
                            {props.state.guests < 13 &&
                                <ActionButton color="red white-text" icon="add" text="Agregar huésped" fullWidth={true} action={props.addGuest}/>
                            }
                            <p className="subTitle" style={{marginBottom: '15px', marginTop: '15px'}}>Huéspedes</p>
                            {props.state.data.map((guest, key) => {
                                return (
                                    <React.Fragment key={`guest-${guest.id}`}>
                                        <hr />
                                        <Info name="Nombre" value={guest.first_name} />
                                        <Info name="Apellidos" value={guest.last_name} />
                                        <Info name="Fecha de nacimiento" value={guest.birth_date} />
                                        <Info name="Genero" value={guest.genre ? 'Female':'Male'} />
                                        <Info name="Actualizado el" value={guest.updated_at} />
                                        <ActionButton width={key == 0 ?"100%" :"50%"} color="yellow darken-4 white-text" icon="edit" text="Editar huésped" fullWidth={false} action={()=>{props.editGuest(guest.id)}}/>
                                        {key > 0 ?<ActionButton width="50%" color="green darken-4 white-text" icon="remove_circle" text="Eliminar huésped" fullWidth={false} action={()=>{props.removeGuest(guest.id, key)}}/>: ''}
                                    </React.Fragment>
                                )
                            })}
                            <p className="subTitle" style={{marginBottom: '15px', marginTop: '15px'}}>
                                <a href="#" className="btn btn-link" onClick={props.handleSendConfirmation.bind(this, props.state.data[0].id)}>
                                    Click aquí para para enviar su registro por correo.
                                </a>
                            </p>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Info name="Reservation" value={props.state.data[0].reservation} />
                            <Info name="Email" value={props.state.data[0].email} />
                            {isEditable() ? renderEditableComponents() : renderReadOnlyComponents()}

                            <Info name="Residence Address" value={props.state.data[0].address} />
                            <Info name="Residence Country" value={props.state.data[0].country} />
                            <Info name="Country of birth" value={props.state.data[0].country_birth} />
                            <Info name="Phone number" value={props.state.data[0].phone} />
                            <Info name="Check In Date" value={props.state.data[0].check_in} />
                            <Info name="Check Out Date" value={props.state.data[0].check_out} />
                            {props.state.guests < 12 &&
                                <ActionButton color="red white-text" icon="add" text="Add guest" fullWidth={true} action={props.addGuest}/>
                            }
                            <p className="subTitle" style={{marginBottom: '15px', marginTop: '15px'}}>Guests</p>
                            {props.state.data.map((guest, key) => {
                                return (
                                    <React.Fragment key={`guest-${guest.id}`}>
                                        <hr />
                                        <Info name="First name" value={guest.first_name} />
                                        <Info name="Last name" value={guest.last_name} />
                                        <Info name="Day of birth" value={guest.birth_date} />
                                        <Info name="Genre" value={guest.genre ? 'Female':'Male'} />
                                        <Info name="Updated at" value={guest.updated_at} />
                                        <ActionButton width={key == 0 ? "100%":"50%"} color="yellow darken-4 white-text" icon="edit" text="Edit guest" fullWidth={false} action={()=>{props.editGuest(guest.id)}}/>
                                        {key > 0 ?<ActionButton width="50%" color="green darken-4 white-text" icon="remove_circle" text="Remove guest" fullWidth={false} action={()=>{props.removeGuest(guest.id, key)}}/>: ''}

                                    </React.Fragment>
                                )
                            })}
                            <p className="subTitle" style={{marginBottom: '15px', marginTop: '15px'}}>
                                <a href="#" className="btn btn-link" onClick={props.handleSendConfirmation.bind(this, props.state.data[0].id)}>
                                    Click here to send your registry via email.
                                </a>
                            </p>
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </Card>
    )
}

export default Information