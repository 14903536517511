import React from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
import {assign} from '../../Functions/folios'

export default class AssignModal extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            folio_id: this.props.id,
            claim: '',
            ticket: '',
            beneficiary_name: '',
            beneficiary_last_name: '',
            beneficiary_m_last_name: '',
            beneficiary_type: '',
            affiliate_name: '',
            affiliate_last_name: '',
            affiliate_m_last_name: '',
            store: '',
            update: false
        }
    }

    componentDidMount(){
        assign(this.props.id)
        .then(data => {
            if(data.success){
                if(data.information){
                    const info = data.information
                    this.setState({
                        folio_id: info.folio_id,
                        claim: info.claim,
                        ticket: info.ticket,
                        beneficiary_name: info.beneficiary_name,
                        beneficiary_last_name: info.beneficiary_last_name,
                        beneficiary_m_last_name: info.beneficiary_m_last_name,
                        beneficiary_type: info.beneficiary_type,
                        affiliate_name: info.affiliate_name,
                        affiliate_last_name: info.affiliate_last_name,
                        affiliate_m_last_name: info.affiliate_m_last_name,
                        store: info.store,
                        update: true
                    })
                }
            }
            M.updateTextFields()
            const selects = document.querySelectorAll('select')
            M.FormSelect.init(selects)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.setState({
                ...this.state
            })
        }
    }

    handleChange = e => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            [e.target.id]: value
        })
    }

    handleSubmit = e => {
        e.preventDefault()
        e.stopPropagation()
        if(this.state.update === false){
            const data = {
                folio_id: this.state.folio_id,
                claim: this.state.claim,
                ticket: this.state.ticket,
                beneficiary_name: this.state.beneficiary_name,
                beneficiary_last_name: this.state.beneficiary_last_name,
                beneficiary_m_last_name: this.state.beneficiary_m_last_name,
                beneficiary_type: this.state.beneficiary_type,
                affiliate_name: this.state.affiliate_name,
                affiliate_last_name: this.state.affiliate_last_name,
                affiliate_m_last_name: this.state.affiliate_m_last_name,
                store: this.state.store,
                update: true
            }
            this.props.callbackSubmit(data)
        }
    }

    handleClose = () => {
        this.props.callbackClose('modalAssignInfo')
    }

    render(){
        return(
            <div id="modalAssignInfo" className="modal modal-large deep-blue-bg">
                <i className="material-icons close-modal-btn" onClick={this.handleClose}>close</i>
                <div className="modal-content">
                    <div className="row">
                        <form onSubmit={this.handleSubmit} className="col s12">
                            <span className="white-text">Datos del beneficiario</span>
                            <hr className="form-divider" style={{backgroundColor: '#fff'}}/>
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <input placeholder="Reclamación" className="white-text" value={this.state.claim}  onChange={this.handleChange} id="claim" type="text" required />
                                    <label className="blue-sky" htmlFor="claim">Reclamación</label>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <input placeholder="Ticket" className="white-text" value={this.state.ticket}  onChange={this.handleChange} id="ticket" type="text" required />
                                    <label className="blue-sky" htmlFor="ticket">Ticket</label>
                                </div>
                            </div>
                            <span className="white-text">Datos del comprador</span>
                            <hr className="form-divider" style={{backgroundColor: '#fff'}}/>
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <input placeholder="Nombre" className="white-text" value={this.state.beneficiary_name}  onChange={this.handleChange} id="beneficiary_name" type="text" required />
                                    <label className="blue-sky" htmlFor="beneficiary_name">Nombre</label>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <input placeholder="Apellido paterno" className="white-text" value={this.state.beneficiary_last_name}  onChange={this.handleChange} id="beneficiary_last_name" type="text" required />
                                    <label className="blue-sky" htmlFor="beneficiary_last_name">Apellido paterno</label>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <input placeholder="Apellido materno" className="white-text" value={this.state.beneficiary_m_last_name}  onChange={this.handleChange} id="beneficiary_m_last_name" type="text" required />
                                    <label className="blue-sky" htmlFor="beneficiary_m_last_name">Apellido materno</label>
                                </div>
                            </div>
                            <div className="input-field col s12 m12">
                                <select id="beneficiary_type" onChange={this.handleChange} defaultValue={this.state.beneficiary_type} value={this.state.beneficiary_type}>
                                    <option value="" disabled>Selecciona una opción</option>
                                    <option value="0">Default</option>
                                </select>
                                <label>Tipo de beneficiario</label>
                            </div>
                            <span className="white-text">Datos del beneficiario</span>
                            <hr className="form-divider" style={{backgroundColor: '#fff'}}/>
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <input placeholder="Nombre" className="white-text" value={this.state.affiliate_name}  onChange={this.handleChange} id="affiliate_name" type="text" required />
                                    <label className="blue-sky" htmlFor="affiliate_name">Nombre</label>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <input placeholder="Apellido paterno" className="white-text" value={this.state.affiliate_last_name}  onChange={this.handleChange} id="affiliate_last_name" type="text" required />
                                    <label className="blue-sky" htmlFor="affiliate_last_name">Apellido paterno</label>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <input placeholder="Apellido materno" className="white-text" value={this.state.affiliate_m_last_name}  onChange={this.handleChange} id="affiliate_m_last_name" type="text" required />
                                    <label className="blue-sky" htmlFor="affiliate_m_last_name">Apellido materno</label>
                                </div>
                            </div>
                            <span className="white-text">Datos de la tienda</span>
                            <hr className="form-divider" style={{backgroundColor: '#fff'}}/>
                            <div className="row" style={{marginBottom: '0'}}>
                                <div className="input-field col s12">
                                    <input placeholder="Tienda" className="white-text" defaultValue={this.state.store}  onChange={this.handleChange} id="store" type="text" required />
                                    <label className="blue-sky" htmlFor="store">Tienda</label>
                                </div>
                            </div>
                            {this.state.update === false &&
                                    <div>
                                        <button className="btn waves-effect waves-light blue-gradient" type="submit" style={{width: '100%'}}>Guardar</button>
                                    </div>   
                            }
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}