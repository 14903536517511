import React from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
import Preloader from '../Loader/Preloader'
import LotCard from './LotCard'
import FolioCard from './FolioCard'
import EmailSendModal from './EmailSendModal'
import Success from '../IziToast/Success'
import Error from '../IziToast/Error'
import {lotsByCompany} from '../../Functions/lots'
import {foliosSearch, folioSend} from '../../Functions/folios'
const authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV

export default class AddFolios extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            companies: this.props.companies,
            company: '',
            plans: null,
            plan: '',
            lots: null,
            search: '',
            folios: null,
            showPreloader: false,
            idToSend: ''
        }
    }

    componentDidMount(){
        M.updateTextFields()
        const selects = document.querySelectorAll('select')
        M.FormSelect.init(selects)
    }

    handleChange = e => {
        const value = e.target.value
        if(e.target.id === 'company'){
            this.handleCompany(value)
        }
        this.setState({
            ...this.state,
            [e.target.id]: value
        })
    }

    handleCompany(company){
        this.setState({showPreloader: true})
        lotsByCompany(authenticate + '/api/lot/get/byCompany/' + company)
        .then(lots => {
            this.setState({lots: lots.lots, folios: null, showPreloader: false})
        })
    }

    handlePaginationClickLots(url){
        this.setState({showPreloader: true})
        lotsByCompany(url)
        .then(lots => {
            this.setState({ lots: lots.lots, folios: null, showPreloader: false })
        })
    }

    handlePaginationClickFolios(url){
        this.setState({showPreloader: true})
        foliosSearch(url)
        .then(folios => {
            this.setState({ folios: folios.folios, lots: null, showPreloader: false })
        })
    }

    handleSearch = () =>{
        foliosSearch(authenticate + '/api/folio/search/' + this.state.search)
        .then(folios => {
            this.setState({folios: folios.folios, lots: null, showPreloader: false})
        })
    }

    handleModelOpenSendEmail = id => {
        this.setState({ idToSend: id })
        const element = document.getElementById('modalSendEmail')
        const modal = M.Modal.init(element, {preventScrolling: false, dismissible: false, opacity: 0})
        modal.open()
    }

    handleCloseModal = id => {
        const element = document.getElementById(id)
        const modal =  M.Modal.getInstance(element)
        modal.close()
        this.setState({ idToSend: null })
    }

    handleSendEmail = ops => {
        const data = {
            id: this.state.idToSend,
            email: ops.email
        }
        const id = 'modalSendEmail'
        folioSend(data)
        .then(response => {
            if(response.success){
                Success('Enviado', 'Se enviaron los folios por correo', 'bottomCenter')
            } else {
                Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
            }
            const element = document.getElementById(id)
            const modal =  M.Modal.getInstance(element)
            modal.close()
            this.setState({showPreloader: false, idToSend: null})
        })
        .catch(e => {
            console.log(e)
            Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
            const element = document.getElementById(id)
            const modal =  M.Modal.getInstance(element)
            modal.close()
            this.setState({showPreloader: false, idToSend: null})
        })
    }

    handleFolioClick = () => {
        
    }

    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {this.state.showPreloader &&
                        <Preloader color="yellownee" />
                    }
                    <div className="col s12">
                        <div className="row" style={{marginBottom: '0'}}>
                            <div className="input-field deep-blue col s12 m6">
                                <select id="company" onChange={this.handleChange} defaultValue={this.state.company} onBlur={this.handleChange}>
                                    <option value="" disabled>Selecciona una opción</option>
                                    {
                                        this.state.companies.map((company, key) => {
                                            return(
                                                <option key={key} value={key}>{company}</option>
                                            )
                                        })
                                    }
                                </select>
                                <label>Compañía</label>
                            </div>
                            <div className="col s10 m5">
                                <div className="input-field">
                                    <input id="search" type="text" className="deep-blue" value={this.state.search} onChange={this.handleChange} />
                                    <label htmlFor="search">Buscar folio</label>
                                </div>
                            </div>
                            <div className="col s2 m1">
                                <button className="btn-floating waves-effect waves-light tooltipped" style={{marginTop: '1rem'}} data-position="top" data-tooltip="Buscar" onClick={this.handleSearch}>
                                    <i className="material-icons deep-blue white">search</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{overflow: 'hidden'}}>
                    <div className="col s12">
                        {this.state.lots &&
                            <div className="row valign-wrapper">
                                <div className="col s12 m6">
                                    <ul className="pagination" style={{float: 'right', margin: '3px'}}>
                                        <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClickLots(this.state.lots.first_page_url)}}><i className="material-icons">first_page</i></a></li>
                                        {this.state.lots.prev_page_url &&
                                            <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClickLots(this.state.lots.prev_page_url)}}>{this.state.lots.current_page - 1}</a></li>
                                        }
                                        <li className="waves-effect active"><a href="#" className="white-text">{this.state.lots.current_page}</a></li>
                                        {this.state.lots.next_page_url &&
                                            <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClickLots(this.state.lots.next_page_url)}}>{this.state.lots.current_page + 1}</a></li>
                                        }
                                        <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClickLots(this.state.lots.last_page_url)}}><i className="material-icons">last_page</i></a></li>
                                    </ul>
                                </div>
                            </div>
                        }
                        {this.state.folios &&
                            <div className="row valign-wrapper">
                                <div className="col s12 m6">
                                    <ul className="pagination" style={{float: 'right', margin: '3px'}}>
                                        <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClickFolios(this.state.folios.first_page_url)}}><i className="material-icons">first_page</i></a></li>
                                        {this.state.folios.prev_page_url &&
                                            <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClickFolios(this.state.folios.prev_page_url)}}>{this.state.folios.current_page - 1}</a></li>
                                        }
                                        <li className="waves-effect active"><a href="#" className="white-text">{this.state.folios.current_page}</a></li>
                                        {this.state.folios.next_page_url &&
                                            <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClickFolios(this.state.folios.next_page_url)}}>{this.state.folios.current_page + 1}</a></li>
                                        }
                                        <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClickFolios(this.state.folios.last_page_url)}}><i className="material-icons">last_page</i></a></li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                    {this.state.lots &&
                        this.state.lots.data.map(lot => {
                            return(
                                <LotCard
                                    key={lot.id}
                                    lot={lot}
                                    callbackSend={this.handleModelOpenSendEmail.bind(this)}
                                />
                            )
                        })
                    }
                    {this.state.folios &&
                        this.state.folios.data.map(folio => {
                            return(
                                <FolioCard
                                    key={folio.id}
                                    folio={folio}
                                    callbackClick={this.handleFolioClick.bind(this)}
                                />
                            )
                        })
                    }
                </div>
                <EmailSendModal 
                    callbackSubmit={this.handleSendEmail.bind(this)}
                    callbackClose={this.handleCloseModal.bind(this)}
                />
            </React.Fragment>
        )
    }
}