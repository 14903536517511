import React from 'react'
import './Checkbox.scss'

const Checkbox = props => {
    return(
        <p>
            <label>
                <input type="checkbox" id={props.id} className={!props.checked ? 'indeterminate':''} onChange={props.toggleCheckbox} checked={props.checked ? 'checked':''}/>
                <span className={props.color ? props.color:''}>{props.name}</span>
            </label>
        </p>
    )
}     

export default Checkbox