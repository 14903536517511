import React from 'react'
import UrlCard from '../Cards/UrlCard'
import Preloader from '../Loader/Preloader'
import ShowBanks from './Banks/ShowBanks'

const cards = [
    {
        title: 'Bancos',
        text: 'Ver todo el listado de bancos, editar, eliminar y asignar permisos.',
        button: 'Ver listado',
        section: 'banks'    
    },
    {
        title: 'Tipos de proveedor',
        text: 'Ver todo el listado de tipos de proveedor, editar y deshabilitar.',
        button: 'Ver listado',
        section: 'supplierTypes'
    },
    {
        title: 'Tipos de servicio',
        text: 'Ver todo el listado de los tipos de servicio, editar y deshabilitar.',
        button: 'Ver listado',
        section: 'serviceTypes'
    },
]

export default class ShowConfigurations extends React.Component {

    constructor(){
        super()
        this.state = {
            showPreloader: true,
            show: 'cards',
        }
    }

    componentDidMount(){
        this.setState({showPreloader: false})
    }

    getSection = section => {
        this.setState({show: section})
    }

    handleClickBack = () => {
        this.setState({show: 'cards'})
    }

    togglePreloader = value => {
        this.setState({showPreloader: value})
    }

    render(){
        return(
            <React.Fragment>
                <div className="content">
                    {this.state.showPreloader && 
                        <Preloader color="yellownee" />
                    }
                    <div className="row">
                        {this.state.show === 'cards' &&
                            cards.map(card => {
                                return(
                                    <UrlCard 
                                        key={card.title}
                                        title={card.title} 
                                        text={card.text}  
                                        button={card.button} 
                                        section={card.section}
                                        callback={this.getSection.bind(this)}
                                    />
                                )
                            })
                        }
                    </div>
                    {this.state.show === 'banks' &&
                        <React.Fragment>
                            <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                                <i className="material-icons left">arrow_back</i>Regresar
                            </button>
                            <ShowBanks
                                togglePreloader={this.togglePreloader.bind(this)}
                            />
                        </React.Fragment>
                    }
                    {this.state.show === 'supplierTypes' &&
                        <React.Fragment>
                            <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                                <i className="material-icons left">arrow_back</i>Regresar
                            </button>
                            <div>
                                <h1>Supplier types</h1>
                            </div>
                        </React.Fragment>
                    }
                    {this.state.show === 'serviceTypes' &&
                        <React.Fragment>
                            <button className="btn-flat waves-effect waves-light deep-blue" name="action" onClick={this.handleClickBack}>
                                <i className="material-icons left">arrow_back</i>Regresar
                            </button>
                            <div>
                                <h1>Service types</h1>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>
        )
    }

}