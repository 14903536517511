import React from 'react'

const InfoCard = props => {
    return(
    <div className="col s12 m6">
        <div className="card box">
            <div className="card-content row">
                <div className="col s12 m8">
                    {props.children}
                </div>
                <div className="col s12 m6 buttons-card-box">
                    <button className="btn-floating waves-effect waves-light tooltipped" data-position="top" data-tooltip="Editar" onClick={props.handleEdit}>
                        <i className="material-icons amber-text text-darken-4">edit</i>
                    </button>
                    <button className="btn-floating waves-effect waves-light tooltipped" data-position="top" data-tooltip="Eliminar" onClick={props.handleDelete}>
                        <i className="material-icons red-text">delete</i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default InfoCard