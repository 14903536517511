const axios = require('axios')

export default async function logout(){
    await axios.get((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/logout',{
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const data = res.data
        if(data.success){
            console.log(data.message)
            localStorage.clear()
            window.location.href = '/'
        }
    })
    .catch(err => {
        console.log(err)
        localStorage.clear()
        window.location.href = '/'
    })
}