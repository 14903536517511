import React from 'react'
import { Link } from 'react-router-dom';
import './Navbar.css'

export default class Sidebar extends React.Component {

    constructor() {
        super()
        this.state = {
            routes: localStorage.getItem('access') !== undefined ? JSON.parse(localStorage.getItem('access')) : [],
            access: {
                Authenticate: {
                    name: 'Autenticación'
                },
                Bulkload: {
                    name: 'Emisión'
                },
                Foliator: {
                    name: 'Foliador'
                },
                Projects: {
                    name: 'Proyectos'
                },
                Tasks: {
                    name: 'Tareas'
                },
                Reports: {
                    name: 'Reportes'
                },
                Configuration: {
                    name: 'Configuración'
                },
            }
        }
    }

    render(){
        const { routes, access } = this.state;
        return(
            <nav className="navigation">
                <ul className="mainmenu">
                    <Link to="/home"><li><span className={(this.props.pathname === '/home' ? 'active' : '')}>Dashboard</span></li></Link>
                    {routes.length &&
                        routes.map(route => {
                            let li
                            if(access.hasOwnProperty(route)){
                                    li = <Link to={`/${route}`}><li key={route}><span className={(this.props.pathname === '/'+route ? 'active' : '')}>{access[route].name}</span></li></Link>
                            } else {
                                li = null
                            }

                            return li
                        })
                    }
                </ul>
            </nav>
        )
    }
}