import React from 'react'
import Form from '../../Forms/Form'
import Input from '../../Inputs/Input'
import Card from '../../Cards/Card'
import Toast from '../../IziToast/Toast'

const Registration = props => {
    
    // Verify queryString parameter for switch to display login form.
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('goto')) {
        let goTo = urlParams.get('goto');
        if (goTo == 'login') props.goTo(0);
    }
    // let displayWelcomeAlert = showWelcomeAlert();

    return(        
        <Card
            columns="s12 m6 l4 push-m3 push-l4"
            color="white"
            white={false}
            style={{marginTop: 'calc(50vh - 274px)', borderRadius: '0px 0px 10px 10px', borderTop: '5px solid rgb(60 152 204)', boxShadow: '0px 0px 32px rgba(0,0,0,.8)'}}
        >
            {props.displayWelcomeAlert()}

            <div className="row">
                <div className="col s8 push-s2" align="center" style={{marginTop: '30px'}}>
                    <img src={props.logoMAS} alt="logo" onClick={()=>{props.goTo(-1)}} style={{width: '100%', cursor: 'pointer'}}/>
                </div>
            </div>
 
            <div className="row">
                <div className="col s12">
                    <p className="subTitle" style={{marginBottom: '15px'}}>{props.state.language ? 'Registro':'Registration'}</p>
                    <Form
                        handleSubmit={props.handleSubmit}
                        column="s12"
                    >
                        {props.state.language ?
                            <React.Fragment>
                                <Input
                                    state={props.state}
                                    id="reservation_number"
                                    placeholder="Reservación"
                                    column="s12"
                                    type="text"
                                    label="Reservación"
                                    handleChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    maxLength="12"
                                />
                                <Input
                                    state={props.state}
                                    id="email"
                                    placeholder="Correo electrónico"
                                    column="s12"
                                    type="email"
                                    label="Correo electrónico"
                                    handleChange={props.handleChange}
                                    onBlur={e=> {
                                        if (e.target.value) {
                                            var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
                                            var email = e.target.value;
                                            if (!regexEmail.test(email)) {
                                                alert("Verifica la dirección de correo electrónico");
                                                e.target.value = '';
                                            }
                                        }
                                    }}

                                />
                                <Input
                                    state={props.state}
                                    id="password"
                                    placeholder="Contraseña"
                                    column="s12"
                                    type="password"
                                    label="Contraseña"
                                    handleChange={props.handleChange}
                                    onBlur={e=> {
                                        if (!e.target.value) {
                                            alert("La contraseña no puede estar vacía");
                                            return false;
                                        }
                                    }}
                                    maxLength="12"
                                />
                                <div className="col s12">
                                    <button className="btn-flat waves-effect waves-light deep-blue" name="action" type="button" onClick={()=>{props.goTo(0)}} style={{fontSize: '10px', width: '100%', margin: '-25px 0px 10px 0px'}}>
                                        <i className="material-icons right" style={{fontSize: '1rem'}}>open_in_new</i>¿Ya tienes una cuenta?
                                    </button>
                                </div>
                                <button className="btn blue-sky-bg" style={{width: '100%'}} type="submit">
                                    Registrarse
                                </button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Input
                                    state={props.state}
                                    id="reservation_number"
                                    placeholder="Reservation / Voucher"
                                    column="s12"
                                    type="text"
                                    label="Reservation / Voucher"
                                    handleChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    maxLength="12"
                                />
                                <Input
                                    state={props.state}
                                    id="email"
                                    placeholder="Email"
                                    column="s12"
                                    type="email"
                                    label="Email"
                                    handleChange={props.handleChange}
                                    onBlur={e=>{
                                        if (e.target.value) {
                                            var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
                                            var email = e.target.value;
                                            if (!regexEmail.test(email)) {
                                                alert("Verify your email address");
                                                e.target.value = '';
                                            }
                                        }
                                    }}
                                />
                                <Input
                                    state={props.state}
                                    id="password"
                                    placeholder="Password"
                                    column="s12"
                                    type="password"
                                    label="Password"
                                    handleChange={props.handleChange}
                                    onBlur={e=> {
                                        if (!e.target.value) {
                                            alert("Your password can not be empty");
                                            return false;
                                        }
                                    }}
                                    maxLength="12"
                                />
                                <div className="col s12">
                                    <button className="btn-flat waves-effect waves-light deep-blue" name="action" type="button" onClick={()=>{props.goTo(0)}} style={{fontSize: '10px', width: '100%', margin: '-25px 0px 10px 0px'}}>
                                        <i className="material-icons right" style={{fontSize: '1rem'}}>open_in_new</i>Do you already have an account?
                                    </button>
                                </div>
                                <button className="btn blue-sky-bg" style={{width: '100%'}} type="submit">
                                    Sign up
                                </button>
                            </React.Fragment>
                        }
                    </Form>
                </div>
            </div>
        </Card>
    )
}

export default Registration