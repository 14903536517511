/**
 * @description Routes from the API
*/
export default {
    loginBlueDiamond: '/api/blue_diamond/login',
    exportBlueDiamond: '/api/blue_diamond/export',
    downloadBlueDiamond: '/api/blue_diamond/download',
    getCompaniesNames: '/api/companies/names',
    getDepartmentsNames: '/api/department/names',
    getUser: '/api/user/get/information',
    getSite: '/api/site',
    getSites: '/api/site/get/all',
    getSitesNames: '/api/site/get/names',
    getModule: '/api/module',
    getAllModules: '/api/module/get/all',
    getModulesOrder: '/api/module/get/order',
    getModulesBySite: '/api/module/get/bySite',
    getAccess: '/api/access',
    getAllAccess: '/api/access/get/all',
    getAccessBySite: '/api/access/get/bySite',
    getBank: '/api/bank',
    getBanks: '/api/bank/get/all',
    getRecordByReservation: '/api/blue_diamond/search',
    getRecord: '/api/blue_diamond',
    getRecords: '/api/blue_diamond/getReservation',
    getBulkloadRecords: '/api/bulkload/records/getByCompany',
    getBulkloadRecordById: '/api/bulkload/records/show',
    getBulkloadRecordByIdBlue: '/api/bulkload/records/show/blue',
    storeUser: '/api/register/newProcess',
    storeSite: '/api/site/store',
    storeModule: '/api/module/store',
    storeAccess: '/api/access/store',
    storeBank: '/api/bank/store',
    storeStartRecord: '/api/blue_diamond/start',
    storeRecord: '/api/blue_diamond/store',
    deleteGuestRecord: '/api/blue_diamond/removeGuestById',
    updateUser: '/api/user/update/process',
    updateSite: '/api/site/update',
    updateModule: '/api/module/update',
    updateAccess: '/api/access/update',
    updateBank: '/api/bank/update',
    updateRecordsStatus: '/api/blue_diamond/update/status',
    updateRecordsGuest: '/api/blue_diamond/update/guest',
    updateRecordsHotelRegistryEntity: '/api/blue_diamond/update/hotelEntityRegistry',
    deleteSite: '/api/site/delete',
    deleteModule: '/api/module/delete',
    deleteAccess: '/api/access/delete',
    deleteBank: '/api/bank/delete',
}