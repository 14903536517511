import React from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
const status = ['Pendiente', 'Enviado', 'Activo', 'Inactivo']

export default class FolioCard extends React.Component{

    componentDidMount(){
        const tooltips = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(tooltips);
    }

    handleClick = () => {
        this.props.callbackClick(this.props.folio.id)
    }

    render(){
        return(
            <div className="col s12 m6">
                <div className="card box" style={{cursor: 'pointer'}} onClick={this.handleClick}>
                    <div className="card-content row">
                        <div className="col s12 m12">
                            <span className="list-description">Datos</span>
                            <br/>
                            <span className="item-name deep-blue">Id folio: {this.props.folio.id}</span>
                            <br/>
                            <span>Nomenclatura: {this.props.folio.nomenclature}</span>
                            <br/>
                            <span>Folio: {this.props.folio.folio}</span>
                            <br/>
                            <span>Estatus: {status[this.props.folio.status]}</span>
                            <br/>
                            <span>Fecha de creación: {this.props.folio.created_at}</span>
                            <br/>
                            <span>Fecha de actualización: {this.props.folio.updated_at}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}