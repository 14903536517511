import React from 'react'
import './Cards.css'

export default class UrlCard extends React.Component{

    handleClick = () => {
        this.props.callback(this.props.section)
    }

    render(){
        return(
            <div className="col s12 m6">
                <div className={`url-panel-card card-panel ${this.props.color}-gradient-card`} onClick={this.handleClick}>
                    <span className="card-title">{this.props.title}</span>
                    <br/>
                    <span>{this.props.text}</span>
                </div>
            </div>
        )
    }
}