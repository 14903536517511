import React from 'react'
import Preloader from '../Loader/Preloader'
import DepartmentCard from './DepartmentCard'
import DepartmentModal from './DepartmentModal'
import Success from '../IziToast/Success'
import Error from '../IziToast/Error'
import M from 'materialize-css/dist/js/materialize.min.js'
const axios = require('axios')

export default class ShowUsers extends React.Component {

    authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV

    constructor(){
        super()
        this.state = {
            department: null,
            departmentsNames: null,
            departmentsData : null,
            departmentsDataUrl: this.authenticate + '/api/department/all',
            users: null,
            options: null,
            showPreloader: true,
            showUpdateDepartmentPreloader: false,
            search: ''
        }
    }

    componentDidMount(){
        this.departmentsNames()
        .then(names => {
            this.setState({ departmentsNames: names })
            this.departments(this.state.departmentsDataUrl)
            .then(res => {
                this.setState({ departmentsData: res.departments, showPreloader: false })
            })
        })
        this.users(this.authenticate + '/api/user/names')
        .then(res => {
            const users = res.users.data
            const names = {}
            for(let i in users){
                names[users[i].name] = null
            }
            this.setState({users: {data: users, names: names}})
        })
        M.AutoInit()
    }

    handlePaginationClick(url){
        this.setState({showPreloader: true})
        this.departments(url)
        .then(res => {
            console.log(res)
            this.setState({ departmentsData: res.departments, showPreloader: false, departmentsDataUrl: url })
        })
    }

    handleGetDepartment = id => {
        this.department(id)
        .then(department => {
            this.setState({ department: department })
            const element = document.getElementById('modalEditDepartment')
            const modal = M.Modal.init(element, {preventScrolling: false, dismissible: false, opacity: 0})
            modal.open()
        })
    }

    handleCloseModal = id => {
        const element = document.getElementById(id)
        const modal =  M.Modal.getInstance(element)
        modal.close()
        this.setState({department: null, options: null})
    }

    handleDisableDepartment = id => {

    }

    handleUpdateDepartment = department => {
        this.setState({showUpdateDepartmentPreloader: true})
        this.departmentUpdate(department)
        .then(data => {
            if(data.success){
                Success('Guardado', 'Se guardaron correctamente los datos del departamento', 'bottomCenter')
            } else {
                Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
            }
            const element = document.getElementById('modalEditDepartment')
            const modal = M.Modal.init(element)
            modal.close()
            this.setState({showUpdateDepartmentPreloader: false, department: null})
            this.departments(this.state.departmentsDataUrl)
            .then(res => {
                this.setState({ departmentsData: res.departments, showPreloader: false })
            })
        })
    }

    handleChange = e =>{
        const value = e.target.value
        this.setState({
            search : value
        })      
    }

    async department(id){
        const response = await axios.get(this.authenticate + '/api/department/get/' + id, {
            headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
        })
        .then(res => {
            const data = res.data
            return data
        })
        .catch(e => {
            console.log(e)
        })
        return response
    }

    async departmentUpdate(department){
        const data = {
            'id': department.id,
            'name': department.name,
            'belongs_to': department.belongsTo,
            'options': '{"status":'+ 1 +', "boss": '+ department.bossId +'}'
        }
    
        const options = {
            method: 'PUT',
            headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
            data: JSON.stringify(data),
            url: this.authenticate + '/api/department/update'
        }
    
        const response = await axios(options)
        .then(res => {
            const data = res.data
            return data
        })
        .catch(e => {
            console.log(e)
            return {success: false}
        })
        return response
    }

    async departments(url){
        const response = await axios.get(url, {
            headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
        })
        .then(res => {
            const data = res.data
            return data
        })
        .catch(e => {
            console.log(e)
        })
        return response
    }

    async departmentsNames(){
        const response = await axios.get(this.authenticate + '/api/department/names', {
            headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
        })
        .then(res => {
            const departments = res.data.departments
            let departmentNames = []
            for(let i in departments){
                let department = departments[i]
                departmentNames[department.id] = department.name
            }
            departmentNames[0] = 'Ninguno'
            return departmentNames
        })
        .catch(e => {
            console.log(e)
        })
        return response
    }

    async users(url){
        const response = await axios.get(url,{
            headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
        })
        .then(res => {
            const data = res.data
            if(data.success){
                return {users: data.users, url: url}
            }
        })
        .catch(err => {
            if(err.response.data.message === 'Server Error'){
                return this.users(url)
            }
        })
        return response
    }

    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col s12">
                        {this.state.departmentsData &&
                            <div className="row valign-wrapper">
                                {/* <div className="col s12 m6">
                                    <div className="input-field">
                                        <input id="search" type="text" className="validate deep-blue" value={this.state.search} onChange={this.handleChange} />
                                        <label htmlFor="search">Buscar</label>
                                    </div>
                                </div> */}
                                <div className="col s12 m6">
                                    <ul className="pagination" style={{float: 'right', margin: '3px'}}>
                                        <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClick(this.state.departmentsData.first_page_url)}}><i className="material-icons">first_page</i></a></li>
                                        {this.state.departmentsData.prev_page_url &&
                                            <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClick(this.state.departmentsData.prev_page_url)}}>{this.state.departmentsData.current_page - 1}</a></li>
                                        }
                                        <li className="waves-effect active"><a href="#" className="white-text">{this.state.departmentsData.current_page}</a></li>
                                        {this.state.departmentsData.next_page_url &&
                                            <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClick(this.state.departmentsData.next_page_url)}}>{this.state.departmentsData.current_page + 1}</a></li>
                                        }
                                        <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClick(this.state.departmentsData.last_page_url)}}><i className="material-icons">last_page</i></a></li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col s12">
                        <div>
                            {(this.state.departmentsData === null || this.state.showPreloader) ?
                                <Preloader color="blue" position="relative" />
                                :
                                <div className="row card-list">
                                    {this.state.departmentsData.data.map(department => {
                                            return (
                                                <DepartmentCard 
                                                    key={department.id} 
                                                    department={department} 
                                                    belongsTo={this.state.departmentsNames[department.belongs_to]}
                                                    callbackEdit={this.handleGetDepartment.bind(this)}
                                                    callbackDisable={this.handleDisableDepartment.bind(this)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {this.state.department !== null &&
                    <DepartmentModal 
                        department={this.state.department.department} 
                        departments={this.state.departmentsNames}
                        users={this.state.users}
                        showPreloader={this.state.showUpdateDepartmentPreloader} 
                        callbackSubmit={this.handleUpdateDepartment.bind(this)}
                        callbackClose={this.handleCloseModal.bind(this)}
                    />
                }
            </React.Fragment>
        )
    }
}