import axios from  'axios'

const folio = async function(folio){
    return await axios.get((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/master/folio/' + folio, 
    {
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
        if(e.response.data.message === 'Server Error'){
            return this.folio()
        }
    })
}

const folioStore = async function(data){
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
        data: JSON.stringify(data),
        url: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/folio/store',
    }
    const response = await axios(options)
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
    })

    return response
}

const folios = async function(lot){
    const response = await axios.get((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/folio/lot/' + lot, 
    {
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
        if(e.response.data.message === 'Server Error'){
            return this.folios()
        }
    })
    return response
}

const foliosSearch = async function(url){
    const response = await axios.get(url, 
    {
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
        if(e.response.data.message === 'Server Error'){
            return this.foliosSearch()
        }
    })
    return response
}

const folioSend = async function(data){
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
        data: JSON.stringify(data),
        url: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/folio/xls/send',
    }
    const response = await axios(options)
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
    })

    return response
}

const assignInfo = async function(data){
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
        data: JSON.stringify(data),
        url: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/info/store',
    }
    const response = await axios(options)
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
    })

    return response
}

const assignInfoMaster = async function(data){
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: JSON.stringify(data),
        url: (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/master/info/store',
    }
    const response = await axios(options)
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
    })

    return response
}

const assign = async function(folio){
    const response = await axios.get((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/info/show/' + folio, 
    {
        headers: {'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
    })
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
        if(e.response.data.message === 'Server Error'){
            return this.folios()
        }
    })
    return response
}

const superFolio = async function(folio, key){
    return await axios.get((process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV) + '/api/master/folio/super/' + folio + '/' + key)
    .then(res => {
        const data = res.data
        return data
    })
    .catch(e => {
        console.log(e)
        if(e.response.data.message === 'Server Error'){
            return this.superFolio()
        }
    })
}

export {folio, folioStore, folios, foliosSearch, folioSend, assignInfo, assignInfoMaster, assign, superFolio}