import React from 'react'
import './Card.scss'

/**
* @param {String} columns Number of columns
* @param {String} color Background color
* @param {Boolean} white Text color
* @param {Object} children Information
* @description Simple card
*/
const Card = props => {
    return(
        <div className={`simpleCard col ${props.columns}`}>
            <div className={`card ${props.color}`} style={props.style ? props.style:{}}>
                <div className={`card-content ${props.white ? 'white-text':''}`}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Card