import React from 'react'
import Axios from 'axios'
import M from 'materialize-css/dist/js/materialize.min.js'
import {verify, refresh} from '../../Functions/verify'
import AddTaskModal from './AddTaskModal'
import TaskCard from './TaskCard'
import TaskView from './TaskView'
import Scrollbars from 'react-custom-scrollbars'
import Preloader from '../Loader/Preloader'
import {userNames} from '../../Functions/users'
import Success from '../IziToast/Success'
import Error from '../IziToast/Error'

export default class Tasks extends React.Component {

    authenticate = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API_DEV

    constructor(){
        super()
        this.state = {
            task: null,
            tasksData: null,
            users: null,
            new_task: false
        }
    }

    async componentDidMount(){
        const res = await Axios.get(this.authenticate + '/api/task/all/' + localStorage.getItem('id'),{
            headers: {Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
        })
        await this.setState({tasksData: res.data.tasks})
        const response = await userNames()
        if(response.success){
            const users = response.users
            const names = {}
            for(let i in users){
                names[users[i].name] = null
            }
            await this.setState({users: {data: users, names: names}, new_task: true})
        }
    }

    async handlePaginationClick(url){
        await this.setState({showPreloader: true})
        const res = await Axios.get(url,{
            headers: {Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
        })
        await this.setState({showPreloader: false, tasksData: res.data.tasks})
    }

    handleCloseModal = id => {
        const element = document.getElementById(id)
        const modal =  M.Modal.getInstance(element)
        modal.close()
        modal.destroy()
        this.setState({department: null, options: null, new_task: false})
    }

    handleNewModal = async () => {
        await this.setState({ new_task: true })
        const element = document.getElementById('modalAddTask')
        const modal =  M.Modal.init(element)
        modal.open()
    }

    handleAddTask = async task => {
        await this.setState({showAddPreloader: true})
        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json', Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token') },
            data: JSON.stringify(task),
            url: this.authenticate + '/api/task/store'
        }
        const response = await Axios(options)
        const data = response.data
        if(data.success){
            Success('Guardado', 'Se creó la nueva tarea', 'bottomCenter')
        } else {
            Error('Error', 'Ocurrió algún error desconocido', 'bottomCenter')
        }
        const element = document.getElementById('modalAddTask')
        const modal = M.Modal.init(element)
        modal.close() 
        const res = await Axios.get(this.authenticate + '/api/task/all/' + localStorage.getItem('id'),{
            headers: {Authorization: localStorage.getItem('token_type') + ' ' + localStorage.getItem('token')}
        })
        this.setState({ tasksData: res.data.tasks, showAddPreloader: false, new_task: false })
    }

    handleWatch = async task => {
        if(this.state.task !== null){
            await this.setState({task: null})
        }
        await this.setState({task: task})
    }

    render(){
        return(
            <React.Fragment>
                <div className="content row" style={{height: '100%'}}>
                    <div className="content--inner col s12 m6" style={{height: '100%'}}>
                        {this.state.showPreloader &&
                            <Preloader color="yellownee" />
                        }
                        {this.state.tasksData && this.state.tasksData.data.length !== 0 &&
                            <div className="row valign-wrapper">
                                <div className="col s12 m6">
                                    <ul className="pagination" style={{float: 'right', margin: '3px'}}>
                                        <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClick(this.state.tasksData.first_page_url)}}><i className="material-icons">first_page</i></a></li>
                                        {this.state.tasksData.prev_page_url &&
                                            <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClick(this.state.tasksData.prev_page_url)}}>{this.state.tasksData.current_page - 1}</a></li>
                                        }
                                        <li className="waves-effect active"><a href="#" className="white-text">{this.state.tasksData.current_page}</a></li>
                                        {this.state.tasksData.next_page_url &&
                                            <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClick(this.state.tasksData.next_page_url)}}>{this.state.tasksData.current_page + 1}</a></li>
                                        }
                                        <li className="waves-effect"><a href="#" className="deep-blue" onClick={()=>{this.handlePaginationClick(this.state.tasksData.last_page_url)}}><i className="material-icons">last_page</i></a></li>
                                    </ul>
                                </div>
                            </div>
                        }
                        {this.state.tasksData && this.state.tasksData.length &&
                            <h3 className="content--inner deep-blue">Aún no tienes tareas</h3>
                        }
                        {this.state.tasksData !== null &&
                            <div className="row card-list">
                                {this.state.tasksData.data.map(task => {
                                    return <TaskCard 
                                                key={task.id}
                                                task={task}
                                                callbackWatch={this.handleWatch.bind(this)}
                                            />
                                })}
                            </div>
                        }

                        <button className="waves-effect waves-light btn btn-bottom yellownee-gradient" onClick={this.handleNewModal}><i className="material-icons left">add</i>Nueva tarea</button>
                    </div>
                    <div className="col s12 m6" style={{height: '100%'}}>
                        <Scrollbars style={{height: '100%', width: '100%'}}
                            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{display:"none"}}/>}
                            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{display:"none"}}/>}
                        >
                            {this.state.task &&
                                <TaskView
                                    task={this.state.task}
                                    users={this.state.users}
                                />
                            }
                        </Scrollbars>
                    </div>
                </div>
                {this.state.new_task &&
                    <AddTaskModal 
                        users={this.state.users}
                        showPreloader={this.state.showAddPreloader} 
                        callbackSubmit={this.handleAddTask.bind(this)}
                        callbackClose={this.handleCloseModal.bind(this)}
                    />
                }
            </React.Fragment>
        )
    }
}