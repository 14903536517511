import React from 'react'

const Pagination = props => {
    return(
        <div className="col s12 m12">
            <ul className="pagination" style={{float: 'right', margin: '3px'}}>
                <li className="waves-effect"><button type="button" role="button" className="deep-blue" onClick={()=>{props.handlePaginationClick(props.state.modules.first_page_url)}}><i className="material-icons">first_page</i></button></li>
                {props.state.modules.prev_page_url &&
                    <li className="waves-effect"><button type="button" role="button" className="deep-blue" onClick={()=>{props.handlePaginationClick(props.state.modules.prev_page_url)}}>{props.state.modules.current_page - 1}</button></li>
                }
                <li className="waves-effect active"><button type="button" role="button" className="white-text">{props.state.modules.current_page}</button></li>
                {props.state.modules.next_page_url &&
                    <li className="waves-effect"><button type="button" role="button" className="deep-blue" onClick={()=>{props.handlePaginationClick(props.state.modules.next_page_url)}}>{props.state.modules.current_page + 1}</button></li>
                }
                <li className="waves-effect"><button type="button" role="button" className="deep-blue" onClick={()=>{props.handlePaginationClick(props.state.modules.last_page_url)}}><i className="material-icons">last_page</i></button></li>
            </ul>
        </div>
    )
}

export default Pagination