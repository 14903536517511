import React from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
const borders = [
    'border-green',
    'border-blue',
    'border-red'
]
const status = [
    'Pendiente',
    'En proceso',
    'Terminada',
    'Cancelada'
]
const status_color = [
    'red-text',
    'blue-text text-darken-3',
    'green-text text-darken-2',
    'grey-text text-darken-1'
]
const icon = [
    'timer',
    'hourglass_empty',
    'check',
    'close'
]

export default class TaskCard extends React.Component {

    componentDidMount(){
        const tooltips = document.querySelectorAll('.tooltipped')
        M.Tooltip.init(tooltips)
    }

    handleWatch = () => {
        this.props.callbackWatch(this.props.task)
    }

    render(){
        return(
            <React.Fragment>
                <div className="col s12" style={{position: 'relative'}}>
                    <div className={`card box ${borders[this.props.task.priority]}`}>
                        <div className="card-content row">
                            <div className="col s12 m8">
                                <span className="item-name deep-blue">{this.props.task.name}</span>
                                <div className="row" style={{margin: 0}}>
                                    <div className="col s6">
                                        <span className="list-description">Creada por</span>
                                        <br/>
                                        <span>{this.props.task.created_by_name}</span>
                                    </div>
                                    <div className="col s6">
                                        <span className="list-description">Pertenece a</span>
                                        <br/>
                                        <span>{this.props.task.belongs_to_name}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 m6 buttons-card-box">
                                <button className="btn-floating waves-effect waves-light tooltipped" data-position="top" data-tooltip="Ver tarea" onClick={this.handleWatch}>
                                    <i className="material-icons deep-blue">visibility</i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <i className={`material-icons icon-state tooltipped ${status_color[this.props.task.status]}`} data-position="top" data-tooltip={status[this.props.task.status]}>{icon[this.props.task.status]}</i>
                </div>
            </React.Fragment>
        )
    }
}